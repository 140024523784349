// ----- fonts

$font-family: Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;

$font-family-sans: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;

$font-family-shingo: "a-otf-ud-shin-go-pr6n", sans-serif;

$font-family-pt-sans: 'PT Sans', sans-serif;

$font-size: 14px;
$font-size-sp: 14px;

$line-height: 1.8;
$line-height-sp: 1.8;

$fs-10: .71em;
$fs-11: .78em;
$fs-12: .85em;
$fs-13: .92em;
$fs-15: 1.07em;
$fs-16: 1.14em;
$fs-17: 1.21em;
$fs-18: 1.28em;
$fs-19: 1.35em;
$fs-20: 1.42em;
$fs-21: 1.5em;
$fs-22: 1.57em;
$fs-23: 1.64em;
$fs-24: 1.71em;
$fs-25: 1.78em;

$fs-28: 2em;

$fs-32: 2.28em;
$fs-48: 3.42em;

// ----- colors

$color: #2d2d2d;
$cl-purple: #9d56e4;
$cl-lightBlue: #778fce;
$cl-blue: #110051;
$cl-lightGreen: #32cca6;
$cl-green: #02c255;
$cl-orange: #ff9537;
$cl-yellow: #ffd200;
$cl-red: #f85550;
$cl-lightGray: #666;
$cl-gray: #8a8a8a;
$cl-lightPurple: #b7b2ca;
$cl-grey: #bfbfbf;
$cl-lightGrey: #f4f3f7;

// ----- layout

$max-width: 1340px;
$mid-width: 1200px;
$min-width: 1036px;
$sm-width: 969px;
$parts-width: 930px;

@for $i from 0 through 20 {
  .mgb#{$i * 5} {
    margin-bottom:#{$i * 5}px;
  }
}