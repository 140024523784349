/*
  sp contact
--------------------------------------------- */


/* ---------- contact index ---------- */

#inquiry {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/contact/contact-bg.png');
		}
	}
	.content_holder {
		margin-bottom: 80px;

		.txt_link {
			margin-top: 20px;
			text-align: center;
		}
	}
	.big_heading p {
		width: 94%;
		margin: 0 auto;
		padding-top: 30px;
		&.eng {
			padding: 0;
		}
	}
	.ico_circle {
		background: $cl-blue;
		padding: 15px 10px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.inner_parts {
		margin-top: 50px;
	}
	.form_cont {
		.form_inner {
			// border: 2px solid $cl-lightPurple;
			// padding: 20px 4%;
		}
		label {
			span {
				background: $cl-red;
				color: #fff;
				border-radius: 4px;
				display: inline-block;
				// padding: 3px 6px 5px;
			    padding: 5px 6px 3px;
				font-size: $fs-10;
				line-height: 1;
				margin-left: 10px;
			}
		}
		table {
			width: 100%;
			th,
			td {
				width: 100%;
				display: block;
			}
			th {
				padding: 10px 0 5px;
				vertical-align: top;
			}
			td {
				padding: 0 0 10px;
				input[type="text"],
				input[type="email"],
				textarea {
					width: 100%;
					border-radius: 4px;
					border: 1px #ddd solid;
					padding: 8px;
					font-family: $font-family;
				}
				textarea {
					height: 260px;
				}
				.formrun-system-show {
					color: $cl-red;
					font-size: $fs-12;
				}
			}
		}
		input[type="button"],
		input[type="submit"],
		button {
			-webkit-appearance: none;
			background-color: transparent;
			border: none;
			cursor: pointer;
			outline: none;
			padding: 0;
			appearance: none;
		}
		.submit_btn {
			text-align: center;
			margin: 20px auto 0;
		}
		button[type="submit"] {
			font-size: $fs-16;
			text-align: center;
			margin: 0 auto 10px;
			text-decoration: none !important;
			display: block;
			width: 100%;
			height: 44px;
			line-height: 44px;
			background: #fff;
			border: 1px solid #110051;
			color: #110051;
		    border-radius: 22px;
			// -webkit-box-shadow: 2px 2px 2px 0 rgba(121, 131, 160, 1);
			// -moz-box-shadow: 2px 2px 2px 0 rgba(121, 131, 160, 1);
			// box-shadow: 2px 2px 2px 0 rgba(121, 131, 160, 1);
		 //    line-height: 47px;
		 //    padding-right: 10px;
		 //    position: relative;

		    // &:after {
	    	//     content: "";
	    	//     background: url(../images/corp/common/ico-arrow-right-btn.png);
			   //  background-position: right center;
	     //        background-repeat: no-repeat;
	     //        background-size: contain;
	     //        padding: 0;
			   //  width: 7px;
			   //  height: 12px;
			   //  position: absolute;
			   //  right: 10px;
			   //  top: 50%;
			   //  -webkit-transform: translateY(-50%);
			   //  -ms-transform: translateY(-50%);
			   //  transform: translateY(-50%);
		    // }
			// &:after {
			// 	padding-top: 5px;
			// 	padding-right: 20px;
			// 	content: url('../images/corp/common/ico-arrow-right-btn.png');
			// 	display: inline-block;
			// 	vertical-align: middle;
			// 	float: right;
			// 	clear: both;
			// }
			// &:hover {
			// 	background: $cl-blue;
			// }
		}
	}
}


/* ---------- contact thankyou ---------- */

#thankyou {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/contact/contact-bg.png');
		}
	}
	.s1 {
		width: 94%;
		margin: 0 auto;
	}
	.content_holder {
		margin-bottom: 100px;
	}
	.big_heading {

		h3 {
			margin-bottom: 50px;
		}

		p {
			padding-top: 30px;
			&.eng {
				padding: 0;
			}
		}
	}
	.big_heading {
		h3 {
			font-size: $fs-20;
		}
	}
	.form_thankyou_cont {
		margin: 0 auto;
		p {
			margin: 0 auto 30px;
			text-align: center;
		}

		.btn_wrap {
			margin: 60px 0;
		}
	}
}
