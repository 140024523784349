/*
  pc company
--------------------------------------------- */


/* ---------- company access ---------- */

#access {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/company/company-bg.png');
			margin-bottom: 80px;
		}
	}
	.content_holder {
		padding-bottom: 80px;
	}
	.big_heading p {
		padding-top: 30px;
		&.eng {
			padding: 0;
		}
	}
	.ico_circle {
		background: #ddd;
		padding: 32px 10px;
    color: #110051;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.inner_parts {
		max-width: 930px;
		margin: 0 auto;
		width: 100%;
		margin-top: 80px;
		.ico_circle_sm {
			padding: 15px 10px;
			img {
				width: 20px;
				margin: 0 auto;
				text-align: center;
			}
		}
		.box1 {
			margin-bottom: 30px;

			> .box {

				&:last-child {
					padding-top: 0;
				}
			}
		}
		.box {
			padding: 0;
			.map {
				border: 1px #ddd solid;
				padding: 10px 10px 4px;
				iframe {
					width: 100%;
					height: 460px;
				}
			}
			.btn_wrap {
				width: 100%;
				padding: 15px 0;
				text-align: right;
				border-bottom: 1px $cl-gray dashed;
				a {
					margin: 0 0 0 15px;
				}
				.plus {
					position: relative;
					font-size: $fs-13;
					&:before {
						content: "";
						width: 12px;
						height: 12px;
						display: inline-block;
						vertical-align: middle;
						padding-right: 6px;
						background: url(../images/corp/company/ico-plus.png) no-repeat left center;
					}
				}
				.btn-navi {
					width: 152px;
				}
			}
			.detail {
				width: 100%;
				margin-top: 10px;
				th,
				td {
					vertical-align: top;
					padding: 5px 0;
				}
				th {
					width: 120px;
				}
			}
		}
	}
}


/* ---------- company profile ---------- */

#profile {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/company/company-bg.png');
			margin-bottom: 80px;
		}
	}
	.content_holder {
		padding-bottom: 80px;
	}
	.big_heading p {
		padding-top: 30px;
		&.eng {
			padding: 0;
		}
	}
	.ico_circle {
		background: #ddd;
		padding: 32px 10px;
    color: #110051;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.inner_parts {
		max-width: 930px;
		margin: 0 auto;
		width: 100%;
		border-top: 2px solid $cl-blue;
		margin-top: 80px;
		padding-bottom: 80px;
		padding: 10px 32px;
		table {
			width: 100%;
			th,
			td {
				padding: 15px 30px;
				border-top: 1px dashed $cl-grey;
				vertical-align: top;
				position: relative;
			}
			tr:first-child {
				th,
				td {
					border-top: none;
				}
			}
			th {
				width: 166px;
				font-weight: bold;
			}
			.btn_wrap {
				width: 190px;
				position: absolute;
				bottom: 30px;
				right: 30px;
				.btn-l {
					width: 190px;
					height: 34px;
				    line-height: 34px;
				    font-size: 0.92em;
				    margin-right: 0;
				    // padding-right: 10px;
				    padding: 0;
				    position: relative;
					&.rounded {
						border-radius: 30px;
					}
				    // &:after {
			    	//     content: "";
			    	//     background: url(../images/corp/common/ico-arrow-right-btn.png);
					   //  background-position: right center;
			     //        background-repeat: no-repeat;
			     //        background-size: contain;
			     //        padding: 0;
					   //  width: 5px;
					   //  height: 9px;
					   //  position: absolute;
					   //  right: 10px;
					   //  top: 50%;
					   //  -webkit-transform: translateY(-50%);
					   //  -ms-transform: translateY(-50%);
					   //  transform: translateY(-50%);
				    // }
				}
			}
		}
	}
}


/* ---------- company message ---------- */

#message {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/company/company-bg.png');
			margin-bottom: 80px;
		}
	}
	.content_holder {
		position: relative;
		max-width: 930px;
		margin: 0 auto;
		width: 100%;
		// border: 2px solid $cl-lightPurple;
		margin-top: 80px;
		padding-bottom: 80px;
		.img_holder {
			margin: 0 auto;
			width: 560px;
			img {
				margin: 0 auto;
				// margin-top: -100px;
//				width: 100%;
				width: 80%;
			}
		}
		.text_holder {
			width: 750px;
			margin: 0 auto;
			h4 {
				margin: 64px 0;
				font-size: 25px;
			}
			ol {
				margin-bottom: 46px;
			}
			p {
				margin-bottom: 44px;
			}
		}
		.signature {
			width: 55%;
			margin-bottom: 108px;
			float: right;
			p {
				margin: 0;
			}
			span {
				display: inline-block;
				vertical-align: middle;
			}
			img {
				width: 35%;
				display: inline-block;
				vertical-align: middle;
				float: right;
				clear: both;
			}
		}
	}
}


/* ---------- company index ---------- */

#company {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/company/company-bg.png');
			margin-bottom: 80px;
		}
	}
	.content_holder {
		margin-top: 80px;
		padding-bottom: 80px;
	}
	.big_heading {

		p {
			padding-top: 30px;

			&.eng {
				padding: 0;
			}
		}
	}
	.ico_circle {
		background: $cl-blue;
		padding: 32px 10px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.box {
		width: calc(100%/3);
		// clear: both;
		position: relative;
		padding: 0 10px;
		// margin: 10px;
		// .iholder {
		// 	position: relative;
		// }
		img {
			max-width: 100%;
		    width: 100%;
		}
		a {
			// bottom: 0;
			// position: absolute;
			// display: block;
			border: 1px solid #ddd;
			width: 100%;
			color: $cl-blue;
			height: 80px;
			line-height: 80px;
			background: rgba(255, 255, 255, 0.8);
			// z-index: 9999;
			text-align: center;
			text-decoration: none;
			font-weight: bold;
			font-size: $fs-18;
			display: table;
			table-layout: fixed;
			width: 100%;
			position: relative;
			// padding: 0 20px;
			// &:after {
			// 	// float: right;
			// 	content: url('../images/corp/common/ico-arrow-right-blue.png');
			// 	// vertical-align: middle;
			// 	// clear: both;
			// 	position: absolute;
			// 	right: 10px;
			// 	top: 50%;
			// 	transform: translateY(-50%);
			// 	-webkit-transform: translateY(-50%);
			// 	-ms-transform: translateY(-50%);
			// }
			&:hover {
				color: $cl-lightBlue;
			}
		}
		.box_image {
			display: table-cell;
			width: 50%;
			vertical-align: middle;
		}
		.box_text {
			display: table-cell;
			width: 50%;
			vertical-align: middle;
			text-align: center;
		}
	}
}
