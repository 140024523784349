/*
  pc information
--------------------------------------------- */


/* ---------- information archive ---------- */

#information_archive {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/information/information-bg.png');
			margin-bottom: 80px;
		}
	}
	.content_holder {
		margin-bottom: 80px;
	}
	.big_heading p {
		padding-top: 30px;
		&.eng {
			padding: 0;
		}
	}
	.container {
		@include clearfix;
		margin: 80px auto 110px;
		width: 930px;
		.article_cont {
			width: calc(95% - 230px);
			margin-right: 5%;
			float: left;
			.article_wrap {
				padding-bottom: 20px;
			}
		}
		.article_sidebar {
			width: 230px;
			float: right;
			.archive_tit {
				font-size: $fs-16;
				font-weight: bold;
				text-align: center;
				padding: 10px 0;
				border: {
					top: 1px;
					bottom: 1px;
					color: $cl-blue;
					style: solid;
				}
			}
		}
	}
	.article_box {
		text-align: left;
		margin: 0 2% 20px;
		@include clearfix;
		&:hover {
			.article_tit {
				color: #9d56e4;
			}
		}
		p.article_date {
			font-size: 12px;
			color: #666666;
			float: left;
			padding-top: 2px;
		}
		.article_tit {
			float: left;
			color: #000000;
			font-size: 14px;
			margin-left: 20px;
			width: 535px;
		}
	}
	.page-nation {
		letter-spacing: normal;
		margin: 50px 0;
		ul {
			li {
				padding: 0 6px;
				display: inline-block;
				a {
					display: block;
					text-align: center;
					width: 45px;
					line-height: 45px;
					border-radius: 4px;
					border: 1px #b7b2ca solid;
					background: #fff;
					color: #b7b2ca;
					text-decoration: none;
				}
				&.current {
					a {
						color: #fff;
						background: #b7b2ca;
					}
				}
			}
		}
	}
	/* ---------- sidebar ---------- */
	.archive {
		// border: 1px #b7b2ca solid;
		.archive_list {
			// padding: 15px 13px 10px;
			li {
				position: relative;
				margin-bottom: 6px;
				a {
					display: block;
					line-height: 45px;
					// background: #f4f3f7;
					text-decoration: none;
					color: $color;
					padding: 0 20px;
					border-bottom: 1px dashed $cl-blue;

					&:after {
					    float: right;
					    content: url(../images/corp/common/ico-arrow-right-blue.png);
					    vertical-align: middle;
					    clear: both;
					}
					i {
						color: #2d205f;
						position: absolute;
						right: 20px;
						top: 50%;
						margin-top: -7px;
					}
				}
				// &:last-child {
				// 	a {
				// 		border-bottom: none;
				// 	}
				// }
			}
		}
	}
}


/* ---------- information single ---------- */

#information_single,
.single-post {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/information/information-bg.png');
			margin-bottom: 80px;
		}
	}
	.content_holder {
		margin-bottom: 80px;
	}
	.big_heading {
		// padding-top: 30px;
		width: 800px;
		margin: 0 auto;
		p.article_date {
			text-align: left;
			margin-bottom: 0;
		}
		h3 {
			margin-top: 40px;
		}
	}
	.container {
		margin: 80px auto 110px;
		width: 800px;
		.single_cont {
			p {
				margin-top: 30px;
			}
			.mid_tit {
				@include clearfix;
				font-size: 22px;
				margin-top: 70px;
				.mid_tit_img {
					float: left;
					padding-top: 17px;
				}
				.mid_tit_txt {
					float: left;
					margin-top: 0;
					padding-left: 15px;
				}
			}
		}
	}
}
