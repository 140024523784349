/*
  sp information
--------------------------------------------- */


/* ---------- information archive ---------- */

#information_archive {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/information/information-bg.png');
		}
	}
	.content_holder {
		width: 94%;
		margin: 0 auto 80px;
	}
	.big_heading p {
		padding-top: 30px;
		&.eng {
			padding: 0;
		}
	}
	.container {
		margin: 50px auto;
		.article_cont {
			width: 100%;
			padding-bottom: 50px;

			.article_box {
				margin-bottom: 20px;

				a {
					text-decoration: none;
				}
				.article_date {
					font-size: 12px;
					color: #666666;
					padding-top: 2px;
				}
				.article_tit {
					color: #000000;
					font-size: 14px;
					width: 100%;
				}
			}
		}
		.article_sidebar {
			width: 100%;
			.archive_tit {
				font-size: $fs-16;
				font-weight: bold;
				text-align: center;
				padding: 10px 0;
				border: {
					top: 1px;
					bottom: 1px;
					color: $cl-blue;
					style: solid;
				}
			}
		}
	}
	.page-nation {
		letter-spacing: normal;
		margin: 50px 0;
		ul {
			li {
				padding: 0 3px;
				display: inline-block;
				a {
					display: block;
					text-align: center;
					width: 45px;
					line-height: 45px;
					border-radius: 4px;
					border: 1px #b7b2ca solid;
					background: #fff;
					color: #b7b2ca;
					text-decoration: none;
				}
				&.current {
					a {
						color: #fff;
						background: #b7b2ca;
					}
				}
			}
		}
	}
	/* ---------- sidebar ---------- */
	.archive {
		// border: 1px #b7b2ca solid;
		.archive_list {
			// padding: 15px 13px 10px;
			li {
				position: relative;
				margin-bottom: 6px;
				a {
					display: block;
					line-height: 45px;
					// background: #f4f3f7;
					text-decoration: none;
					color: $color;
					padding: 0 20px;
					border-bottom: 1px dashed $cl-blue;
					&:after {
					    float: right;
					    content: url(../images/corp/common/ico-arrow-right-blue.png);
					    vertical-align: middle;
					    clear: both;
					}
					i {
						color: #2d205f;
						position: absolute;
						right: 20px;
						top: 50%;
						margin-top: -7px;
					}
				}
				// &:last-child {
				// 	a {
				// 		border-bottom: none;
				// 	}
				// }
			}
		}
	}
}


/* ---------- information single ---------- */

#information_single,
.single-post {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/information/information-bg.png');
		}
	}
	.content_holder {
		width: 94%;
		margin: 0 auto 80px;
	}
	.big_heading {
		width: 94%;
		margin: 0 auto;
		padding-top: 30px;
		p.article_date {
			text-align: left;
		}
	}
	.container {
		margin: 45px auto 110px;
		width: 100%;
		.single_cont {
			p {
				margin: 30px auto;
			}
			.mid_tit {
				@include clearfix;
				font-size: 22px;
				margin: 50px auto 0;
				.mid_tit_img {
					float: left;
					padding-top: 15px;
					margin-right: 10px;
				}
				.mid_tit_txt {
					margin: 0;
					line-height: 1.5;
				}
			}
		}
	}
}
