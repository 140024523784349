/*
  pc contact
--------------------------------------------- */


/* ---------- contact index ---------- */

#inquiry {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/contact/contact-bg.png');
			margin-bottom: 80px;
		}
	}
	.content_holder {
		margin-bottom: 80px;
	}
	.big_heading p {
		padding-top: 30px;
		&.eng {
			padding: 0;
		}
	}
	.ico_circle {
		background: $cl-blue;
		padding: 30px 10px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.inner_parts {
		margin-top: 80px;
	}
	.form_cont {
		.form_inner {
			// border: 2px solid $cl-lightPurple;
			padding: 0 50px;
		}
		label {
			span {
				background: $cl-red;
				color: #fff;
				border-radius: 4px;
				display: inline-block;
				// padding: 3px 6px 5px;
			    padding: 4px 6px 4px;
				font-size: $fs-10;
				line-height: 1;
				margin-left: 10px;
			}
		}
		table {
			width: 100%;
			th,
			td {
				padding: 12px 0;
			}
			th {
				padding: 20px 0;
				width: 230px;
				vertical-align: top;
			}
			td {
				input[type="text"],
				input[type="email"],
				textarea {
					width: 100%;
					border-radius: 4px;
					border: 1px #ddd solid;
					padding: 8px;
					font-family: $font-family;
				}
				textarea {
					height: 260px;
				}
				.formrun-system-show {
					color: $cl-red;
					font-size: $fs-12;
				}
			}
		}
		input[type="button"],
		input[type="submit"],
		button {
			-webkit-appearance: none;
			background-color: transparent;
			border: none;
			cursor: pointer;
			outline: none;
			padding: 0;
			appearance: none;
		}
		.submit_btn {
			text-align: center;
			margin: 50px auto;
		}
		button[type="submit"] {
			font-family: $font-family;
			// font-size: $fs-18;
			// text-align: center;
			// text-decoration: none !important;
			// color: #fff;
			// display: inline-block;
			// width: 386px;
			// height: 60px;
			// line-height: 60px;
			// background: #778fce;
			// border-radius: 6px;
			font-size: $fs-18;
			text-align: center;
			text-decoration: none !important;
			display: inline-block;
			width: 386px;
			height: 60px;
			line-height: 60px;
			background: #fff;
			border: 1px solid #110051;
			color: #110051;
		    padding: 0 30px;
	        border-radius: 30px;
			// -webkit-box-shadow: 2px 2px 2px 0 rgba(121, 131, 160, 1);
			// -moz-box-shadow: 2px 2px 2px 0 rgba(121, 131, 160, 1);
			// box-shadow: 2px 2px 2px 0 rgba(121, 131, 160, 1);
			// &:after {
			// 	padding-top: 5px;
			// 	padding-right: 20px;
			// 	content: url('../images/corp/common/ico-arrow-right-btn.png');
			// 	display: inline-block;
			// 	vertical-align: middle;
			// 	float: right;
			// 	clear: both;
			// }
			&:hover {
				// background: $cl-blue;
				background: #110051;
				color: #fff;
			}
		}
	}
	.txt_link {
		text-align: center;
	}
	.is-visible {
		display: block;
	    color: rgb(248, 85, 80);
	    font-size: 0.85em;
	}
}


/* ---------- contact thankyou ---------- */

#thankyou {

	.main_heading {

		 .overlay {
			background-image: url('../images/corp/contact/contact-bg.png');
		 	margin-bottom: 80px;
		 }
	}

	.big_heading {

		h3 {
			margin-bottom: 50px;
		}
	}

	.form_thankyou_cont {
		max-width: 930px;
		text-align: center;
		margin: 0 auto;
		p {
			margin: 0 auto 30px;
			text-align: center;
		}
	}

	.inner_parts {
		margin-top: 0;
		padding-bottom: 100px;
	}

	.btn_wrap {
		margin: 70px 0;
	}
}
