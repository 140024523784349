//  -------------------- mixin
//　-----　clearfix
@mixin clearfix {
	*zoom: 1;
	&:after {
		content: ".";
		display: block;
		clear: both;
		height: 0;
		visibility: hidden;
	}
}

// ----- btn
@mixin btn-70 {
	a {
		font-family: $font-family-sans;
		font-size: $fs-18;
		letter-spacing: .1em;
		text-decoration: none;
		text-align: center;
		display: block;
		background: #fff;
		line-height: 66px;
		border-radius: 35px;
		margin: 0 auto;
		&:hover {
			color: #fff;
		}
	}
}

// ----- title
@mixin tit-line-cont {
	position: relative;
	text-align: center;
	margin-bottom: 20px;
	&:before {
		width: 100%;
		content: "";
		border-top: 1px #ddd solid;
		@include wv-center;
		z-index: 1;
	}
	@media (max-width: 767px) {
		margin-bottom: 10px;
	}
}

// ----- color gradation
@mixin gr-blue {
	background: #c5f3e2;
	background: -moz-linear-gradient(left, #c5f3e2 0%, #d4e0fc 100%);
	background: -webkit-linear-gradient(left, #c5f3e2 0%, #d4e0fc 100%);
	background: linear-gradient(to right, #c5f3e2 0%, #d4e0fc 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c5f3e2', endColorstr='#d4e0fc', GradientType=1);
}

@mixin gr-pink {
	background: #b7ddf1;
	background: -moz-linear-gradient(left, #b7ddf1 0%, #fad4d8 100%);
	background: -webkit-linear-gradient(left, #b7ddf1 0%, #fad4d8 100%);
	background: linear-gradient(to right, #b7ddf1 0%, #fad4d8 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b7ddf1', endColorstr='#fad4d8', GradientType=1);
}

@mixin gr-green {
	background: #cdeedc;
	background: -moz-linear-gradient(left, #cdeedc 0%, #f2f3cc 100%);
	background: -webkit-linear-gradient(left, #cdeedc 0%, #f2f3cc 100%);
	background: linear-gradient(to right, #cdeedc 0%, #f2f3cc 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cdeedc', endColorstr='#f2f3cc', GradientType=1);
}

@mixin gr-btn-blue {
	background: #1090d2;
	background: -moz-linear-gradient(left, #1090d2 0%, #36b7b2 100%);
	background: -webkit-linear-gradient(left, #1090d2 0%, #36b7b2 100%);
	background: linear-gradient(to right, #1090d2 0%, #36b7b2 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1090d2', endColorstr='#36b7b2', GradientType=1);
}

@mixin gr-btn-pink {
	background: #ef707d;
	background: -moz-linear-gradient(left, #ef707d 0%, #f2986f 100%);
	background: -webkit-linear-gradient(left, #ef707d 0%, #f2986f 100%);
	background: linear-gradient(to right, #ef707d 0%, #f2986f 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef707d', endColorstr='#f2986f', GradientType=1);
}

@mixin gr-btn-green {
	background: #34c272;
	background: -moz-linear-gradient(left, #34c272 0%, #0bd05f 100%);
	background: -webkit-linear-gradient(left, #34c272 0%, #0bd05f 100%);
	background: linear-gradient(to right, #34c272 0%, #0bd05f 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34c272', endColorstr='#0bd05f', GradientType=1);
}

@mixin gr-btn-orange {
	background: #ff9537;
	background: -moz-linear-gradient(left, #ff9537 0%, #ff6845 100%);
	background: -webkit-linear-gradient(left, #ff9537 0%, #ff6845 100%);
	background: linear-gradient(to right, #ff9537 0%, #ff6845 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9537', endColorstr='#ff6845', GradientType=1);
}


// placeholder
@mixin placeholder($color) {
	&::-webkit-input-placeholder {
		color:$color;
	}
	&:-moz-placeholder {
		color:$color;
	}
	&:-ms-input-placeholder {
		color:$color;
	}
}
