/*
  sp company
--------------------------------------------- */


/* ---------- company access ---------- */

#access {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/company/company-bg.png');
		}
	}
	.content_holder {
		width: 94%;
		margin: 50px auto 80px;
	}
	.big_heading p {
		padding-top: 30px;
		&.eng {
			padding: 0;
		}
	}
	.ico_circle {
		background: #ddd;
		padding: 12px 5px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.inner_parts {
		margin: 0 auto;
		width: 100%;
		margin-bottom: 80px;
		max-width: 100%;
		.ico_circle_sm {
			padding: 10px 3px;
			img {
				width: 14px;
				margin: 0 auto;
				text-align: center;
			}
		}
		.box1 {
			margin-bottom: 30px;

			> .box {
				margin-bottom: 10px;
			}
		}
		.box {
			padding: 0;
			.map {
				border: 1px #ddd solid;
				padding: 6px 6px 0;
				iframe {
					width: 100%;
					height: 160px;
				}
			}
			.btn_wrap {
				width: 100%;
				padding: 15px 0;
				text-align: right;
				border-bottom: 1px $cl-gray dashed;
				a {
					margin: 0 0 0 15px;
				}
				.plus {
					position: relative;
					font-size: $fs-13;
					&:before {
						content: "";
						width: 12px;
						height: 12px;
						display: inline-block;
						vertical-align: middle;
						padding-right: 6px;
						background: url(../images/corp/company/ico-plus.png) no-repeat left center;
					}
				}
				.btn-navi {
					width: 152px;
				}
			}
			.detail {
				width: 100%;
				margin-top: 20px;
				th,
				td {
					vertical-align: top;
					display: block;
				}
				th {
					width: 120px;
				}
				td {
					padding-bottom: 10px;
				}
			}
		}
	}
}


/* ---------- company profile ---------- */

#profile {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/company/company-bg.png');
		}
	}
	.content_holder {
		margin-top: 50px;
		margin-bottom: 80px;
	}
	.big_heading p {
		padding-top: 30px;
		&.eng {
			padding: 0;
		}
	}
	.ico_circle {
		background: #ddd;
		padding: 12px 5px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.inner_parts {
		margin: 0 auto;
		width: 100%;
		border-top: 2px solid $cl-blue;
		margin-bottom: 80px;
		// padding: 10px 4%;
		table {
			width: 100%;
			th,
			td {
				width: 100%;
				display: block;
				vertical-align: top;
				position: relative;
			}
			tr:first-child {
				th,
				td {
					border-top: none;
				}
			}
			th {
				padding-top: 10px;
				width: 166px;
				font-weight: bold;
			}
			td {
				padding-bottom: 10px;
				border-bottom: 1px dashed $cl-grey;
			}
			.btn_wrap {
				margin: 10px auto 0 0;
				text-align: right;
				.btn-l {
					width: 190px;
				    font-size: $fs-13;
				    margin-right: 0;
				    padding: 0;
				    // padding-right: 10px;
				    position: relative;
				    &:after {
			    	    content: "";
			    	    background: url(../images/corp/common/ico-arrow-right-btn.png);
					    background-position: right center;
			            background-repeat: no-repeat;
			            background-size: contain;
			            padding: 0;
					    width: 7px;
					    height: 13px;
					    position: absolute;
					    right: 10px;
					    top: 50%;
					    -webkit-transform: translateY(-50%);
					    -ms-transform: translateY(-50%);
					    transform: translateY(-50%);
				    }
				}
			}
		}
	}
}


/* ---------- company message ---------- */

#message {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/company/company-bg.png');
		}
	}
	.content_holder {
		position: relative;
		margin: 0 auto;
		width: 94%;
		// border: 2px solid $cl-lightPurple;
		margin-top: 50px;
		margin-bottom: 80px;
		.img_holder {
			// width: 50%;
			margin: 0 auto;
			img {
				margin: 0 auto;
				width: 100%;
				// margin-top: -70px;
			}
		}
		.text_holder {
			// width: 90%;
			margin: 0 auto;
			h4 {
				margin: 30px 0 20px;
				font-size: $fs-18;
			}
			ol {
				margin: 0 0 30px;
			}
			p {
				margin-bottom: 30px;
			}
		}
		.signature {
			width: 100%;
			margin-bottom: 30px;
			float: right;
			p {
				margin: 0;
			}
			span {
				display: inline-block;
				vertical-align: middle;
			}
			img {
				display: inline-block;
				vertical-align: middle;
				float: right;
				margin: 4px 20px 0 0;
				clear: both;
			}
		}
	}
}


/* ---------- company index ---------- */

#company {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/company/company-bg.png');
		}
	}
	.content_holder {
		margin-bottom: 80px;

		.inner_parts {
			max-width: 100%;
		}
	}
	.s1 {
		width: 94%;
		margin: 0 auto;
	}
	.big_heading p {
		padding-top: 30px;
		&.eng {
			padding: 0;
		}
	}
	.ico_circle {
		background: $cl-blue;
		padding: 12px 5px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.box {
		width: 100%;
		position: relative;
		padding: 0;
		margin-bottom: 30px;
		// .iholder {
		// 	width: 100%;
		// 	height: 220px;
		// 	overflow: hidden;
		// 	position: relative;
		// 	margin-bottom: 20px;
		// }
		img {
			// position: absolute;
			// top: 50%;
			// left: 50%;
			// transform: translate( -50%, -50%);
			// margin: 0 auto;
			width: 100%;
		}
		a {
			// bottom: 0;
			// position: absolute;
			// display: block;
			border: 1px solid #ddd;
			width: 100%;
			color: $cl-blue;
			height: 64px;
			line-height: 64px;
			background: rgba(255, 255, 255, 0.8);
			// z-index: 9999;
			text-align: center;
			text-decoration: none;
			font-weight: bold;
			font-size: $fs-16;
			// padding: 0 20px;
			display: table;
			position: relative;
			// &:after {
			// 	float: right;
			// 	content: url('../images/corp/common/ico-arrow-right-blue.png');
			// 	vertical-align: middle;
			// 	clear: both;
			// }
			&:hover {
				color: $cl-lightBlue;
			}
		}
		.box_image {
			display: table-cell;
			width: 50%;
			vertical-align: middle;
		}
		.box_text {
			display: table-cell;
			width: 50%;
			vertical-align: middle;
			text-align: center;
		}
	}
}
