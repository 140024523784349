/*
  sp add
--------------------------------------------- */

/* ---------- privacy ---------- */

#privacy {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/company/company-bg.png');
		}
	}
	.inner_parts {
		padding-bottom: 50px;
	}

	.cont {
		margin: 50px 0;
	}

	.big_heading {

		p {
			padding-top: 30px;
			width: 94%;
			margin: 0 auto;

			&.eng {
				padding-top: 0;
			}
		}
	}

	a.btn-l {
		display: block;
		margin: 0 auto;
	    line-height: 47px;
	    padding-right: 10px;
	    position: relative;

	    &:after {
    	    content: "";
    	    background: url(../images/corp/common/ico-arrow-right-btn.png);
		    background-position: right center;
            background-repeat: no-repeat;
            background-size: contain;
            padding: 0;
		    width: 7px;
		    height: 12px;
		    position: absolute;
		    right: 10px;
		    top: 50%;
		    -webkit-transform: translateY(-50%);
		    -ms-transform: translateY(-50%);
		    transform: translateY(-50%);
	    }
	}

	.ordered_list {
		list-style-type: decimal;
		margin-left: 20px;

		li {
			padding-left: 5px;
		}
	}

	.unordered_list {

		li {
		    margin-left: 1em;
		    text-indent: -1em;
		    padding-left: 0;

			&:before {
				content: "・";
			}
		}
	}
}
