/*
  base
--------------------------------------------- */

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

img {
	border-style: none;
	display: block;
	vertical-align: bottom;
}

a,
a img,
button {
	transition: .4s;
}

a img:hover {
	opacity: .7;
}

a:link {
	color: $color;
	text-decoration: none;
}

a:visited {
	color: $color;
}

a:hover {
	color: $color;
	text-decoration: none;
}

a:active {
//	color: $color;
}

.center {
	text-align: center;
}
