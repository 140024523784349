/*
  pc add
--------------------------------------------- */

/* ---------- privacy ---------- */

#privacy {

	.main_heading {
		.overlay {
			background-image: url(../images/corp/company/company-bg.png);
		    margin-bottom: 80px;
		}
	}

	.inner_parts {
		padding-bottom: 100px;
	}

	.cont {
		margin: 50px 0;

		p {
			font-size: 14px;
		}
	}

	.big_heading {
		margin-bottom: 80px;

		p {
			padding-top: 30px;

			&.eng {
				padding: 0;
			}
		}
	}

	a.btn-l {
		display: block;
		margin: 0 auto;
	}

	.ordered_list {
		list-style-type: decimal;
		margin-left: 20px;

		li {
			padding-left: 5px;
		}
	}

	.unordered_list {

		li {
		    margin-left: 1em;
		    text-indent: -1em;
		    padding-left: 0;

			&:before {
				content: "・";
			}
		}
	}
}
