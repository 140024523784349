/*
  sp layout
--------------------------------------------- */

body {
	background: #ddd;
	line-height: $line-height;
	font-size: $font-size;
	height: 100%;
	font-family: $font-family;
	position: relative;
	font-kerning: normal;
	box-sizing: border-box;
}

.wrapper {
	margin: 0 auto;
	background: #fff;
}

html {
	height: 100%;
}

img {
	max-width: 100%;
	height: auto;
}

.pc_only {
	display: none !important;
}

.inner {
	width: 94%;
	margin: 0 auto;
	position: relative;
}

.inner_mid {
	width: 100%;
	margin: 0 auto;
	position: relative;
}

.inner_min {
	padding: 0 2%;
	margin: 0 auto;
	position: relative;
}

.inner_sm {
	width: 94%;
	margin: 0 auto;
	position: relative;
}

.container {
	@include clearfix;
	padding: 0 2%;
	margin: 0 auto;
	position: relative;
}


/*
  sp heading
--------------------------------------------- */

.heading {
	text-align: center;
}

a {
	text-decoration: none;
}

.next-btn {
	text-align: center;
}

a.next {
	text-decoration: none;
	&:after {
		content: url('../images/corp/common/ico-arrow-right.png');
		display: inline-block;
		padding-left: 10px;
	}
}


/*
  sp header
--------------------------------------------- */

.header {
	.holder {
		min-height: 40px;
		line-height: 40px;
		.inner {
			width: 100%;
		}
		.logo_holder {
			vertical-align: middle;
			width: 100%;
			text-align: center;
			margin: 0 auto;
			img {
				width: 46px;
				display: inline-block;
				vertical-align: middle;
			}
			.logo_txt {
				color: $cl-blue;
				display: inline-block;
				font-size: $fs-10;
				letter-spacing: .2em;
			}
		}
		.g_nav {
			vertical-align: middle;
			width: 100%;
			.nav {
				// letter-spacing: -1em;
				// letter-spacing: -.4em;
				line-height: 30px;
				@include clearfix;
			}
			li {
				letter-spacing: normal;
				position: relative;
				// display: inline-block;
				width: 33.33%;
				border-bottom: 1px #ddd solid;
				border-right: 1px #ddd solid;
				float: left;
				a {
					color: $cl-blue;
					letter-spacing: .05em;
					font-size: $fs-13;
					padding: 0 2%;
					text-align: center;
					display: block;
					text-decoration: none;
				}
				&:first-child {
					width: 50%;
					line-height: 35px;
					font-weight: bold;
					border-top: 5px $cl-purple solid;
					border-right: none;
					a {
						border-right: 1px #ddd solid;
					}
					&.active {
						background: $cl-purple;
						a {
							color: #fff;
						}
					}
				}
				&:nth-child(2) {
					width: 50%;
					line-height: 35px;
					font-weight: bold;
					border-top: 5px $cl-blue solid;
					border-right: none;
					&.active {
						background: $cl-blue;
						a {
							color: #fff;
						}
					}
				}
				&:last-child {
					border-right: none;
				}
			}
		}
	}
	.sub_box {
		visibility: hidden;
		opacity: 0;
		z-index: 999;
		position: fixed;
		width: 100%;
		top: 30px;
		height: 280px;
		color: #000;
		background: #fff;
		transition: all 0.5s ease-in-out;
		-webkit-overflow-scrolling: touch;
		overflow-scrolling: touch;
		&.in {
			top: 0;
			visibility: visible;
			opacity: 1;
		}
		.wrap {
			@include clearfix;
		}
		p {
			padding: 10px;
			color: #fff;
			border-bottom: 1px solid #fff;
			& span {
				display: inline-block;
				vertical-align: middle;
			}
			& a.close {
				display: inline-block;
				float: right;
				clear: both;
				border: 0px;
				margin: 5px;
			}
		}
	}
	#sp_li_1 {
		background-image: url('../images/corp/common/sp-miraimo-bg.png');
		background-size: cover;
		height: 100vh;
		overflow-y: scroll;
		padding-bottom: 10px;
		& ul {
			// padding-bottom: 40px;
			li {
				text-align: center;
				img {
					max-width: 50%;
					display: inline-block;
				}
			}
			li:first-child {
				border-bottom: 1px dashed $cl-purple;
				padding: 25px 0px;
				// margin-bottom: 20%;
				margin-bottom: 60px;
			}
		}
		& p {
			background: $cl-purple;
		}
		a {
			// display: block;
			font-size: $font-size;
			color: $cl-purple;
			text-align: center;
			text-decoration: none;
	        transition: none;
	        -webkit-text-size-adjust: 100%;
		}
	}
	#sp_li_2 {
		background: $cl-blue;
		height: 100vh;
		overflow-y: scroll;
		& ul {
			// margin-top: 30%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
			-webkit-transform: translateY(-50%) translateX(-50%);
			-ms-transform: translateY(-50%) translateX(-50%);

			@media screen and (min-width: 568px) {
				margin: 40px 0 0;
			    padding-bottom: 20px;
			}
		}
		a {
			display: block;
			font-size: $font-size;
			color: #fff;
			text-align: center;
			text-decoration: none;
		}
	}
}


/*
  sp footer
--------------------------------------------- */

.footer {
	border-top: 1.5px solid $cl-blue;
	margin: 0 auto;
	.holder {
		.logo_holder {
			width: 74px;
			padding: 50px 0 14px;
			vertical-align: middle;
			img {
				vertical-align: middle;
			}
		}
		.footer_nav {
			letter-spacing: -1em;
			vertical-align: middle;
			padding: 20px 0 0;
			li {
				letter-spacing: normal;
				width: 50%;
				display: inline-block;
				position: relative;
				vertical-align: middle;
				a {
					text-decoration: none;
					color: $cl-blue;
					font-size: $fs-10;
					display: block;
					color: $cl-gray;
					&:hover {
						color: $cl-blue;
					}
					img {
						height: 16px;
					}
				}
			}
		}
	}
	.copyright {
		font-family: $font-family-pt-sans;
		font-size: $fs-10;
		padding-bottom: 20px;
	}
	&_sns {
		ul {
			text-align: center;
			li {
				display: inline-block;
				padding: 0;
				margin: 20px 4px;
				a {
					width: 38px;
					height: 38px;
					display: block;
					border-radius: 20px;
					border: 1px $color solid;
					text-align: center;
					margin: 0 auto;
					img {
						width: auto;
						height: 36px;
						text-align: center;
						margin: 0 auto;
						padding: 10px 0;
					}
				}
			}
		}
	}
}


/*
  sp common area
--------------------------------------------- */

.area_tit {
	text-align: center;
	font-size: $fs-32;
	font-family: $font-family-sans;
	letter-spacing: .075em;
	line-height: 1.6;
	padding: 70px 0 60px;
	span {
		display: block;
		font-size: 16px;
		font-family: $font-family-pt-sans;
		letter-spacing: .1em;
	}
}


/* ---------- area blog ---------- */

.area_blog {
	padding-bottom: 10px;
	.area_tit {
		span {
			color: $cl-lightGreen;
		}
	}
	&_cont {
		letter-spacing: -1em;
		margin: 0 -10px;
		&_box {
			letter-spacing: normal;
			text-align: left;
			display: inline-block;
			margin: 0 2% 20px;
			width: 46%;
			max-width: 292px;
			.img {
				padding-bottom: 6px;
				img {
					width: 100%;
				}
			}
			.date {
				font-size: $fs-10;
				font-weight: bold;
				line-height: 14px;
			}
			.tag {
				display: inline-block;
				font-size: $fs-10;
				text-align: center;
				padding: 0 8px;
				color: $cl-lightGreen;
				line-height: 18px;
				border: 1px $cl-lightGreen solid;
				border-radius: 9px;
			}
			.tit {
				font-size: $fs-12;
				padding: 8px 0;
				line-height: 1.5;
			}
			a {
				text-decoration: none;
				&:hover {
					color: $cl-lightGreen;
				}
			}
		}
	}
}

#home {
	.area_blog_cont {
		margin: 0;
		&_box {
			width: 92%;
			max-width: inherit;
			margin: 0 auto 20px;
			display: block;
			.date {
				display: inline-block;
				padding-right: 10px;
			}
		}
	}
}


/*
  sp page
--------------------------------------------- */

.pages {
	background: url(../images/corp/common/head_bg.png) no-repeat center top;
	background-size: contain;
	.area_key {
		padding: 20px 0 100px;
		.area_tit {
			padding: 70px 0 40px;
		}
	}
}


/* ---------- buttons ---------- */

.btn {
	background: #fff;
	display: inline-block;
	padding: 0 30px;
	font-size: $font-size;
	text-decoration: none;
	text-align: center;
	border-radius: 25px;
	line-height: 44px;
}

.btn-purple {
	border: 1px solid $cl-purple;
	color: $cl-purple;
	&:hover {
		background: $cl-purple;
		color: #fff;
	}
}

.btn-orange {
	border: 1px solid $cl-orange;
	color: $cl-orange;
	&:hover {
		background: $cl-orange;
		color: #fff;
	}
}

.btn-lightGreen {
	border: 1px solid $cl-lightGreen;
	color: $cl-lightGreen;
	&:hover {
		background: $cl-lightGreen;
		color: #fff;
	}
}
