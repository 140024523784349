/*
  pc business
--------------------------------------------- */


/* ---------- business index ---------- */

#business {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
			margin-bottom: 80px;
		}
	}
	.inner_parts {
		padding-bottom: 80px;
	}
	.big_heading {
		margin-bottom: 60px;
		p {
			padding-top: 30px;
			&.eng {
				padding: 0;
			}
		}
	}
	.ico_circle {
		background: $cl-blue;
		padding: 32px 10px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.cont {
		margin: 20px 0;
	}
	.box-2 {
		margin-top: 20px;
		margin-bottom: 40px;
	    border-top: 2px solid $cl-blue;
        padding-top: 10px;
		&:first-child {
			margin-right: 40px;
		}
		&:last-child {
			margin-left: 0;
		}
	}
	.box {
		padding: 0;

		.title {
			color: #222;
		}
	}
	.box p {
		min-height: 90px;
	}
	.box_bottom {
		position: relative;
		margin-top: 20px;

		img {
			width: 100%;
			max-width: 200px;
		}
	}
	.box_btn {
		position: absolute;
		bottom: 0;
		right: 0;
		vertical-align: bottom!important;

		.btn-l {
		    width: 132px;
		    height: 34px;
		    line-height: 34px;
		    font-size: $fs-13;
		    margin-right: 0;
		    padding: 0;
			&.rounded {
				border-radius: 30px;
			}
		    // padding-right: 10px;

		    // &:after {
	    	//     content: "";
	    	//     background: url(../images/corp/common/ico-arrow-right-btn.png);
			   //  background-position: right center;
	     //        background-repeat: no-repeat;
	     //        background-size: contain;
	     //        padding: 0;
			   //  width: 5px;
			   //  height: 9px;
			   //  position: absolute;
			   //  right: 10px;
			   //  top: 50%;
			   //  -webkit-transform: translateY(-50%);
			   //  -ms-transform: translateY(-50%);
			   //  transform: translateY(-50%);
		    // }
		}
	}
	.ico_circle_sm img {
		margin: 0 auto;
	}
}


/* ---------- business recruitment ---------- */

#recruitment {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
			margin-bottom: 80px;
		}
	}
	.inner_parts {
		margin-bottom: 80px;
	}
	.big_heading {
		margin-bottom: 60px;

		h4 {
			margin-top: 80px;
		}

		p {
			&.eng {
				padding: 0;
			}
		}
	}
	.ico_circle {
		background: none;
		padding: 10px 0;
		margin-bottom: 60px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.cont {
		margin: 20px 0;
	}
	.box1 {
		margin-top: 40px;

		.box {

			&:first-child {
				margin-bottom: 10px;
			}
		}

		.article {
			border-top-color: $cl-blue;
			padding-top: 30px;
		}
	}
	.box-2 {
		margin-top: 20px;
		margin-bottom: 40px;
	    border-top: 2px solid $cl-blue;
	    padding-top: 10px;
		&:first-child {
			margin-right: 40px;
		}
		&:last-child {
			margin-left: 0;
		}
	}
	.box {
		padding: 0;
		&:first-child {
			margin-bottom: 20px;
		}

		.title {
			color: #222;
		}
	}
	span.smalltxt {
		font-size: 12px;
	}
	.ico_circle_sm img {
		margin: 0 auto;
	}
	.step {
		width: 615px;
		margin: 0 auto 30px;
		clear: both;
		.step_L {
			display: inline-block;
			p.step_L_txt {
				display: inline-block;
				font-size: 13px;
				color: #110051;
				padding-right: 10px;
			}
			.step_L_number {
				display: inline-block;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background: $cl-blue;
				color: #fff;
				padding: 0 5px;
			    font-size: $fs-22;
				text-align: center;
			    line-height: 60px;
				max-width: 60px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
			    letter-spacing: 3px;
				img {
					margin: 0 auto;
					padding-top: 4px;
				}
			}
		}
		.step_R {
			display: inline-block;
			width: 470px;
			p.txt1 {
				text-align: center;
				font-size: 20px;
				color: #2d2d2d;
				margin-bottom: 10px;
			}
			p.txt2 {
				text-align: center;
				font-size: 13px;
				color: #2d2d2d;
				margin-bottom: 15px;
				span.underline {
					text-decoration: underline;
				}
			}
		}
		.line {
			border-bottom: 1px solid #110051;
		}
	}
}


/* ---------- business service ---------- */

#service {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
			margin-bottom: 80px;
		}
	}
	.inner_parts {
		margin-bottom: 80px;
	}
	.big_heading {
		margin-bottom: 60px;

		h4 {
			margin-top: 80px;
		}

		p {
			&.eng {
				padding: 0;
			}
		}
	}
	.ico_circle {
		background: none;
		padding: 10px 0;
		margin-bottom: 60px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.box {
		padding: 0;

		.title {
			color: #222;
		}
	}
	.box1 {
		margin-top: 50px;

		.box-1 {
			padding: 20px;

			p {
				margin-bottom: 0;
			}
		}
	}
	.box-2 {
		margin-top: 20px;
		margin-bottom: 40px;
	    border-top: 2px solid $cl-blue;
	    padding-top: 10px;
		&:first-child {
			margin-right: 40px;
		}
		&:last-child {
			margin-left: 0;
		}
	}
	.article {
		border-top-color: $cl-blue;
	}
	.ico_circle_sm img {
		margin: 0 auto;
	}
}


/* ---------- business event ---------- */

#event {
	.big_heading {
		margin-bottom: 80px;

		h4 {
			margin-top: 80px;
		}
	}
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
			margin-bottom: 80px;
		}
	}
	.inner_parts {
		margin-bottom: 80px;
	}
	.big_heading p {
		&.eng {
			padding: 0;
		}
	}
	.box1 {

		> .box {
			margin-bottom: 10px;
		}
	}
	.box {
		padding: 0;

		.title {
			color: #222;
		}
	}
	.article {
		border-top-color: $cl-blue;
	}
	.ico_circle {
		background: none;
		padding: 10px 0;
		margin-bottom: 60px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.r_border {
		border-right: 1px solid #ccc;
		margin-right: 0;
		padding-right: 25px;
	}
	.box-2 {

		p {
			margin-bottom: 0;
		}
	}
}


/* ---------- business training ---------- */

#training {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
			margin-bottom: 80px;
		}
	}
	.inner_parts {
		margin-bottom: 80px;
	}
	.big_heading {
		margin-bottom: 80px;

		h4 {
			margin-top: 80px;
		}

		p {
			&.eng {
				padding: 0;
			}
		}
	}
	.ico_circle {
		background: none;
		padding: 10px 0;
		margin-bottom: 60px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.cont {
		margin: 50px 0;
	}
	.box {
		padding: 0;

		.title {
			color: #222;
		}
	}
	.article {
		border-top-color: $cl-blue;
		padding-top: 20px;
	}
	.r_border {
		border-right: 1px solid #ccc;
		margin-right: 0;
		padding-right: 25px;
	}
	.step {
		width: 599px;
		margin: 0 auto 30px;
		clear: both;
		.step_L {
			display: inline-block;
			p.step_L_txt {
				display: inline-block;
				font-size: 13px;
				color: #110051;
				padding-right: 10px;
			}
			.step_L_number {
				display: inline-block;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background: $cl-blue;
				color: #fff;
				padding: 0 5px;
			    font-size: $fs-22;
				text-align: center;
			    line-height: 60px;
				max-width: 60px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
			    letter-spacing: 3px;
				img {
					margin: 0 auto;
					padding-top: 4px;
				}
			}
		}
		.step_R {
			display: inline-block;
			width: 470px;
			.txt1 {
				text-align: center;
				font-size: 20px;
				color: #2d2d2d;
				margin-bottom: 10px;
			}
			.txt2 {
				text-align: center;
				font-size: 13px;
				color: #2d2d2d;
				margin-bottom: 15px;
				.underline {
					text-decoration: underline;
				}
			}
		}
		.line {
			border-bottom: 1px solid $cl-blue;
			display: block;
			margin-left: auto;
		}
	}
	.program_steps {
		margin-top: 80px;

		.title {
			color: #222;
		}
	}
}


/* ---------- business consulting ---------- */

#consulting {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
			margin-bottom: 80px;
		}
	}
	.inner_parts {
		margin-bottom: 40px;
	}
	.big_heading {
		margin-bottom: 60px;

		&.last {
			margin-bottom: 30px;
		}

		p {
			&.eng {
				padding: 0;
			}
		}
	}
	h4.mintit {
		text-align: center;
		font-size: 26px;
		padding-top: 30px;
		position: relative;
		&:after {
			position: absolute;
			bottom: -20px;
			left: 0;
			right: 0;
			margin: 0 auto;
			content: url('../images/corp/business/business-title_border.png');
			display: block;
		}
	}
	.ico_circle {
		background: none;
		padding: 10px 0;
		margin-bottom: 60px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.box1 {
		margin-top: 50px;
	}
	.box-2 {
		margin-top: 20px;
		margin-bottom: 40px;
	    border-top: 2px solid $cl-blue;
	    padding-top: 10px;
	}
	.box {
		padding: 0;

		.title {
			color: #222;
		}
	}
	span.smalltxt {
		font-size: 12px;
	}
	.ico_circle_sm img {
		margin: 0 auto;
	}
	ol.steps {
		margin-left: 0;
		margin-bottom: 20px;
		li {
			padding: 15px 0;
			list-style: none;
			padding-left: 1.5em;
			vertical-align: middle;
			font-size: $fs-20;
			span {
		    	display: list-item;
		    	list-style-type: disc;
			}
		}
	}
	.step {
		width: 615px;
		margin: 0 auto 30px;
		clear: both;
		.step_L {
			display: inline-block;
			p.step_L_txt {
				display: inline-block;
				font-size: 13px;
				color: #110051;
				padding-right: 10px;
			}
			.step_L_number {
				display: inline-block;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background: $cl-blue;
				color: #fff;
				padding: 0 5px;
			    font-size: $fs-22;
				text-align: center;
			    line-height: 60px;
				max-width: 60px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
			    letter-spacing: 3px;
				img {
					margin: 0 auto;
					padding-top: 4px;
				}
			}
		}
		.step_R {
			display: inline-block;
			width: 470px;
			p.txt1 {
				text-align: center;
				font-size: 20px;
				color: #2d2d2d;
				margin-bottom: 10px;
			}
			p.txt2 {
				text-align: center;
				font-size: 13px;
				color: #2d2d2d;
				margin-bottom: 15px;
				span.underline {
					text-decoration: underline;
				}
			}
		}
		.line {
			border-bottom: 1px solid #110051;
		}
	}
	.wrap {
		// letter-spacing: -0.4em;
		// width: 615px;
		// margin: 0 auto;
		.imgbox {
			display: inline-block;
			margin-right: 30px;
			letter-spacing: normal;
		}
		.txtbox {
			display: inline-block;
			vertical-align: top;
			width: 285px;
			letter-spacing: normal;
		}
	}
	.backgray {
		background: #f4f3f7;
		padding: 20px 30px;
		margin: 20px auto 0;
		width: 615px;
		ul {
			li {
			    padding-left: 1em;
			    text-indent: -1em;
			}
		}
	}
	.consulting_hr {

		.box1 {

			.box {
				margin-bottom: 10px;
			}

			.article {
				border-top-color: $cl-blue;
			}
		}
	}
}


/* ---------- business fbg ---------- */

#fbg {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
			margin-bottom: 80px;
		}
	}
	.inner_parts {
		margin-bottom: 80px;
	}
	.big_heading {
		margin-bottom: 80px;

		h4 {
			margin-top: 80px;
		}

		p {
			&.eng {
				padding: 0;
			}
		}
	}
	.ico_circle {
		background: none;
		padding: 10px 0;
		margin-bottom: 60px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.r_border {
		border-right: 1px solid #ccc;
		margin-right: 0;
		padding-right: 25px;
	}
	ol.steps1 {
		margin-left: 0;
		margin-top: -20px;
		li {
			padding: 15px 0 15px 2em;
			list-style: none;
		    // text-indent: -1.3em;
		    span {
		    	display: list-item;
		    	list-style-type: disc;
		    }
		}
		li.border-t-none {
			border-top: none;
		}
		li.border-b-none {
			border-bottom: none;
		}
	}
	ol.steps {
		li.border-t-none {
			border-top: none;
		}
		li.border-b-none {
			border-bottom: none;
		}
	}
	.box {
		padding: 0;

		.title {
			color: #222;
		}
	}
	.article {
		border-top-color: $cl-blue;
		padding-top: 20px;
	}
	.box-2 {

		p {
			margin-bottom: 0;
		}
	}
	.date {
		margin: 10px 0 0;
	}
	.appointment {
		.steps {
			margin-left: 0;
			margin-top: -20px;
			li {
			    padding: 15px 0 15px 2em;
				list-style-position: inside;
				text-indent: -1.1em;
			}
		}
	}
}
