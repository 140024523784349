/*
  sp index header
--------------------------------------------- */

.header {
	position: relative;
}


/*
  sp hero
--------------------------------------------- */

.hero {
	background: url(../images/corp/common/hero_bg_sp.png) no-repeat center bottom;
	background-size: 100%;
	background-repeat: no-repeat;
	// min-height: 220px;
	position: relative;
    padding-top: 58.4375%;
	&_logo {
		width: 160px;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -80px;
		text-align: center;
		img {
			text-align: center;
			margin: 0 auto;
		}
	}
}

#home {
	/* ---------- visual text ---------- */
	.s1 {
		h2 {
			padding-top: 50px;
			text-align: center;
			font-size: $fs-19;
			color: $cl-blue;
			font-family: $font-family-shingo;
			letter-spacing: .08em;
			font-feature-settings: "palt";
		}
		p {
			font-family: $font-family-shingo;
			letter-spacing: .1em;
			font-size: $fs-13;
			line-height: 2em;
			text-align: center;
			margin: 25px auto 50px;
		}
	}
	/* ---------- info ---------- */
	.s2 {
		// background: url('../images/corp/common/info-bg_sp.png') no-repeat center top;
		// background-size: 100%;
		// border-top: 1px solid $cl-blue;
		padding: 50px 0 100px;
		min-height: 444px;
		text-align: center;
		h3 {
			text-align: center;
			font-size: $fs-19;
			position: relative;
			display: inline-block;
			padding-bottom: 5px;
			font-family: $font-family-shingo;
			letter-spacing: .2em;
			&:after {
				// width: 80px;
				// height: 3px;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				// left: 50%;
				// margin-left: -40px;
				content: "";
				// background: url('../images/corp/common/ico-border.png');
				// background-size: cover;
				display: block;
				height: 3px;
				width: 70px;
				background: $cl-blue;
			}
		}
		.info_holder {
			margin: 50px 0;
		}
		ul {
			text-align: left;
			li {
				margin-bottom: 20px;
				a {
					display: block;
					text-decoration: none;
					&:hover {
						color: $cl-blue;
						.title {
							text-decoration: underline;
						}
					}
				}
				.date {
					display: block;
					font-size: $fs-12;
					color: $cl-gray;
				}
				.title {
					vertical-align: top;
				}
			}
		}
		.next {
			&:hover {
				color: $cl-purple;
			}
		}
		.btn-l {
			width: 250px;
		}
	}
	/* ---------- miraimo ---------- */
	.s3 {
		background: url('../images/corp/common/mirai-bg_sp.png') no-repeat top;
		background-size: contain;
		.inner_sm {
			text-align: center;
			padding: 100px 0;
			.logo {
				display: inline-block;
				margin-bottom: 50px;
			}
			.text {
				// color: $cl-purple;
				h3 {
					font-size: $fs-19;
					font-weight: bold;
					margin-bottom: 20px;
				}
				p {
					margin-bottom: 25px;
				}
				a {
					color: $cl-orange;
					text-decoration: none;
					width: 250px;
					&:hover {
						color: #fff;
					}
				}
			}
		}
		.inner_mid {
			width: 100%;
			h3 {
				text-align: center;
				font-size: $fs-19;
				position: relative;
				display: inline-block;
				padding-bottom: 5px;
				margin-bottom: 45px;
				font-family: $font-family-shingo;
				letter-spacing: .2em;
				&:after {
					// width: 160px;
					// height: 3px;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					// left: 50%;
					// margin-left: -80px;
					content: "";
					// background: url('../images/corp/common/ico-border-gr.png');
					// background: cover;
					display: block;
					height: 3px;
					width: 160px;
					background: $cl-lightGreen;
				}
			}
			.next-btn {
				margin: 20px auto;
				.btn {
					color: $cl-lightGreen;
					text-decoration: none;
					&:hover {
						color: #FFF;
					}
				}
			}
			&.area_blog {
				padding-bottom: 100px;
			}
		}
	}
	/* ---------- miraimo-newgrads ---------- */
	.miraimo-newgrads {
		background: url(../images/corp/common/miraimo-newgrads_sp.png) no-repeat center bottom;
		background-size: cover;
		position: relative;
		padding-top: 187.5%;

		&-image {

			img {
				width: 100%;
			}
		}

		&-wrap {
			position: absolute;
			bottom: 10%;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
		}

		&-title {
			margin: 0 auto 20px;
			width: 296px;

			img {
				width: 100%;
			}
		}

		&-desc {
			color: #FFF;
			line-height: 1.857;
			margin-bottom: 30px;
		}

		.newgrads_button {
			font-weight: bold;
			text-align: center;
			display: block;
			background: #fff;
			line-height: 50px;
		    width: 92%;
		    border-radius: 35px;
			margin: 0 auto;
			background: #58c56e;
			background: -moz-linear-gradient(left, #58c56e 0%, #b4d451 100%);
			background: -webkit-linear-gradient(left, #58c56e 0%, #b4d451 100%);
			background: linear-gradient(to right, #58c56e 0%, #b4d451 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#58c56e', endColorstr='#b4d451', GradientType=1);
			color: #FFF !important;
			text-decoration: none !important;

			&:hover {
			    opacity: 0.75;
			}
		}
	}}


/* loading cont */

body .wrapper{
	height: auto;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 2s ease-in-out;
	-moz-transition: all 2s ease-in-out;
	transition: all 2s ease-in-out;

}

body .loading_cont{
	height: 0;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	display: none;
	& .hero{
		height: 0;
		opacity: 0;
		visibility: hidden;
	}
	& .hero_logo{
		bottom: 0px;
	}
}

body.loading{
	overflow: hidden;
}

body.loading .wrapper{
	height: 0;
	opacity: 0;
	visibility: hidden;
}

body.loading .loading_cont{
	overflow: hidden;
	width: 100%;
	position: fixed;
	height: 100%;
	background: #fff;
	opacity: 1;
	visibility: visible;
	display: block;
	& .hero{
		opacity: 1;
		visibility: visible;
		background: #fff;
		padding-top: calc(58.4375% + 112px);
	}
	@-webkit-keyframes herologo {
		0%{
			opacity: 0;
			bottom: -300px;
		}
		100% {
			opacity: 1;
			bottom: 0px;
		}
	}
	@-moz-keyframes herologo {
		0%{
			opacity: 0;
			bottom: -300px;
		}
		100% {
			opacity: 1;
			bottom: 0px;
		}
	}
	@keyframes herologo {
		0%{
			opacity: 0;
			bottom: -300px;
		}
		100% {
			opacity: 1;
			bottom: 0px;
		}
	}
	& .hero_logo{
    	-webkit-animation-name: herologo;
    	-webkit-animation-duration: 2.5s;

    	-moz-animation-name: herologo;
    	-moz-animation-duration: 2.5s;

    	animation-name: herologo;
    	animation-duration: 2.5s;
	}
}
