/*
  pc index header
--------------------------------------------- */

.header {
	position: relative;
}


/*
  pc hero
--------------------------------------------- */

.hero {
	background: url(../images/corp/common/hero_bg.png) no-repeat center bottom;
	background-size: cover;
	background-repeat: no-repeat;
	height: 470px;
	position: absolute;
	width: 100%;
	z-index: 100;
	&_logo {
		position: absolute;
		top: 160px;
		left: 50%;
		margin-left: -172.5px;
		text-align: center;
		img {
			text-align: center;
			margin: 0 auto;
		}
	}
}
.hero_loading {
	display: none;
	transition: .6s;
	width: 100%;
	background: #fff;
	height: 100vh;
	position: relative;
	z-index: 10000;
	.hero_logo {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -172.5px;
		margin-top: -172.5px;
		text-align: center;
		img {
			text-align: center;
			margin: 0 auto;
		}
	}
}

#home {
	/* ---------- visual text ---------- */
	.s1 {
		padding-top: 510px;
		h2 {
			padding-top: 65px;
			text-align: center;
			font-size: 30px;
			color: $cl-blue;
			font-family: $font-family-shingo;
			letter-spacing: .12em;
			font-feature-settings: "palt";
		}
		p {
			font-family: $font-family-shingo;
			letter-spacing: .1em;
			line-height: 2em;
			text-align: center;
			font-size: $fs-16;
			margin: 58px auto 84px;
		}
	}
	/* ---------- info ---------- */
	.s2 {
		// background-image: url('../images/corp/common/info-bg.png');
		// background-size: auto;
		// background-repeat: no-repeat;
		// background-position: top;
		// padding: 43px 0;
	    padding: 70px 0 150px;
		min-height: 444px;
		text-align: center;
		// border-top: 1px solid $cl-blue;
		h3 {
			text-align: center;
			font-size: $fs-25;
			position: relative;
			display: inline-block;
			padding-bottom: 10px;
			font-family: $font-family-shingo;
			letter-spacing: .2em;
			&:after {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				// left: 50%;
				// margin-left: -53px;
				// content: url('../images/corp/common/ico-border.png');
				content: "";
				display: block;
				height: 3px;
				width: 50%;
				background: $cl-blue;
			}
		}
		.info_holder {
			margin: 60px 0;
		}
		ul {
			text-align: left;
			li {
				margin-bottom: 20px;
				a {
					text-decoration: none;
					display: block;
					&:hover {
						color: $cl-blue;
						.title {
							text-decoration: underline;
						}
					}
				}
				.date {
					display: inline-block;
					vertical-align: top;
					width: 15%;
				}
				.title {
					display: inline-block;
					vertical-align: top;
					width: 83%;
				}
			}
		}
		.btn-l {
			width: 250px;
		    height: 46px;
		    line-height: 44px;
		    font-size: 14px;
		    margin-right: 0;
		    // padding-right: 10px;
		    position: relative;
			&.rounded {
				border-radius: 30px;
			}

		    // &:after {
	    	//     content: "";
	    	//     background: url(../images/corp/common/ico-arrow-right-btn.png);
			   //  background-position: right center;
	     //        background-repeat: no-repeat;
	     //        background-size: contain;
	     //        padding: 0;
			   //  width: 7px;
			   //  height: 12px;
			   //  position: absolute;
			   //  right: 10px;
			   //  top: 50%;
			   //  -webkit-transform: translateY(-50%);
			   //  -ms-transform: translateY(-50%);
			   //  transform: translateY(-50%);
		    // }
		}
		// .next {
		// 	&:hover {
		// 		color: $cl-purple;
		// 	}
		// }
	}
	/* ---------- miraimo ---------- */
	.s3 {
		background-image: url('../images/corp/common/mirai-bg.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top;
		.inner_sm {
			text-align: center;
			padding: 200px 0 100px;
			.logo {
				// display: inline-block;
				margin: 0 auto 55px;
				width: 300px;

				img {
					width: 100%;
				}
			}
			.text {
				// color: $cl-purple;
				h3 {
					font-size: $fs-20;
					font-weight: bold;
					margin-bottom: 20px;
				}
				p {
					margin-bottom: 25px;
				}
				a {
					color: $cl-orange;
					text-decoration: none;
					width: 250px;
					&:hover {
						color: #fff;
					}
				}
			}
		}
		.inner_mid {
			h3 {
				text-align: center;
				font-size: $fs-25;
				position: relative;
				display: inline-block;
				padding-bottom: 10px;
				margin-bottom: 45px;
				font-family: $font-family-shingo;
				letter-spacing: .2em;
				&:after {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					// left: 50%;
					// margin-left: -111.5px;
					// content: url('../images/corp/common/ico-border-gr.png');
					content: "";
					display: block;
					height: 3px;
					width: 50%;
					background: $cl-lightGreen;
				}
			}
			.next-btn {
				margin-top: 43px;
				.btn {
					color: $cl-lightGreen;
					text-decoration: none;
					&:hover {
						color: #FFF;
					}
				}
			}
			&.area_blog {
				padding-bottom: 200px;
			}
		}
	}

	/* ---------- miraimo-newgrads ---------- */
	.miraimo-newgrads {
		position: relative;

		&-image {

			img {
				width: 100%;
			}
		}

		&-wrap {
			position: absolute;
			top: 50%;
			left: 8.3%;
			transform: translateY(-50%);
			text-align: center;
		}

		&-title {
			margin: 0 auto 20px;
			width: 296px;

			img {
				width: 100%;
			}
		}

		&-desc {
			color: #FFF;
			line-height: 1.857;
			margin-bottom: 30px;
		}

		.newgrads_button {
			font-weight: bold;
			text-align: center;
			display: block;
			background: #fff;
			line-height: 49px;
			width: 244px;
			border-radius: 25px;
			margin: 0 auto;
			background: #58c56e;
			background: -moz-linear-gradient(left, #58c56e 0%, #b4d451 100%);
			background: -webkit-linear-gradient(left, #58c56e 0%, #b4d451 100%);
			background: linear-gradient(to right, #58c56e 0%, #b4d451 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#58c56e', endColorstr='#b4d451', GradientType=1);
			color: #FFF !important;
			text-decoration: none !important;

			&:hover {
			    opacity: 0.75;
			}
		}
	}
}

/* loading cont */

body .wrapper{
	height: auto;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 2s ease-in-out;
	-moz-transition: all 2s ease-in-out;
	transition: all 2s ease-in-out;

}

body .loading_cont{
	height: 0;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	display: none;
	& .hero{
		height: 0;
		opacity: 0;
		visibility: hidden;
	}
}

body.loading{
	overflow: hidden;
}

body.loading .wrapper{
	height: 0;
	opacity: 0;
	visibility: hidden;
}

body.loading .loading_cont{
	overflow: hidden;
	width: 100%;
	position: fixed;
	height: 100%;
	background: #fff;
	opacity: 1;
	visibility: visible;
	display: block;
	& .hero{
		height: auto;
		opacity: 1;
		visibility: visible;
		background: #fff;
	}
	@-webkit-keyframes herologo {
		from{
			top:300px;
			opacity: 0;
		}
		to{
			top: 228px;
			opacity: 1;
		}
	}
	@-moz-keyframes herologo {
		from{
			top:300px;
			opacity: 0;
		}
		to{
			top: 228px;
			opacity: 1;
		}
	}
	@keyframes herologo {
		from{
			top:300px;
			opacity: 0;
		}
		to{
			top: 228px;
			opacity: 1;
		}
	}

	& .hero_logo{
    	-webkit-animation-name: herologo;
    	-webkit-animation-duration: 2.5s;

    	-moz-animation-name: herologo;
    	-moz-animation-duration: 2.5s;

    	animation-name: herologo;
    	animation-duration: 2.5s;

	}
}
