/*
  sp business
--------------------------------------------- */


/* ---------- business index ---------- */

#business {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
		}
	}
	.content_holder {
		margin-bottom: 80px;
	}
	.inner_parts {
		max-width: 100%;
		padding-bottom: 50px;
	}
	.s1 {
		width: 94%;
		margin: 0 auto;
	}
	.big_heading {

		p {
			padding-top: 20px;
			margin-bottom: 50px;

			&.eng {
				padding: 0;
				margin-bottom: 0;
			}
		}
	}
	.ico_circle {
		background: $cl-blue;
		padding: 12px 5px;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
		}
	}
	.box {
		padding: 0;

		.title {
			color: #222;
		}
	}
	.box-2 {
	    border-top: 2px solid $cl-blue;
	    padding-top: 10px;
	    margin-bottom: 40px;
	}
	.box_bottom {
		img {
			width: 100%;
			margin: 10px auto;
		}

		.btn-l {
		    width: 250px;
		    margin: 0 auto;
		    position: relative;

		    // &:after {
	    	//     content: "";
	    	//     background: url(../images/corp/common/ico-arrow-right-btn.png);
			   //  background-position: right center;
	     //        background-repeat: no-repeat;
	     //        background-size: contain;
	     //        padding: 0;
			   //  width: 7px;
			   //  height: 12px;
			   //  position: absolute;
			   //  right: 10px;
			   //  top: 50%;
			   //  -webkit-transform: translateY(-50%);
			   //  -ms-transform: translateY(-50%);
			   //  transform: translateY(-50%);
		    // }
		}

		// .box_btn a {
		// 	width: 100%!important;
		// 	margin: 0 auto!important;
		// }
	}
}


/* ---------- business recruitment ---------- */

#recruitment {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
		}
	}
	.content_holder {
		margin-bottom: 80px;
	}
	.inner_parts {
		max-width: 100%;
	    padding-bottom: 50px;
	}
	.s1 {
		width: 94%;
		margin: 0 auto;
	}
	.big_heading {
		margin-bottom: 50px;

		p {
			&.eng {
				padding: 0;
			}
		}
	}
	.ico_circle {
		background: none;
		padding: 10px 0;
		margin-bottom: 60px;
		width: 20%;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
			width: 100%;
		}
	}
	.step {
		width: 100%;
		margin: 0 auto 20px;
		clear: both;
		.step_L {
			width: 130px;
			margin: 0 auto;
			p.step_L_txt {
				display: inline-block;
				font-size: 13px;
				color: #110051;
				padding-right: 10px;
			}
			.step_L_number {
				display: inline-block;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background: $cl-blue;
				color: #fff;
				padding: 0 5px;
				font-size: $fs-22;
				text-align: center;
				line-height: 60px;
				max-width: 60px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
			    letter-spacing: 3px;
				img {
					margin: 0 auto;
					padding-top: 4px;
				}
			}
		}
		.step_R {
			width: 100%;
			p.txt1 {
				text-align: center;
				font-size: 20px;
				color: #2d2d2d;
				margin-bottom: 10px;
			}
			p.txt2 {
				text-align: center;
				font-size: 13px;
				color: #2d2d2d;
				margin-bottom: 15px;
				span.underline {
					text-decoration: underline;
				}
			}
		}
		.line {
			border-bottom: 1px solid #110051;
		}
	}
	.box {
		padding: 0;

		.title {
			color: #222;
		}
	}
	.box1 {

		.article {
			border-top-color: $cl-blue;
			// padding-top: 30px;
		}
	}
	.box-2 {
	    border-top: 2px solid $cl-blue;
	    padding-top: 10px;
	    margin-bottom: 40px;
	    > .box:first-child {
	    	margin-bottom: 5px;
	    }
	}

	.cont {

		&:last-child {
			margin-top: 50px;
		}
	}
}


/* ---------- business service ---------- */

#service {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
		}
	}
	.content_holder {
		margin-bottom: 80px;
	}
	.inner_parts {
		max-width: 100%;
		padding-bottom: 50px;
	}
	.s1 {
		width: 94%;
		margin: 0 auto;
	}
	.big_heading {
		margin-bottom: 50px;

		p {
			&.eng {
				padding: 0;
			}
		}
	}
	.box {
		padding: 0;

		.title {
			color: #222;
		}
	}
	.box1 {
		margin-top: 50px;

		.box-1 {
			padding: 20px;

			p {
				margin-bottom: 0;
			}
		}
	}
	.box-2 {
		margin-top: 20px;
		margin-bottom: 40px;
	    border-top: 2px solid $cl-blue;
	    padding-top: 10px;
		&:first-child {
			margin-right: 40px;
		}
		&:last-child {
			margin-left: 0;
		}
	}
	.article {
		border-top-color: $cl-blue;
		padding-top: 20px;
	}
	.ico_circle {
		background: none;
		padding: 10px 0;
		margin-bottom: 60px;
		width: 20%;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
			width: 100%;
		}
	}
}


/* ---------- business event ---------- */

#event {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
		}
	}
	.content_holder {
		margin-bottom: 80px;
	}
	.inner_parts {
		max-width: 100%;
		padding-bottom: 50px;
	}
	.s1 {
		width: 94%;
		margin: 0 auto;
	}
	.big_heading {
		margin-bottom: 50px;

		p {
			&.eng {
				padding: 0;
			}
		}
	}
	.ico_circle {
		background: none;
		padding: 10px 0;
		margin-bottom: 60px;
		width: 20%;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
			width: 100%;
		}
	}
	.cont {

		&:last-child {

			.box-2 {
				margin-bottom: 0;
			}

			.article {

				p {
					margin-bottom: 0;
				}
			}
		}
	}
	.box {
		padding: 0;

		.title {
			color: #222;
		}
	}
	.article {
		border-top-color: $cl-blue;
		padding-top: 20px;
	}
}


/* ---------- business training ---------- */

#training {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
		}
	}
	.content_holder {
		margin-bottom: 80px;
	}
	.inner_parts {
		max-width: 100%;
		padding-bottom: 50px;
	}
	.s1 {
		width: 94%;
		margin: 0 auto;
	}
	.big_heading {
		margin-bottom: 50px;

		p {
			&.eng {
				padding: 0;
			}
		}
	}
	.box {
		padding: 0;

		.title {
			color: #222;
		}
	}
	.article {
		border-top-color: $cl-blue;
		padding-top: 20px;
	}
	.ico_circle {
		background: none;
		padding: 10px 0;
		margin-bottom: 60px;
		width: 20%;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
			width: 100%;
		}
	}
	ol.steps {
		margin-left: 0;
		margin-bottom: 20px;
		li {
			padding: 25px 0;
			list-style: none;
			vertical-align: middle;
			font-size: 14px;
			span {
				display: inline-block;
				margin-right: 10px;
				margin-bottom: -10px;
				width: 24px;
			    vertical-align: top;
			}
		}
	}
	.step {
		width: 100%;
		margin: 0 auto 30px;
		clear: both;
		.step_L {
			width: 130px;
			margin: 0 auto;
			p.step_L_txt {
				display: inline-block;
				font-size: 13px;
				color: #110051;
				padding-right: 10px;
			}
			.step_L_number {
				display: inline-block;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background: $cl-blue;
				color: #fff;
				padding: 0 5px;
			    font-size: $fs-22;
				text-align: center;
			    line-height: 60px;
				max-width: 60px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
			    letter-spacing: 3px;
				img {
					margin: 0 auto;
					padding-top: 4px;
				}
			}
		}
		.step_R {
			width: 100%;
			.txt1 {
				text-align: center;
				font-size: 20px;
				color: #2d2d2d;
				margin-bottom: 10px;
			}
			.txt2 {
				text-align: center;
				font-size: 13px;
				color: #2d2d2d;
				margin-bottom: 15px;
				span.underline {
					text-decoration: underline;
				}
			}
		}
		.line {
			border-bottom: 1px solid #110051;
		}
	}
	.program_steps {
		margin-top: 50px;
	}
}


/* ---------- business consulting ---------- */

#consulting {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
		}
	}
	.content_holder {
		margin-bottom: 80px;
	}
	.inner_parts {
		max-width: 100%;

		&:nth-child(4) {

			.box1 {
				margin-top: 25px;
			}
		}

		&:last-child {
		    padding-bottom: 50px;

			.box1 {
				margin-top: 25px;
			}

			.imgbox {
				margin: 0 auto 15px;
				width: 300px;
			}
		}
	}

	h4.mintit {
		position: relative;

		&:after {
			position: absolute;
		    bottom: -20px;
		    left: 0;
		    right: 0;
		    margin: 0 auto;
		    content: url(../images/corp/business/business-title_border.png);
		    display: block;
		}
	}
	.s1 {
		width: 94%;
		margin: 0 auto;
	}
	.big_heading {
		margin-bottom: 50px;

		&:nth-child(3) {
			margin-bottom: 25px;
		}

		&:nth-child(6) {
			margin-bottom: 25px;
		}

		p {
			&.eng {
				padding: 0;
			}
		}
	}
	.box1 {
		margin-top: 50px;
	}
	.box-2 {
		margin-top: 20px;
		margin-bottom: 40px;
	    border-top: 2px solid $cl-blue;
	    padding-top: 10px;
	    > .box:first-child {
	    	margin-bottom: 5px;
	    }
	}
	.box {
		padding: 0;

		.title {
			color: #222;
		}
	}
	.ico_circle {
		background: none;
		padding: 10px 0;
		margin-bottom: 60px;
		width: 20%;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
			width: 100%;
		}
	}
	ol.steps {
		margin-left: 0;
		margin-bottom: 10px;
		li {
			padding: 12px 0;
			list-style: none;
			vertical-align: middle;
			font-size: 14px;
			padding-left: 1.5em;
			display: block;
			span {
		    	display: list-item;
		    	list-style-type: disc;
			}
		}
	}
	.step {
		width: 100%;
		margin: 0 auto 30px;
		clear: both;
		.step_L {
			width: 130px;
			margin: 0 auto;
			p.step_L_txt {
				display: inline-block;
				font-size: 13px;
				color: #110051;
				padding-right: 10px;
			}
			.step_L_number {
				display: inline-block;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background: $cl-blue;
				color: #fff;
				padding: 0 5px;
			    font-size: $fs-22;
				text-align: center;
			    line-height: 60px;
				max-width: 60px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
			    letter-spacing: 3px;
				img {
					margin: 0 auto;
					padding-top: 4px;
				}
			}
		}
		.step_R {
			width: 100%;
			p.txt1 {
				text-align: center;
				font-size: 20px;
				color: #2d2d2d;
				margin-bottom: 10px;
			}
			p.txt2 {
				text-align: center;
				font-size: 13px;
				color: #2d2d2d;
				margin-bottom: 15px;
				span.underline {
					text-decoration: underline;
				}
			}
		}
		.line {
			border-bottom: 1px solid #110051;
		}
	}
	.backgray {
		background: #f4f3f7;
		padding: 20px 20px 20px 10px;
		margin-top: 30px;
		ul {
			li {
			    padding-left: 1em;
			    text-indent: -1em;
			}
		}
	}
	.consulting_hr {

		.box1 {

			.box {
				margin-bottom: 10px;
			}

			.article {
				border-top-color: $cl-blue;
				padding-top: 20px;
			}
		}
	}
}


/* ---------- business fbg ---------- */

#fbg {
	.main_heading {
		.overlay {
			background-image: url('../images/corp/business/business-bg.png');
		}
	}
	.content_holder {
		margin-bottom: 80px;
	}

	.inner_parts {
		max-width: 100%;
		padding-bottom: 50px;
	}

	.s1 {
		width: 94%;
		margin: 0 auto;
	}
	.big_heading {
		margin-bottom: 50px;

		p {
			&.eng {
				padding: 0;
			}
		}
	}
	.ico_circle {
		background: none;
		padding: 10px 0;
		margin-bottom: 60px;
		width: 50%;
		img {
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			margin: 0 auto;
			width: 100%;
		}
	}
	ol.steps {
		li {
		    padding: 10px 0 10px 2em;
		    list-style: none;
		    display: block;
		    // text-indent: -1.2em;
		    span {
		    	display: list-item;
		    	list-style-type: decimal;
		    }
		}
		li.border-t-none {
			border-top: none;
		}
		li.border-b-none {
			border-bottom: none;
		}
	}
	ol.steps1 {
		margin-left: 0;
		li {
		    padding: 15px 0 15px 2em;
		    list-style: none;
		    // text-indent: -1.3em;
		    span {
		    	display: list-item;
		    	list-style-type: disc;
		    }
		}
		li.border-t-none {
			border-top: none;
		}
		li.border-b-none {
			border-bottom: none;
		}
	}
	.box {
		padding: 0;

		.title {
			color: #222;
		}
	}
	.date {
		margin: 10px 0 0;
	}
	.article {
		border-top-color: $cl-blue;
		padding-top: 20px;
	}
	.cont {

		&:first-child {

			.article {
				padding-top: 0;
			}
		}

		&:last-child {

			.box-2 {
				margin-bottom: 0;
			}

			.article {

				p {
					margin-bottom: 0;
				}
			}
		}
	}
}