/*
  pc layout
--------------------------------------------- */

body {
	background: #ddd;
	line-height: $line-height;
	font-size: $font-size;
	height: 100%;
	font-family: $font-family;
	position: relative;
	font-kerning: normal;
	box-sizing: border-box;
	min-width: 1024px;
}

.wrapper {
	// max-width: 1644px;
	margin: 0 auto;
	// padding: 0 20px;
	background: #fff;
	transition: .6s;
}

html {
	height: 100%;
}

.sp_only {
	display: none;
}

.inner {
	box-sizing: border-box;
	max-width: $max-width;
	width: 100%;
	margin: 0 auto;
	position: relative;
}

.inner_mid {
	box-sizing: border-box;
	max-width: $mid-width;
	width: 100%;
	margin: 0 auto;
	position: relative;
}

.inner_min {
	padding: 0 10px;
	box-sizing: content-box;
	max-width: $min-width;
	margin: 0 auto;
	position: relative;
}

.inner_sm {
	box-sizing: border-box;
	max-width: $sm-width;
	width: 100%;
	margin: 0 auto;
	position: relative;
}

.inner_small {
	box-sizing: border-box;
	max-width: 700px;
	// width: 100%;
	margin: 0 auto;
	position: relative;
}

.container {
	@include clearfix;
	padding: 0 10px;
	box-sizing: content-box;
	max-width: $max-width;
	margin: 0 auto;
	position: relative;
}


/*
  pc heading
--------------------------------------------- */

.heading {
	text-align: center;
}

a {
	text-decoration: none;
}

div.next-btn {
	text-align: center;
}

a.next {
	text-decoration: none;
	&:after {
		content: url('../images/corp/common/ico-arrow-right.png');
		display: inline-block;
		padding-left: 10px;
	}
}


/*
  pc header
--------------------------------------------- */

.header {
	padding: 0 30px;
    // overflow: hidden;
	.holder {
		@include clearfix;
		min-height: 78px;
		line-height: 78px;
		.logo_holder {
			float: left;
			vertical-align: middle;
			width: 35%;
			img {
				display: inline-block;
				vertical-align: middle;
			}
			.logo_txt {
				color: $cl-blue;
				display: inline-block;
				font-size: $fs-13;
			}
		}
		.g_nav {
			float: right;
			text-align: right;
			vertical-align: middle;
			width: 65%;
			.nav {
				letter-spacing: -1em;
				line-height: 78px;
			}
			li {
				letter-spacing: normal;
				position: relative;
				display: inline-block;
				a {
					color: $cl-blue;
					letter-spacing: .05em;
					font-size: $font-size;
					padding: 0 15px;
					display: block;
					text-decoration: none;
				}
				&:first-child {
					&:before {
						content: " ";
						top: 0;
						left: 0;
						position: absolute;
						width: 100%;
						height: 10px;
						background: $cl-purple;
					}
					&.active {
						background: $cl-purple;
						a {
							color: #fff;
						}
					}
				}
				&:nth-child(2) {
					&:before {
						content: " ";
						top: 0;
						left: 0;
						position: absolute;
						width: 100%;
						height: 10px;
						background: $cl-blue;
					}
					&.active {
						background: $cl-blue;
						a {
							color: #fff;
						}
					}
				}
			}
		}
	}
	.sub_box {
		opacity: 0;
		visibility: hidden;
		transition: .5s;
		z-index: 999;
		position: absolute;
		width: 100%;
		bottom: -279px;
		height: 280px;
		color: #000;
		background: #fff;
		left: 0;
		&.in {
			transition: .5s;
			opacity: 1;
			visibility: visible;
		}
		.wrap {
			@include clearfix;
		}
		.left {
			float: left;
			// height: 100%;
			width: 35%;
			vertical-align: top;
			text-align: center;
			font-size: $font-size;
			position: absolute;
		    top: 50%;
		    -ms-transform: translateY(-50%);
		    -webkit-transform: translateY(-50%);
		    transform: translateY(-50%);
			img {
				margin: 0 auto;
				display: block;
			}
			a {
				color: $cl-purple;
				text-decoration: none;
				&:hover {
					color: #fff;
				}
			}
		}
		.right {
			float: right;
			vertical-align: top;
			height: 100%;
			width: 65%;
			ul {
				width: 100%;
				margin: 0 auto;
				letter-spacing: -1em;
				li {
					letter-spacing: normal;
					width: calc(100%/3);
					display: inline-block;
					padding: 3.5px;
					text-align: center;
					a {
						background: #FFF;
						text-decoration: none;
						// border: 1px solid $cl-purple;
						border-top: 3px solid;
						display: block;
						font-size: $font-size;
						// color: $cl-purple;
						color: $color;
						&:hover {
							color: #fff;
							// background: $cl-purple;
						}
					}

					&:first-child {

						a {
							border-top-color: $cl-purple;

							&:hover {
								background: $cl-purple;
							}
						}
					}

					&:nth-child(2) {

						a {
							border-top-color: #00b9d8;

							&:hover {
								background: #00b9d8;
							}
						}
					}

					&:nth-child(3) {

						a {
							border-top-color: #32cca6;

							&:hover {
								background: #32cca6;
							}
						}
					}

					&:nth-child(4) {

						a {
							border-top-color: $cl-green;

							&:hover {
								background: $cl-green;
							}
						}
					}

					&:nth-child(5) {

						a {
							border-top-color: $cl-orange;

							&:hover {
								background: $cl-orange;
							}
						}
					}

					&:last-child {

						a {
							border-top-color: #f85550;

							&:hover {
								background: #f85550;
							}
						}
					}
				}
			}
		}
	}
	#li_1 {
		&:before {
			top: 0;
			left: 0;
			position: absolute;
			height: 1px;
			background: $cl-purple;
			width: 100%;
			content: "";
			z-index: 99999;
		}
		.wrap {
			display: block;
			position: relative;
			height: 100%;
			background-image: url('../images/corp/common/sub_box1-bg.png');
			background-size: cover;
		}
		.left {
			// padding: 55px 0 55px 10%;
			padding: 0 0 0 10%;
		}
		.right {
			padding: 55px 5%;
		}
	}
	#li_2 {
		.left {
			background: $cl-blue;
			// background: #312562;
			p {
				line-height: 280px;
				color: #fff;
				text-align: right;
				font-size: $fs-24;
				margin-right: 62px;
			}
		}
		.right {
			background: $cl-blue;
			padding: 55px 5%;
			a {
				background: $cl-blue;
				border-top: 3px solid #fff;
				display: block;
				font-size: $font-size;
				color: #fff;
				&:hover {
					color: $cl-blue;
					background: #fff;
				}
			}
		}
	}
}


/*
  pc footer
--------------------------------------------- */

.footer {
	border-top: 3px solid $cl-blue;
	margin: 0 auto;
	padding: 0 30px;
	.holder {
		@include clearfix;
		min-height: 78px;
		.logo_holder {
			padding: 24px 0;
			float: left;
			vertical-align: middle;
			img {
				vertical-align: middle;
			}
		}
		.footer_nav {
			float: right;
			width: calc(100% - 100px);
			vertical-align: middle;
			text-align: right;
			li {
				display: inline-block;
				position: relative;
				vertical-align: top;
				line-height: 13px;
			    padding: 43px 0 0;
				&:last-child {
				    line-height: 19px;
				    padding-top: 37px;
				}
				a {
					text-decoration: none;
					color: $cl-blue;
					font-size: $fs-13;
					padding: 0 15px;
					display: block;
					color: $cl-gray;
					&:hover {
						color: $cl-blue;
					}
				}
			}
		}
	}
	.copyright {
		font-family: $font-family-pt-sans;
		font-size: $fs-13;
		padding-bottom: 20px;
	}
	&_sns {
		ul {
			text-align: center;
			li {
				display: inline-block;
				padding: 60px 15px;
				width: 59px;
			    text-align: center;
			    img {
			    	margin: 0 auto;
			    }
			}
		}
	}
}


/*
  pc common area
--------------------------------------------- */

main {
	position: relative;
}

.blur.active {
	position: absolute;
	width: 100%;
	// top: 279px;
	top: 0;
	height: 100%;
	z-index: 111;
	background: rgba(0, 0, 0, 0.7);
}

.area_tit {
	text-align: center;
	font-size: $fs-32;
	font-family: $font-family-sans;
	letter-spacing: .075em;
	line-height: 1.6;
	padding: 70px 0 60px;
	span {
		display: block;
		font-size: 16px;
		font-family: $font-family-pt-sans;
		letter-spacing: .1em;
	}
}


/* ---------- area blog ---------- */

.area_blog {
	padding-bottom: 70px;
	.area_tit {
		span {
			color: $cl-lightGreen;
		}
	}
	&_cont {
		letter-spacing: -1em;
		margin: 0 -10px;
		&_box {
			letter-spacing: normal;
			text-align: left;
			display: inline-block;
			margin: 0 5px;
			width: calc(25% - 10px);
			max-width: 292px;
			vertical-align: top;
			.img {
				padding-bottom: 20px;
				img {
					width: 100%;
				}
			}
			.date {
				font-size: $fs-12;
				font-weight: bold;
				line-height: 28px;
				display: inline-block;
				padding: 0 10px;
			}
			.tag {
				font-size: $fs-11;
				text-align: center;
				padding: 0 12px;
				color: $cl-lightGreen;
				display: inline-block;
				line-height: 26px;
				border: 1px $cl-lightGreen solid;
				border-radius: 14px;
			}
			.tit {
				padding: 16px 10px;
				line-height: 1.5;
			}
			a {
				text-decoration: none;
				&:hover {
					color: $cl-lightGreen;
				}
			}
		}
	}
}


/*
  pc page
--------------------------------------------- */

.pages {
	background: url(../images/corp/common/head_bg.png) no-repeat center top;
	background-size: contain;
	.area_key {
		padding: 20px 0 100px;
		.area_tit {
			padding: 70px 0 40px;
		}
	}
}


/* ---------- buttons ---------- */

.btn {
	background: #fff;
	display: inline-block;
	padding: 0 30px;
	font-size: $font-size;
	text-decoration: none;
	text-align: center;
	border-radius: 25px;
	line-height: 44px;
}

.btn-purple {
	border: 1px solid $cl-purple;
	color: $cl-purple;
	&:hover {
		background: $cl-purple;
		color: #fff;
	}
}

.btn-orange {
	border: 1px solid $cl-orange;
	color: $cl-orange;
	&:hover {
		background: $cl-orange;
		color: #fff;
	}
}

.btn-lightGreen {
	border: 1px solid $cl-lightGreen;
	color: $cl-lightGreen;
	&:hover {
		background: $cl-lightGreen;
		color: #fff;
	}
}
