  /*
  pc parts
--------------------------------------------- */

.main_heading {
	position: relative;
	.overlay {
		background: url('../images/corp/common/main-heading-bg.png') no-repeat center bottom;
		background-size: cover;
		min-height: 240px;
		position: relative;
		.inner_mid {
			position: static;
		}
		h1 {
			text-align: center;
			color: #fff;
			font-size: $fs-25;
			font-family: $font-family-shingo;
			letter-spacing: .2em;
			// padding-top: 25px;
			position: absolute;
		    top: 50%;
		    left: 50%;
		    transform: translateY(-50%) translateX(-50%);
		    -webkit-transform: translateY(-50%) translateX(-50%);
		    -ms-transform: translateY(-50%) translateX(-50%);
		}
		.breadcrumbs {
			ul {
				padding: 25px 10px 0;
				li {
					color: #fff;
					display: inline-block;
					font-size: $fs-13;
					a {
						color: #fff;
						display: inline-block;
						vertical-align: top;
						text-decoration: underline;
						&:hover {
							text-decoration: none;
						}
					}
					&:after {
						content: ">";
						padding: 0 15px;
						display: inline-block;
						vertical-align: top;
					}
					&:last-child {
						&:after {
							content: '';
						}
					}
				}
			}
		}
	}
}

.big_heading {
	text-align: center;
	p.eng {
		font-size: $fs-11;
		letter-spacing: 0.2em;
		color: $cl-blue;
		margin-bottom: 0;
	}
	h3 {
		text-align: center;
		font-size: 30px;
		letter-spacing: .1em;
		position: relative;
		display: inline-block;
		// padding-bottom: 10px;
		font-family: $font-family-shingo;
		// &:after {
		// 	position: absolute;
		// 	bottom: 0;
		// 	left: 0;
		// 	right: 0;
		// 	margin: 0 auto;
		// 	// content: url('../images/corp/common/ico-border-sm.png');
		// 	content: "";
		// 	display: block;
		// 	height: 3px;
		// 	width: 70px;
		// 	background: $cl-blue;
		// }
	}
	h4 {
		font-size: $fs-20;
		margin: 23px 0;
	}
	p {
		margin-bottom: 23px;
	}
}


/* icon circle */

.ico_circle {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: $cl-grey;
	color: #fff;
	padding: 15px;
	font-size: $fs-25;
	text-align: center;
	line-height: 1.5;
	margin: 37px auto;
  .fa {
    font-size: $fs-23;
  }
}

.ico_circle_sm {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	// background: #ddd;
	// color: #fff;
	padding: 15px 5px;
	font-size: $fs-19;
	text-align: center;
	line-height: 1.5;
	max-width: 60px;
	display: inline-block;
	vertical-align: middle;
	// margin-right: 15px;
	color: #110051;
  .fa {
    font-size: $fs-23;
  }
}

.inner_parts {
	max-width: $parts-width;
	margin: 0 auto;
}


/* margin and padding 0 */

.pbtm-0 {
	padding-bottom: 0 !important;
}

.mbtm-0 {
	margin-bottom: 0 !important;
}


/* flex */

.inline {
	display: flex;
	flex-wrap: wrap;
}


/* typhography */

.article {
	p {
		margin-bottom: 25px;
	}
  p.p_right{
    width: 643px;
    float: right;
  }
}


/* lists */

ol.steps {
	margin-left: 20px;
	li {
		border-top: 1px dashed $cl-grey;
		padding: 26px 0 26px 76px;
		list-style-type: decimal;
		&:last-child {
			border-bottom: 1px dashed $cl-grey;
		}
	}
}

ol.steps-simple {
	margin-left: 20px;
	li {
		counter-increment: customlistcounter;
		&:before {
			content: counter(customlistcounter) ")";
			float: left;
			width: 1.2em;
		}
		&:first-child {
			counter-reset: customlistcounter;
		}
	}
}

ul.disc {
	margin-left: 20px;
	list-style: disc;
}

ol.num {
	li {
		counter-increment: customlistcounter;
		&:before {
			padding-right: 10px;
			content: counter(customlistcounter) "";
			float: left;
			width: 1.2em;
			color: $cl-lightBlue;
		}
		&:first-child {
			counter-reset: customlistcounter;
		}
	}
}

ul.square {
	li {
		&:before {
			padding-right: 10px;
			float: left;
			width: 1.2em;
			content: url('../images/corp/common/ico-square.png');
			display: inline-block;
			vertical-align: middle;
		}
	}
}


/* table */

.table {
	width: 100%;
	border-top: 1px solid #dddddd;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
	tr {
		border-bottom: 1px solid #dddddd;
	}
	td {
		padding: 25px;
		&:first-child {
			width: 20%;
			background: #f6f6f6;
			text-align: center;
			border-right: 1px solid #dddddd;
		}
	}
	&.table-with-heading {
		tr:first-child {
			background: #e1eef6;
			td {
				padding: 17px;
				&:first-child {
					background: #e1eef6;
				}
			}
		}
	}
}


/* anchor links */

a.ac {
	text-decoration: underline;
	display: inline-block;
	margin-right: 38px;
}

a.blnk {
	&:after {
		padding-left: 5px;
		content: url('../images/corp/common/ico-blank.png');
		display: inline-block;
		vertical-align: middle;
	}
}

a.pdf {
	&:after {
		padding-left: 5px;
		content: url('../images/corp/common/ico-pdf.png');
		display: inline-block;
		vertical-align: middle;
	}
}


/* btns */

a.btn-l {
	font-size: $fs-18;
	text-align: center;
	text-decoration: none !important;
	display: inline-block;
	width: 386px;
	height: 60px;
	line-height: 60px;
	background: #fff;
	border: 1px solid #110051;
	color: #110051;
    padding: 0 30px;
    border-radius: 30px;
	// -webkit-box-shadow: 2px 2px 2px 0 rgba(121, 131, 160, 1);
	// -moz-box-shadow: 2px 2px 2px 0 rgba(121, 131, 160, 1);
	// box-shadow: 2px 2px 2px 0 rgba(121, 131, 160, 1);
	// &:after {
	// 	padding-top: 5px;
	// 	padding-right: 20px;
	// 	content: url('../images/corp/common/ico-arrow-right-btn.png');
	// 	display: inline-block;
	// 	vertical-align: middle;
	// 	float: right;
	// 	clear: both;
	// }
	&:hover {
		// background: $cl-blue;
		background: #110051;
		color: #fff;
	}
	&.rounded {
		border-radius: 30px;
	}
}

a.btn-s {
	text-align: center;
	text-decoration: none !important;
	color: #fff;
	display: inline-block;
	width: 132px;
	height: 32px;
	line-height: 32px;
	background: #778fce;
	-webkit-box-shadow: 2px 2px 2px 0 rgba(121, 131, 160, 1);
	-moz-box-shadow: 2px 2px 2px 0 rgba(121, 131, 160, 1);
	box-shadow: 2px 2px 2px 0 rgba(121, 131, 160, 1);
	&:hover {
		background: $cl-blue;
	}
}

a.btn-navi {
	font-size: $fs-13;
	text-align: center;
	text-decoration: none;
	color: #FFF;
	display: inline-block;
	width: 132px;
	height: 32px;
	line-height: 32px;
	border: 1px solid $cl-blue;
	background: $cl-blue;
	vertical-align: middle;
	position: relative;
	&:before,
	&:after {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		content: "";
		vertical-align: middle;
	}
	// &:after {
	// 	padding-right: 5px;
	// 	float: right;
	// 	content: url('../images/corp/common/ico-arrow-right-blue.png');
	// 	display: inline-block;
	// 	vertical-align: middle;
	// 	clear: both;
	// }
	&:before {
		right: 8px;
		width: 10px;
		height: 1px;
		background: #fff;
	}
	&:after {
		right: 8px;
		width: 6px;
		height: 6px;
		border-top: 1px solid #fff;
		border-right: 1px solid #fff;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	&:hover {
		background: #FFF;
		color: $cl-blue;
		&:before {
			background: $cl-blue;
		}
		&:after {
			border-top-color: $cl-blue;
			border-right-color: $cl-blue;
		}
	}
}

.cont {
	margin: 25px auto;
	.border-drk {
		border: 2px solid $cl-lightPurple;
	}
	.border-light {
		border: 2px solid $cl-lightGrey;
	}
	.bg-light {
		background: $cl-lightGrey;
	}
	.rounded {
		border-radius: 5px;
	}
	.txt {
		border-top: 2px solid $cl-lightPurple;
		padding-top: 25px;
	}
	.title {
		font-size: $fs-19;
		color: $cl-blue;
		// font-weight: bold;
		display: inline-block;
		vertical-align: middle;
		line-height: 1.5;
	}
	/* typhography */
	p {
		font-size: $fs-13;
	}
	p.center {
		text-align: center;
	}
	/* box size */
	.box {
		padding: 20px;
	}
	.box-1 {
		width: 100%;
	}
	.box-2 {
		width: calc(100%/2 - 25px);
		&:first-child {
			margin-right: 25px;
		}
		&:last-child {
			margin-left: 25px;
		}
	}
	.box-3 {
		width: calc(100%/3);
		clear: both;
		&:first-child {
			img {
				float: left;
			}
			p {
				width: 90%;
				float: left;
			}
		}
		&:nth-child(2) {
			img {
				margin: 0 auto;
			}
			p {
				width: 90%;
				margin: 0 auto;
			}
		}
		&:last-child {
			img {
				float: right;
			}
			p {
				width: 90%;
				float: right;
			}
		}
	}
	/* title */
	h4 {
		font-size: $fs-13;
		color: $cl-blue;
		border-bottom: 1px solid $cl-grey;
		font-weight: bold;
		padding-bottom: 10px;
		margin-bottom: 20px;
	}
	/* box with images */
	.box-with-img-right {
		overflow: auto;
		img {
			float: right;
			margin: 0 0 43px 43px;
		}
	}
	.box-with-img-left {
		overflow: auto;
		img {
			float: left;
			margin: 0 43px 43px 0;
		}
	}
}

.content_holder {
	p {
		text-align: left;
	}
}

.clearfix {
	clear: both;
}
