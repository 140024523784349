@charset "UTF-8";
@import url("//fonts.googleapis.com/css?family=PT+Sans");
/*
  reset
--------------------------------------------- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

input[type="button"], input[type="submit"] {
  -webkit-appearance: none; }

/*
  icon
--------------------------------------------- */
@font-face {
  font-family: 'mori-font';
  src: url("../fonts/mori-font.eot?3ttmy6");
  src: url("../fonts/mori-font.eot?3ttmy6#iefix") format("embedded-opentype"), url("../fonts/mori-font.ttf?3ttmy6") format("truetype"), url("../fonts/mori-font.woff?3ttmy6") format("woff"), url("../fonts/mori-font.svg?3ttmy6#mori-font") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="i-"], [class*=" i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'mori-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.i-corp-01:before {
  content: "\e900"; }

.i-corp-02:before {
  content: "\e901"; }

.i-corp-03:before {
  content: "\e902"; }

.i-corp-04:before {
  content: "\e903"; }

.i-corp-05:before {
  content: "\e904"; }

.i-corp-06:before {
  content: "\e905"; }

.i-corp-07:before {
  content: "\e906"; }

.i-corp-08:before {
  content: "\e907"; }

.i-corp-09:before {
  content: "\e908"; }

.i-corp-10:before {
  content: "\e909"; }

.i-corp-11:before {
  content: "\e90a"; }

.i-corp-12:before {
  content: "\e90b"; }

.i-corp-13:before {
  content: "\e90c"; }

.i-corp-14:before {
  content: "\e90d"; }

.i-corp-15:before {
  content: "\e90e"; }

.i-corp-16:before {
  content: "\e90f"; }

.i-corp-17:before {
  content: "\e910"; }

.i-corp-18:before {
  content: "\e911"; }

.i-corp-19:before {
  content: "\e912"; }

.i-corp-20:before {
  content: "\e913"; }

.i-corp-21:before {
  content: "\e914"; }

.i-corp-22:before {
  content: "\e915"; }

.i-corp-23:before {
  content: "\e916"; }

.i-corp-24:before {
  content: "\e917"; }

.i-corp-25:before {
  content: "\e918"; }

.i-corp-26:before {
  content: "\e919"; }

.i-corp-27:before {
  content: "\e91a"; }

.i-corp-28:before {
  content: "\e91b"; }

.i-corp-29:before {
  content: "\e91c"; }

.i-corp-30:before {
  content: "\e91d"; }

.i-corp-31:before {
  content: "\e91e"; }

.i-corp-32:before {
  content: "\e91f"; }

.i-corp-33:before {
  content: "\e920"; }

.i-corp-34:before {
  content: "\e921"; }

.i-corp-35:before {
  content: "\e922"; }

.i-corp-36:before {
  content: "\e923"; }

.i-corp-37:before {
  content: "\e924"; }

.i-corp-38:before {
  content: "\e925"; }

.i-corp-39:before {
  content: "\e926"; }

.i-corp-40:before {
  content: "\e927"; }

.i-corp-41:before {
  content: "\e928"; }

.i-corp-42:before {
  content: "\e929"; }

.mgb0 {
  margin-bottom: 0px; }

.mgb5 {
  margin-bottom: 5px; }

.mgb10 {
  margin-bottom: 10px; }

.mgb15 {
  margin-bottom: 15px; }

.mgb20 {
  margin-bottom: 20px; }

.mgb25 {
  margin-bottom: 25px; }

.mgb30 {
  margin-bottom: 30px; }

.mgb35 {
  margin-bottom: 35px; }

.mgb40 {
  margin-bottom: 40px; }

.mgb45 {
  margin-bottom: 45px; }

.mgb50 {
  margin-bottom: 50px; }

.mgb55 {
  margin-bottom: 55px; }

.mgb60 {
  margin-bottom: 60px; }

.mgb65 {
  margin-bottom: 65px; }

.mgb70 {
  margin-bottom: 70px; }

.mgb75 {
  margin-bottom: 75px; }

.mgb80 {
  margin-bottom: 80px; }

.mgb85 {
  margin-bottom: 85px; }

.mgb90 {
  margin-bottom: 90px; }

.mgb95 {
  margin-bottom: 95px; }

.mgb100 {
  margin-bottom: 100px; }

/*
  base
--------------------------------------------- */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

img {
  border-style: none;
  display: block;
  vertical-align: bottom; }

a,
a img,
button {
  transition: .4s; }

a img:hover {
  opacity: .7; }

a:link {
  color: #2d2d2d;
  text-decoration: none; }

a:visited {
  color: #2d2d2d; }

a:hover {
  color: #2d2d2d;
  text-decoration: none; }

.center {
  text-align: center; }

@media (min-width: 768px) {
  /*
  pc layout
--------------------------------------------- */
  body {
    background: #ddd;
    line-height: 1.8;
    font-size: 14px;
    height: 100%;
    font-family: Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    position: relative;
    font-kerning: normal;
    box-sizing: border-box;
    min-width: 1024px; }
  .wrapper {
    margin: 0 auto;
    background: #fff;
    transition: .6s; }
  html {
    height: 100%; }
  .sp_only {
    display: none; }
  .inner {
    box-sizing: border-box;
    max-width: 1340px;
    width: 100%;
    margin: 0 auto;
    position: relative; }
  .inner_mid {
    box-sizing: border-box;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    position: relative; }
  .inner_min {
    padding: 0 10px;
    box-sizing: content-box;
    max-width: 1036px;
    margin: 0 auto;
    position: relative; }
  .inner_sm {
    box-sizing: border-box;
    max-width: 969px;
    width: 100%;
    margin: 0 auto;
    position: relative; }
  .inner_small {
    box-sizing: border-box;
    max-width: 700px;
    margin: 0 auto;
    position: relative; }
  .container {
    *zoom: 1;
    padding: 0 10px;
    box-sizing: content-box;
    max-width: 1340px;
    margin: 0 auto;
    position: relative; }
    .container:after {
      content: ".";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
  /*
  pc heading
--------------------------------------------- */
  .heading {
    text-align: center; }
  a {
    text-decoration: none; }
  div.next-btn {
    text-align: center; }
  a.next {
    text-decoration: none; }
    a.next:after {
      content: url("../images/corp/common/ico-arrow-right.png");
      display: inline-block;
      padding-left: 10px; }
  /*
  pc header
--------------------------------------------- */
  .header {
    padding: 0 30px; }
    .header .holder {
      *zoom: 1;
      min-height: 78px;
      line-height: 78px; }
      .header .holder:after {
        content: ".";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
      .header .holder .logo_holder {
        float: left;
        vertical-align: middle;
        width: 35%; }
        .header .holder .logo_holder img {
          display: inline-block;
          vertical-align: middle; }
        .header .holder .logo_holder .logo_txt {
          color: #110051;
          display: inline-block;
          font-size: 0.92em; }
      .header .holder .g_nav {
        float: right;
        text-align: right;
        vertical-align: middle;
        width: 65%; }
        .header .holder .g_nav .nav {
          letter-spacing: -1em;
          line-height: 78px; }
        .header .holder .g_nav li {
          letter-spacing: normal;
          position: relative;
          display: inline-block; }
          .header .holder .g_nav li a {
            color: #110051;
            letter-spacing: .05em;
            font-size: 14px;
            padding: 0 15px;
            display: block;
            text-decoration: none; }
          .header .holder .g_nav li:first-child:before {
            content: " ";
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 10px;
            background: #9d56e4; }
          .header .holder .g_nav li:first-child.active {
            background: #9d56e4; }
            .header .holder .g_nav li:first-child.active a {
              color: #fff; }
          .header .holder .g_nav li:nth-child(2):before {
            content: " ";
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 10px;
            background: #110051; }
          .header .holder .g_nav li:nth-child(2).active {
            background: #110051; }
            .header .holder .g_nav li:nth-child(2).active a {
              color: #fff; }
    .header .sub_box {
      opacity: 0;
      visibility: hidden;
      transition: .5s;
      z-index: 999;
      position: absolute;
      width: 100%;
      bottom: -279px;
      height: 280px;
      color: #000;
      background: #fff;
      left: 0; }
      .header .sub_box.in {
        transition: .5s;
        opacity: 1;
        visibility: visible; }
      .header .sub_box .wrap {
        *zoom: 1; }
        .header .sub_box .wrap:after {
          content: ".";
          display: block;
          clear: both;
          height: 0;
          visibility: hidden; }
      .header .sub_box .left {
        float: left;
        width: 35%;
        vertical-align: top;
        text-align: center;
        font-size: 14px;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); }
        .header .sub_box .left img {
          margin: 0 auto;
          display: block; }
        .header .sub_box .left a {
          color: #9d56e4;
          text-decoration: none; }
          .header .sub_box .left a:hover {
            color: #fff; }
      .header .sub_box .right {
        float: right;
        vertical-align: top;
        height: 100%;
        width: 65%; }
        .header .sub_box .right ul {
          width: 100%;
          margin: 0 auto;
          letter-spacing: -1em; }
          .header .sub_box .right ul li {
            letter-spacing: normal;
            width: calc(100%/3);
            display: inline-block;
            padding: 3.5px;
            text-align: center; }
            .header .sub_box .right ul li a {
              background: #FFF;
              text-decoration: none;
              border-top: 3px solid;
              display: block;
              font-size: 14px;
              color: #2d2d2d; }
              .header .sub_box .right ul li a:hover {
                color: #fff; }
            .header .sub_box .right ul li:first-child a {
              border-top-color: #9d56e4; }
              .header .sub_box .right ul li:first-child a:hover {
                background: #9d56e4; }
            .header .sub_box .right ul li:nth-child(2) a {
              border-top-color: #00b9d8; }
              .header .sub_box .right ul li:nth-child(2) a:hover {
                background: #00b9d8; }
            .header .sub_box .right ul li:nth-child(3) a {
              border-top-color: #32cca6; }
              .header .sub_box .right ul li:nth-child(3) a:hover {
                background: #32cca6; }
            .header .sub_box .right ul li:nth-child(4) a {
              border-top-color: #02c255; }
              .header .sub_box .right ul li:nth-child(4) a:hover {
                background: #02c255; }
            .header .sub_box .right ul li:nth-child(5) a {
              border-top-color: #ff9537; }
              .header .sub_box .right ul li:nth-child(5) a:hover {
                background: #ff9537; }
            .header .sub_box .right ul li:last-child a {
              border-top-color: #f85550; }
              .header .sub_box .right ul li:last-child a:hover {
                background: #f85550; }
    .header #li_1:before {
      top: 0;
      left: 0;
      position: absolute;
      height: 1px;
      background: #9d56e4;
      width: 100%;
      content: "";
      z-index: 99999; }
    .header #li_1 .wrap {
      display: block;
      position: relative;
      height: 100%;
      background-image: url("../images/corp/common/sub_box1-bg.png");
      background-size: cover; }
    .header #li_1 .left {
      padding: 0 0 0 10%; }
    .header #li_1 .right {
      padding: 55px 5%; }
    .header #li_2 .left {
      background: #110051; }
      .header #li_2 .left p {
        line-height: 280px;
        color: #fff;
        text-align: right;
        font-size: 1.71em;
        margin-right: 62px; }
    .header #li_2 .right {
      background: #110051;
      padding: 55px 5%; }
      .header #li_2 .right a {
        background: #110051;
        border-top: 3px solid #fff;
        display: block;
        font-size: 14px;
        color: #fff; }
        .header #li_2 .right a:hover {
          color: #110051;
          background: #fff; }
  /*
  pc footer
--------------------------------------------- */
  .footer {
    border-top: 3px solid #110051;
    margin: 0 auto;
    padding: 0 30px; }
    .footer .holder {
      *zoom: 1;
      min-height: 78px; }
      .footer .holder:after {
        content: ".";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
      .footer .holder .logo_holder {
        padding: 24px 0;
        float: left;
        vertical-align: middle; }
        .footer .holder .logo_holder img {
          vertical-align: middle; }
      .footer .holder .footer_nav {
        float: right;
        width: calc(100% - 100px);
        vertical-align: middle;
        text-align: right; }
        .footer .holder .footer_nav li {
          display: inline-block;
          position: relative;
          vertical-align: top;
          line-height: 13px;
          padding: 43px 0 0; }
          .footer .holder .footer_nav li:last-child {
            line-height: 19px;
            padding-top: 37px; }
          .footer .holder .footer_nav li a {
            text-decoration: none;
            color: #110051;
            font-size: 0.92em;
            padding: 0 15px;
            display: block;
            color: #8a8a8a; }
            .footer .holder .footer_nav li a:hover {
              color: #110051; }
    .footer .copyright {
      font-family: "PT Sans", sans-serif;
      font-size: 0.92em;
      padding-bottom: 20px; }
    .footer_sns ul {
      text-align: center; }
      .footer_sns ul li {
        display: inline-block;
        padding: 60px 15px;
        width: 59px;
        text-align: center; }
        .footer_sns ul li img {
          margin: 0 auto; }
  /*
  pc common area
--------------------------------------------- */
  main {
    position: relative; }
  .blur.active {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    z-index: 111;
    background: rgba(0, 0, 0, 0.7); }
  .area_tit {
    text-align: center;
    font-size: 2.28em;
    font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    letter-spacing: .075em;
    line-height: 1.6;
    padding: 70px 0 60px; }
    .area_tit span {
      display: block;
      font-size: 16px;
      font-family: "PT Sans", sans-serif;
      letter-spacing: .1em; }
  /* ---------- area blog ---------- */
  .area_blog {
    padding-bottom: 70px; }
    .area_blog .area_tit span {
      color: #32cca6; }
    .area_blog_cont {
      letter-spacing: -1em;
      margin: 0 -10px; }
      .area_blog_cont_box {
        letter-spacing: normal;
        text-align: left;
        display: inline-block;
        margin: 0 5px;
        width: calc(25% - 10px);
        max-width: 292px;
        vertical-align: top; }
        .area_blog_cont_box .img {
          padding-bottom: 20px; }
          .area_blog_cont_box .img img {
            width: 100%; }
        .area_blog_cont_box .date {
          font-size: 0.85em;
          font-weight: bold;
          line-height: 28px;
          display: inline-block;
          padding: 0 10px; }
        .area_blog_cont_box .tag {
          font-size: 0.78em;
          text-align: center;
          padding: 0 12px;
          color: #32cca6;
          display: inline-block;
          line-height: 26px;
          border: 1px #32cca6 solid;
          border-radius: 14px; }
        .area_blog_cont_box .tit {
          padding: 16px 10px;
          line-height: 1.5; }
        .area_blog_cont_box a {
          text-decoration: none; }
          .area_blog_cont_box a:hover {
            color: #32cca6; }
  /*
  pc page
--------------------------------------------- */
  .pages {
    background: url(../images/corp/common/head_bg.png) no-repeat center top;
    background-size: contain; }
    .pages .area_key {
      padding: 20px 0 100px; }
      .pages .area_key .area_tit {
        padding: 70px 0 40px; }
  /* ---------- buttons ---------- */
  .btn {
    background: #fff;
    display: inline-block;
    padding: 0 30px;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    border-radius: 25px;
    line-height: 44px; }
  .btn-purple {
    border: 1px solid #9d56e4;
    color: #9d56e4; }
    .btn-purple:hover {
      background: #9d56e4;
      color: #fff; }
  .btn-orange {
    border: 1px solid #ff9537;
    color: #ff9537; }
    .btn-orange:hover {
      background: #ff9537;
      color: #fff; }
  .btn-lightGreen {
    border: 1px solid #32cca6;
    color: #32cca6; }
    .btn-lightGreen:hover {
      background: #32cca6;
      color: #fff; }
  /*
  pc index header
--------------------------------------------- */
  .header {
    position: relative; }
  /*
  pc hero
--------------------------------------------- */
  .hero {
    background: url(../images/corp/common/hero_bg.png) no-repeat center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: 470px;
    position: absolute;
    width: 100%;
    z-index: 100; }
    .hero_logo {
      position: absolute;
      top: 160px;
      left: 50%;
      margin-left: -172.5px;
      text-align: center; }
      .hero_logo img {
        text-align: center;
        margin: 0 auto; }
  .hero_loading {
    display: none;
    transition: .6s;
    width: 100%;
    background: #fff;
    height: 100vh;
    position: relative;
    z-index: 10000; }
    .hero_loading .hero_logo {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -172.5px;
      margin-top: -172.5px;
      text-align: center; }
      .hero_loading .hero_logo img {
        text-align: center;
        margin: 0 auto; }
  #home {
    /* ---------- visual text ---------- */
    /* ---------- info ---------- */
    /* ---------- miraimo ---------- */
    /* ---------- miraimo-newgrads ---------- */ }
    #home .s1 {
      padding-top: 510px; }
      #home .s1 h2 {
        padding-top: 65px;
        text-align: center;
        font-size: 30px;
        color: #110051;
        font-family: "a-otf-ud-shin-go-pr6n", sans-serif;
        letter-spacing: .12em;
        font-feature-settings: "palt"; }
      #home .s1 p {
        font-family: "a-otf-ud-shin-go-pr6n", sans-serif;
        letter-spacing: .1em;
        line-height: 2em;
        text-align: center;
        font-size: 1.14em;
        margin: 58px auto 84px; }
    #home .s2 {
      padding: 70px 0 150px;
      min-height: 444px;
      text-align: center; }
      #home .s2 h3 {
        text-align: center;
        font-size: 1.78em;
        position: relative;
        display: inline-block;
        padding-bottom: 10px;
        font-family: "a-otf-ud-shin-go-pr6n", sans-serif;
        letter-spacing: .2em; }
        #home .s2 h3:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          content: "";
          display: block;
          height: 3px;
          width: 50%;
          background: #110051; }
      #home .s2 .info_holder {
        margin: 60px 0; }
      #home .s2 ul {
        text-align: left; }
        #home .s2 ul li {
          margin-bottom: 20px; }
          #home .s2 ul li a {
            text-decoration: none;
            display: block; }
            #home .s2 ul li a:hover {
              color: #110051; }
              #home .s2 ul li a:hover .title {
                text-decoration: underline; }
          #home .s2 ul li .date {
            display: inline-block;
            vertical-align: top;
            width: 15%; }
          #home .s2 ul li .title {
            display: inline-block;
            vertical-align: top;
            width: 83%; }
      #home .s2 .btn-l {
        width: 250px;
        height: 46px;
        line-height: 44px;
        font-size: 14px;
        margin-right: 0;
        position: relative; }
        #home .s2 .btn-l.rounded {
          border-radius: 30px; }
    #home .s3 {
      background-image: url("../images/corp/common/mirai-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top; }
      #home .s3 .inner_sm {
        text-align: center;
        padding: 200px 0 100px; }
        #home .s3 .inner_sm .logo {
          margin: 0 auto 55px;
          width: 300px; }
          #home .s3 .inner_sm .logo img {
            width: 100%; }
        #home .s3 .inner_sm .text h3 {
          font-size: 1.42em;
          font-weight: bold;
          margin-bottom: 20px; }
        #home .s3 .inner_sm .text p {
          margin-bottom: 25px; }
        #home .s3 .inner_sm .text a {
          color: #ff9537;
          text-decoration: none;
          width: 250px; }
          #home .s3 .inner_sm .text a:hover {
            color: #fff; }
      #home .s3 .inner_mid h3 {
        text-align: center;
        font-size: 1.78em;
        position: relative;
        display: inline-block;
        padding-bottom: 10px;
        margin-bottom: 45px;
        font-family: "a-otf-ud-shin-go-pr6n", sans-serif;
        letter-spacing: .2em; }
        #home .s3 .inner_mid h3:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          content: "";
          display: block;
          height: 3px;
          width: 50%;
          background: #32cca6; }
      #home .s3 .inner_mid .next-btn {
        margin-top: 43px; }
        #home .s3 .inner_mid .next-btn .btn {
          color: #32cca6;
          text-decoration: none; }
          #home .s3 .inner_mid .next-btn .btn:hover {
            color: #FFF; }
      #home .s3 .inner_mid.area_blog {
        padding-bottom: 200px; }
    #home .miraimo-newgrads {
      position: relative; }
      #home .miraimo-newgrads-image img {
        width: 100%; }
      #home .miraimo-newgrads-wrap {
        position: absolute;
        top: 50%;
        left: 8.3%;
        transform: translateY(-50%);
        text-align: center; }
      #home .miraimo-newgrads-title {
        margin: 0 auto 20px;
        width: 296px; }
        #home .miraimo-newgrads-title img {
          width: 100%; }
      #home .miraimo-newgrads-desc {
        color: #FFF;
        line-height: 1.857;
        margin-bottom: 30px; }
      #home .miraimo-newgrads .newgrads_button {
        font-weight: bold;
        text-align: center;
        display: block;
        background: #fff;
        line-height: 49px;
        width: 244px;
        border-radius: 25px;
        margin: 0 auto;
        background: #58c56e;
        background: -moz-linear-gradient(left, #58c56e 0%, #b4d451 100%);
        background: -webkit-linear-gradient(left, #58c56e 0%, #b4d451 100%);
        background: linear-gradient(to right, #58c56e 0%, #b4d451 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#58c56e', endColorstr='#b4d451', GradientType=1);
        color: #FFF !important;
        text-decoration: none !important; }
        #home .miraimo-newgrads .newgrads_button:hover {
          opacity: 0.75; }
  /* loading cont */
  body .wrapper {
    height: auto;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 2s ease-in-out;
    -moz-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out; }
  body .loading_cont {
    height: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    display: none; }
    body .loading_cont .hero {
      height: 0;
      opacity: 0;
      visibility: hidden; }
  body.loading {
    overflow: hidden; }
  body.loading .wrapper {
    height: 0;
    opacity: 0;
    visibility: hidden; }
  body.loading .loading_cont {
    overflow: hidden;
    width: 100%;
    position: fixed;
    height: 100%;
    background: #fff;
    opacity: 1;
    visibility: visible;
    display: block; }
    body.loading .loading_cont .hero {
      height: auto;
      opacity: 1;
      visibility: visible;
      background: #fff; }
  @-webkit-keyframes herologo {
    from {
      top: 300px;
      opacity: 0; }
    to {
      top: 228px;
      opacity: 1; } }
  @-moz-keyframes herologo {
    from {
      top: 300px;
      opacity: 0; }
    to {
      top: 228px;
      opacity: 1; } }
  @keyframes herologo {
    from {
      top: 300px;
      opacity: 0; }
    to {
      top: 228px;
      opacity: 1; } }
    body.loading .loading_cont .hero_logo {
      -webkit-animation-name: herologo;
      -webkit-animation-duration: 2.5s;
      -moz-animation-name: herologo;
      -moz-animation-duration: 2.5s;
      animation-name: herologo;
      animation-duration: 2.5s; }
  /*
  pc information
--------------------------------------------- */
  /* ---------- information archive ---------- */
  #information_archive {
    /* ---------- sidebar ---------- */ }
    #information_archive .main_heading .overlay {
      background-image: url("../images/corp/information/information-bg.png");
      margin-bottom: 80px; }
    #information_archive .content_holder {
      margin-bottom: 80px; }
    #information_archive .big_heading p {
      padding-top: 30px; }
      #information_archive .big_heading p.eng {
        padding: 0; }
    #information_archive .container {
      *zoom: 1;
      margin: 80px auto 110px;
      width: 930px; }
      #information_archive .container:after {
        content: ".";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
      #information_archive .container .article_cont {
        width: calc(95% - 230px);
        margin-right: 5%;
        float: left; }
        #information_archive .container .article_cont .article_wrap {
          padding-bottom: 20px; }
      #information_archive .container .article_sidebar {
        width: 230px;
        float: right; }
        #information_archive .container .article_sidebar .archive_tit {
          font-size: 1.14em;
          font-weight: bold;
          text-align: center;
          padding: 10px 0;
          border-top: 1px;
          border-bottom: 1px;
          border-color: #110051;
          border-style: solid; }
    #information_archive .article_box {
      text-align: left;
      margin: 0 2% 20px;
      *zoom: 1; }
      #information_archive .article_box:after {
        content: ".";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
      #information_archive .article_box:hover .article_tit {
        color: #9d56e4; }
      #information_archive .article_box p.article_date {
        font-size: 12px;
        color: #666666;
        float: left;
        padding-top: 2px; }
      #information_archive .article_box .article_tit {
        float: left;
        color: #000000;
        font-size: 14px;
        margin-left: 20px;
        width: 535px; }
    #information_archive .page-nation {
      letter-spacing: normal;
      margin: 50px 0; }
      #information_archive .page-nation ul li {
        padding: 0 6px;
        display: inline-block; }
        #information_archive .page-nation ul li a {
          display: block;
          text-align: center;
          width: 45px;
          line-height: 45px;
          border-radius: 4px;
          border: 1px #b7b2ca solid;
          background: #fff;
          color: #b7b2ca;
          text-decoration: none; }
        #information_archive .page-nation ul li.current a {
          color: #fff;
          background: #b7b2ca; }
    #information_archive .archive .archive_list li {
      position: relative;
      margin-bottom: 6px; }
      #information_archive .archive .archive_list li a {
        display: block;
        line-height: 45px;
        text-decoration: none;
        color: #2d2d2d;
        padding: 0 20px;
        border-bottom: 1px dashed #110051; }
        #information_archive .archive .archive_list li a:after {
          float: right;
          content: url(../images/corp/common/ico-arrow-right-blue.png);
          vertical-align: middle;
          clear: both; }
        #information_archive .archive .archive_list li a i {
          color: #2d205f;
          position: absolute;
          right: 20px;
          top: 50%;
          margin-top: -7px; }
  /* ---------- information single ---------- */
  #information_single .main_heading .overlay,
  .single-post .main_heading .overlay {
    background-image: url("../images/corp/information/information-bg.png");
    margin-bottom: 80px; }
  #information_single .content_holder,
  .single-post .content_holder {
    margin-bottom: 80px; }
  #information_single .big_heading,
  .single-post .big_heading {
    width: 800px;
    margin: 0 auto; }
    #information_single .big_heading p.article_date,
    .single-post .big_heading p.article_date {
      text-align: left;
      margin-bottom: 0; }
    #information_single .big_heading h3,
    .single-post .big_heading h3 {
      margin-top: 40px; }
  #information_single .container,
  .single-post .container {
    margin: 80px auto 110px;
    width: 800px; }
    #information_single .container .single_cont p,
    .single-post .container .single_cont p {
      margin-top: 30px; }
    #information_single .container .single_cont .mid_tit,
    .single-post .container .single_cont .mid_tit {
      *zoom: 1;
      font-size: 22px;
      margin-top: 70px; }
      #information_single .container .single_cont .mid_tit:after,
      .single-post .container .single_cont .mid_tit:after {
        content: ".";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
      #information_single .container .single_cont .mid_tit .mid_tit_img,
      .single-post .container .single_cont .mid_tit .mid_tit_img {
        float: left;
        padding-top: 17px; }
      #information_single .container .single_cont .mid_tit .mid_tit_txt,
      .single-post .container .single_cont .mid_tit .mid_tit_txt {
        float: left;
        margin-top: 0;
        padding-left: 15px; }
  /*
  pc parts
--------------------------------------------- */
  .main_heading {
    position: relative; }
    .main_heading .overlay {
      background: url("../images/corp/common/main-heading-bg.png") no-repeat center bottom;
      background-size: cover;
      min-height: 240px;
      position: relative; }
      .main_heading .overlay .inner_mid {
        position: static; }
      .main_heading .overlay h1 {
        text-align: center;
        color: #fff;
        font-size: 1.78em;
        font-family: "a-otf-ud-shin-go-pr6n", sans-serif;
        letter-spacing: .2em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        -webkit-transform: translateY(-50%) translateX(-50%);
        -ms-transform: translateY(-50%) translateX(-50%); }
      .main_heading .overlay .breadcrumbs ul {
        padding: 25px 10px 0; }
        .main_heading .overlay .breadcrumbs ul li {
          color: #fff;
          display: inline-block;
          font-size: 0.92em; }
          .main_heading .overlay .breadcrumbs ul li a {
            color: #fff;
            display: inline-block;
            vertical-align: top;
            text-decoration: underline; }
            .main_heading .overlay .breadcrumbs ul li a:hover {
              text-decoration: none; }
          .main_heading .overlay .breadcrumbs ul li:after {
            content: ">";
            padding: 0 15px;
            display: inline-block;
            vertical-align: top; }
          .main_heading .overlay .breadcrumbs ul li:last-child:after {
            content: ''; }
  .big_heading {
    text-align: center; }
    .big_heading p.eng {
      font-size: 0.78em;
      letter-spacing: 0.2em;
      color: #110051;
      margin-bottom: 0; }
    .big_heading h3 {
      text-align: center;
      font-size: 30px;
      letter-spacing: .1em;
      position: relative;
      display: inline-block;
      font-family: "a-otf-ud-shin-go-pr6n", sans-serif; }
    .big_heading h4 {
      font-size: 1.42em;
      margin: 23px 0; }
    .big_heading p {
      margin-bottom: 23px; }
  /* icon circle */
  .ico_circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #bfbfbf;
    color: #fff;
    padding: 15px;
    font-size: 1.78em;
    text-align: center;
    line-height: 1.5;
    margin: 37px auto; }
    .ico_circle .fa {
      font-size: 1.64em; }
  .ico_circle_sm {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 15px 5px;
    font-size: 1.35em;
    text-align: center;
    line-height: 1.5;
    max-width: 60px;
    display: inline-block;
    vertical-align: middle;
    color: #110051; }
    .ico_circle_sm .fa {
      font-size: 1.64em; }
  .inner_parts {
    max-width: 930px;
    margin: 0 auto; }
  /* margin and padding 0 */
  .pbtm-0 {
    padding-bottom: 0 !important; }
  .mbtm-0 {
    margin-bottom: 0 !important; }
  /* flex */
  .inline {
    display: flex;
    flex-wrap: wrap; }
  /* typhography */
  .article p {
    margin-bottom: 25px; }
  .article p.p_right {
    width: 643px;
    float: right; }
  /* lists */
  ol.steps {
    margin-left: 20px; }
    ol.steps li {
      border-top: 1px dashed #bfbfbf;
      padding: 26px 0 26px 76px;
      list-style-type: decimal; }
      ol.steps li:last-child {
        border-bottom: 1px dashed #bfbfbf; }
  ol.steps-simple {
    margin-left: 20px; }
    ol.steps-simple li {
      counter-increment: customlistcounter; }
      ol.steps-simple li:before {
        content: counter(customlistcounter) ")";
        float: left;
        width: 1.2em; }
      ol.steps-simple li:first-child {
        counter-reset: customlistcounter; }
  ul.disc {
    margin-left: 20px;
    list-style: disc; }
  ol.num li {
    counter-increment: customlistcounter; }
    ol.num li:before {
      padding-right: 10px;
      content: counter(customlistcounter) "";
      float: left;
      width: 1.2em;
      color: #778fce; }
    ol.num li:first-child {
      counter-reset: customlistcounter; }
  ul.square li:before {
    padding-right: 10px;
    float: left;
    width: 1.2em;
    content: url("../images/corp/common/ico-square.png");
    display: inline-block;
    vertical-align: middle; }
  /* table */
  .table {
    width: 100%;
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd; }
    .table tr {
      border-bottom: 1px solid #dddddd; }
    .table td {
      padding: 25px; }
      .table td:first-child {
        width: 20%;
        background: #f6f6f6;
        text-align: center;
        border-right: 1px solid #dddddd; }
    .table.table-with-heading tr:first-child {
      background: #e1eef6; }
      .table.table-with-heading tr:first-child td {
        padding: 17px; }
        .table.table-with-heading tr:first-child td:first-child {
          background: #e1eef6; }
  /* anchor links */
  a.ac {
    text-decoration: underline;
    display: inline-block;
    margin-right: 38px; }
  a.blnk:after {
    padding-left: 5px;
    content: url("../images/corp/common/ico-blank.png");
    display: inline-block;
    vertical-align: middle; }
  a.pdf:after {
    padding-left: 5px;
    content: url("../images/corp/common/ico-pdf.png");
    display: inline-block;
    vertical-align: middle; }
  /* btns */
  a.btn-l {
    font-size: 1.28em;
    text-align: center;
    text-decoration: none !important;
    display: inline-block;
    width: 386px;
    height: 60px;
    line-height: 60px;
    background: #fff;
    border: 1px solid #110051;
    color: #110051;
    padding: 0 30px;
    border-radius: 30px; }
    a.btn-l:hover {
      background: #110051;
      color: #fff; }
    a.btn-l.rounded {
      border-radius: 30px; }
  a.btn-s {
    text-align: center;
    text-decoration: none !important;
    color: #fff;
    display: inline-block;
    width: 132px;
    height: 32px;
    line-height: 32px;
    background: #778fce;
    -webkit-box-shadow: 2px 2px 2px 0 #7983a0;
    -moz-box-shadow: 2px 2px 2px 0 #7983a0;
    box-shadow: 2px 2px 2px 0 #7983a0; }
    a.btn-s:hover {
      background: #110051; }
  a.btn-navi {
    font-size: 0.92em;
    text-align: center;
    text-decoration: none;
    color: #FFF;
    display: inline-block;
    width: 132px;
    height: 32px;
    line-height: 32px;
    border: 1px solid #110051;
    background: #110051;
    vertical-align: middle;
    position: relative; }
    a.btn-navi:before, a.btn-navi:after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      content: "";
      vertical-align: middle; }
    a.btn-navi:before {
      right: 8px;
      width: 10px;
      height: 1px;
      background: #fff; }
    a.btn-navi:after {
      right: 8px;
      width: 6px;
      height: 6px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    a.btn-navi:hover {
      background: #FFF;
      color: #110051; }
      a.btn-navi:hover:before {
        background: #110051; }
      a.btn-navi:hover:after {
        border-top-color: #110051;
        border-right-color: #110051; }
  .cont {
    margin: 25px auto;
    /* typhography */
    /* box size */
    /* title */
    /* box with images */ }
    .cont .border-drk {
      border: 2px solid #b7b2ca; }
    .cont .border-light {
      border: 2px solid #f4f3f7; }
    .cont .bg-light {
      background: #f4f3f7; }
    .cont .rounded {
      border-radius: 5px; }
    .cont .txt {
      border-top: 2px solid #b7b2ca;
      padding-top: 25px; }
    .cont .title {
      font-size: 1.35em;
      color: #110051;
      display: inline-block;
      vertical-align: middle;
      line-height: 1.5; }
    .cont p {
      font-size: 0.92em; }
    .cont p.center {
      text-align: center; }
    .cont .box {
      padding: 20px; }
    .cont .box-1 {
      width: 100%; }
    .cont .box-2 {
      width: calc(100%/2 - 25px); }
      .cont .box-2:first-child {
        margin-right: 25px; }
      .cont .box-2:last-child {
        margin-left: 25px; }
    .cont .box-3 {
      width: calc(100%/3);
      clear: both; }
      .cont .box-3:first-child img {
        float: left; }
      .cont .box-3:first-child p {
        width: 90%;
        float: left; }
      .cont .box-3:nth-child(2) img {
        margin: 0 auto; }
      .cont .box-3:nth-child(2) p {
        width: 90%;
        margin: 0 auto; }
      .cont .box-3:last-child img {
        float: right; }
      .cont .box-3:last-child p {
        width: 90%;
        float: right; }
    .cont h4 {
      font-size: 0.92em;
      color: #110051;
      border-bottom: 1px solid #bfbfbf;
      font-weight: bold;
      padding-bottom: 10px;
      margin-bottom: 20px; }
    .cont .box-with-img-right {
      overflow: auto; }
      .cont .box-with-img-right img {
        float: right;
        margin: 0 0 43px 43px; }
    .cont .box-with-img-left {
      overflow: auto; }
      .cont .box-with-img-left img {
        float: left;
        margin: 0 43px 43px 0; }
  .content_holder p {
    text-align: left; }
  .clearfix {
    clear: both; }
  /*
  pc company
--------------------------------------------- */
  /* ---------- company access ---------- */
  #access .main_heading .overlay {
    background-image: url("../images/corp/company/company-bg.png");
    margin-bottom: 80px; }
  #access .content_holder {
    padding-bottom: 80px; }
  #access .big_heading p {
    padding-top: 30px; }
    #access .big_heading p.eng {
      padding: 0; }
  #access .ico_circle {
    background: #ddd;
    padding: 32px 10px;
    color: #110051; }
    #access .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #access .inner_parts {
    max-width: 930px;
    margin: 0 auto;
    width: 100%;
    margin-top: 80px; }
    #access .inner_parts .ico_circle_sm {
      padding: 15px 10px; }
      #access .inner_parts .ico_circle_sm img {
        width: 20px;
        margin: 0 auto;
        text-align: center; }
    #access .inner_parts .box1 {
      margin-bottom: 30px; }
      #access .inner_parts .box1 > .box:last-child {
        padding-top: 0; }
    #access .inner_parts .box {
      padding: 0; }
      #access .inner_parts .box .map {
        border: 1px #ddd solid;
        padding: 10px 10px 4px; }
        #access .inner_parts .box .map iframe {
          width: 100%;
          height: 460px; }
      #access .inner_parts .box .btn_wrap {
        width: 100%;
        padding: 15px 0;
        text-align: right;
        border-bottom: 1px #8a8a8a dashed; }
        #access .inner_parts .box .btn_wrap a {
          margin: 0 0 0 15px; }
        #access .inner_parts .box .btn_wrap .plus {
          position: relative;
          font-size: 0.92em; }
          #access .inner_parts .box .btn_wrap .plus:before {
            content: "";
            width: 12px;
            height: 12px;
            display: inline-block;
            vertical-align: middle;
            padding-right: 6px;
            background: url(../images/corp/company/ico-plus.png) no-repeat left center; }
        #access .inner_parts .box .btn_wrap .btn-navi {
          width: 152px; }
      #access .inner_parts .box .detail {
        width: 100%;
        margin-top: 10px; }
        #access .inner_parts .box .detail th,
        #access .inner_parts .box .detail td {
          vertical-align: top;
          padding: 5px 0; }
        #access .inner_parts .box .detail th {
          width: 120px; }
  /* ---------- company profile ---------- */
  #profile .main_heading .overlay {
    background-image: url("../images/corp/company/company-bg.png");
    margin-bottom: 80px; }
  #profile .content_holder {
    padding-bottom: 80px; }
  #profile .big_heading p {
    padding-top: 30px; }
    #profile .big_heading p.eng {
      padding: 0; }
  #profile .ico_circle {
    background: #ddd;
    padding: 32px 10px;
    color: #110051; }
    #profile .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #profile .inner_parts {
    max-width: 930px;
    margin: 0 auto;
    width: 100%;
    border-top: 2px solid #110051;
    margin-top: 80px;
    padding-bottom: 80px;
    padding: 10px 32px; }
    #profile .inner_parts table {
      width: 100%; }
      #profile .inner_parts table th,
      #profile .inner_parts table td {
        padding: 15px 30px;
        border-top: 1px dashed #bfbfbf;
        vertical-align: top;
        position: relative; }
      #profile .inner_parts table tr:first-child th,
      #profile .inner_parts table tr:first-child td {
        border-top: none; }
      #profile .inner_parts table th {
        width: 166px;
        font-weight: bold; }
      #profile .inner_parts table .btn_wrap {
        width: 190px;
        position: absolute;
        bottom: 30px;
        right: 30px; }
        #profile .inner_parts table .btn_wrap .btn-l {
          width: 190px;
          height: 34px;
          line-height: 34px;
          font-size: 0.92em;
          margin-right: 0;
          padding: 0;
          position: relative; }
          #profile .inner_parts table .btn_wrap .btn-l.rounded {
            border-radius: 30px; }
  /* ---------- company message ---------- */
  #message .main_heading .overlay {
    background-image: url("../images/corp/company/company-bg.png");
    margin-bottom: 80px; }
  #message .content_holder {
    position: relative;
    max-width: 930px;
    margin: 0 auto;
    width: 100%;
    margin-top: 80px;
    padding-bottom: 80px; }
    #message .content_holder .img_holder {
      margin: 0 auto;
      width: 560px; }
      #message .content_holder .img_holder img {
        margin: 0 auto;
        width: 80%; }
    #message .content_holder .text_holder {
      width: 750px;
      margin: 0 auto; }
      #message .content_holder .text_holder h4 {
        margin: 64px 0;
        font-size: 25px; }
      #message .content_holder .text_holder ol {
        margin-bottom: 46px; }
      #message .content_holder .text_holder p {
        margin-bottom: 44px; }
    #message .content_holder .signature {
      width: 55%;
      margin-bottom: 108px;
      float: right; }
      #message .content_holder .signature p {
        margin: 0; }
      #message .content_holder .signature span {
        display: inline-block;
        vertical-align: middle; }
      #message .content_holder .signature img {
        width: 35%;
        display: inline-block;
        vertical-align: middle;
        float: right;
        clear: both; }
  /* ---------- company index ---------- */
  #company .main_heading .overlay {
    background-image: url("../images/corp/company/company-bg.png");
    margin-bottom: 80px; }
  #company .content_holder {
    margin-top: 80px;
    padding-bottom: 80px; }
  #company .big_heading p {
    padding-top: 30px; }
    #company .big_heading p.eng {
      padding: 0; }
  #company .ico_circle {
    background: #110051;
    padding: 32px 10px; }
    #company .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #company .box {
    width: calc(100%/3);
    position: relative;
    padding: 0 10px; }
    #company .box img {
      max-width: 100%;
      width: 100%; }
    #company .box a {
      border: 1px solid #ddd;
      width: 100%;
      color: #110051;
      height: 80px;
      line-height: 80px;
      background: rgba(255, 255, 255, 0.8);
      text-align: center;
      text-decoration: none;
      font-weight: bold;
      font-size: 1.28em;
      display: table;
      table-layout: fixed;
      width: 100%;
      position: relative; }
      #company .box a:hover {
        color: #778fce; }
    #company .box .box_image {
      display: table-cell;
      width: 50%;
      vertical-align: middle; }
    #company .box .box_text {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
      text-align: center; }
  /*
  pc contact
--------------------------------------------- */
  /* ---------- contact index ---------- */
  #inquiry .main_heading .overlay {
    background-image: url("../images/corp/contact/contact-bg.png");
    margin-bottom: 80px; }
  #inquiry .content_holder {
    margin-bottom: 80px; }
  #inquiry .big_heading p {
    padding-top: 30px; }
    #inquiry .big_heading p.eng {
      padding: 0; }
  #inquiry .ico_circle {
    background: #110051;
    padding: 30px 10px; }
    #inquiry .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #inquiry .inner_parts {
    margin-top: 80px; }
  #inquiry .form_cont .form_inner {
    padding: 0 50px; }
  #inquiry .form_cont label span {
    background: #f85550;
    color: #fff;
    border-radius: 4px;
    display: inline-block;
    padding: 4px 6px 4px;
    font-size: 0.71em;
    line-height: 1;
    margin-left: 10px; }
  #inquiry .form_cont table {
    width: 100%; }
    #inquiry .form_cont table th,
    #inquiry .form_cont table td {
      padding: 12px 0; }
    #inquiry .form_cont table th {
      padding: 20px 0;
      width: 230px;
      vertical-align: top; }
    #inquiry .form_cont table td input[type="text"],
    #inquiry .form_cont table td input[type="email"],
    #inquiry .form_cont table td textarea {
      width: 100%;
      border-radius: 4px;
      border: 1px #ddd solid;
      padding: 8px;
      font-family: Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif; }
    #inquiry .form_cont table td textarea {
      height: 260px; }
    #inquiry .form_cont table td .formrun-system-show {
      color: #f85550;
      font-size: 0.85em; }
  #inquiry .form_cont input[type="button"],
  #inquiry .form_cont input[type="submit"],
  #inquiry .form_cont button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none; }
  #inquiry .form_cont .submit_btn {
    text-align: center;
    margin: 50px auto; }
  #inquiry .form_cont button[type="submit"] {
    font-family: Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    font-size: 1.28em;
    text-align: center;
    text-decoration: none !important;
    display: inline-block;
    width: 386px;
    height: 60px;
    line-height: 60px;
    background: #fff;
    border: 1px solid #110051;
    color: #110051;
    padding: 0 30px;
    border-radius: 30px; }
    #inquiry .form_cont button[type="submit"]:hover {
      background: #110051;
      color: #fff; }
  #inquiry .txt_link {
    text-align: center; }
  #inquiry .is-visible {
    display: block;
    color: #f85550;
    font-size: 0.85em; }
  /* ---------- contact thankyou ---------- */
  #thankyou .main_heading .overlay {
    background-image: url("../images/corp/contact/contact-bg.png");
    margin-bottom: 80px; }
  #thankyou .big_heading h3 {
    margin-bottom: 50px; }
  #thankyou .form_thankyou_cont {
    max-width: 930px;
    text-align: center;
    margin: 0 auto; }
    #thankyou .form_thankyou_cont p {
      margin: 0 auto 30px;
      text-align: center; }
  #thankyou .inner_parts {
    margin-top: 0;
    padding-bottom: 100px; }
  #thankyou .btn_wrap {
    margin: 70px 0; }
  /*
  pc business
--------------------------------------------- */
  /* ---------- business index ---------- */
  #business .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png");
    margin-bottom: 80px; }
  #business .inner_parts {
    padding-bottom: 80px; }
  #business .big_heading {
    margin-bottom: 60px; }
    #business .big_heading p {
      padding-top: 30px; }
      #business .big_heading p.eng {
        padding: 0; }
  #business .ico_circle {
    background: #110051;
    padding: 32px 10px; }
    #business .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #business .cont {
    margin: 20px 0; }
  #business .box-2 {
    margin-top: 20px;
    margin-bottom: 40px;
    border-top: 2px solid #110051;
    padding-top: 10px; }
    #business .box-2:first-child {
      margin-right: 40px; }
    #business .box-2:last-child {
      margin-left: 0; }
  #business .box {
    padding: 0; }
    #business .box .title {
      color: #222; }
  #business .box p {
    min-height: 90px; }
  #business .box_bottom {
    position: relative;
    margin-top: 20px; }
    #business .box_bottom img {
      width: 100%;
      max-width: 200px; }
  #business .box_btn {
    position: absolute;
    bottom: 0;
    right: 0;
    vertical-align: bottom !important; }
    #business .box_btn .btn-l {
      width: 132px;
      height: 34px;
      line-height: 34px;
      font-size: 0.92em;
      margin-right: 0;
      padding: 0; }
      #business .box_btn .btn-l.rounded {
        border-radius: 30px; }
  #business .ico_circle_sm img {
    margin: 0 auto; }
  /* ---------- business recruitment ---------- */
  #recruitment .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png");
    margin-bottom: 80px; }
  #recruitment .inner_parts {
    margin-bottom: 80px; }
  #recruitment .big_heading {
    margin-bottom: 60px; }
    #recruitment .big_heading h4 {
      margin-top: 80px; }
    #recruitment .big_heading p.eng {
      padding: 0; }
  #recruitment .ico_circle {
    background: none;
    padding: 10px 0;
    margin-bottom: 60px; }
    #recruitment .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #recruitment .cont {
    margin: 20px 0; }
  #recruitment .box1 {
    margin-top: 40px; }
    #recruitment .box1 .box:first-child {
      margin-bottom: 10px; }
    #recruitment .box1 .article {
      border-top-color: #110051;
      padding-top: 30px; }
  #recruitment .box-2 {
    margin-top: 20px;
    margin-bottom: 40px;
    border-top: 2px solid #110051;
    padding-top: 10px; }
    #recruitment .box-2:first-child {
      margin-right: 40px; }
    #recruitment .box-2:last-child {
      margin-left: 0; }
  #recruitment .box {
    padding: 0; }
    #recruitment .box:first-child {
      margin-bottom: 20px; }
    #recruitment .box .title {
      color: #222; }
  #recruitment span.smalltxt {
    font-size: 12px; }
  #recruitment .ico_circle_sm img {
    margin: 0 auto; }
  #recruitment .step {
    width: 615px;
    margin: 0 auto 30px;
    clear: both; }
    #recruitment .step .step_L {
      display: inline-block; }
      #recruitment .step .step_L p.step_L_txt {
        display: inline-block;
        font-size: 13px;
        color: #110051;
        padding-right: 10px; }
      #recruitment .step .step_L .step_L_number {
        display: inline-block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #110051;
        color: #fff;
        padding: 0 5px;
        font-size: 1.57em;
        text-align: center;
        line-height: 60px;
        max-width: 60px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        letter-spacing: 3px; }
        #recruitment .step .step_L .step_L_number img {
          margin: 0 auto;
          padding-top: 4px; }
    #recruitment .step .step_R {
      display: inline-block;
      width: 470px; }
      #recruitment .step .step_R p.txt1 {
        text-align: center;
        font-size: 20px;
        color: #2d2d2d;
        margin-bottom: 10px; }
      #recruitment .step .step_R p.txt2 {
        text-align: center;
        font-size: 13px;
        color: #2d2d2d;
        margin-bottom: 15px; }
        #recruitment .step .step_R p.txt2 span.underline {
          text-decoration: underline; }
    #recruitment .step .line {
      border-bottom: 1px solid #110051; }
  /* ---------- business service ---------- */
  #service .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png");
    margin-bottom: 80px; }
  #service .inner_parts {
    margin-bottom: 80px; }
  #service .big_heading {
    margin-bottom: 60px; }
    #service .big_heading h4 {
      margin-top: 80px; }
    #service .big_heading p.eng {
      padding: 0; }
  #service .ico_circle {
    background: none;
    padding: 10px 0;
    margin-bottom: 60px; }
    #service .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #service .box {
    padding: 0; }
    #service .box .title {
      color: #222; }
  #service .box1 {
    margin-top: 50px; }
    #service .box1 .box-1 {
      padding: 20px; }
      #service .box1 .box-1 p {
        margin-bottom: 0; }
  #service .box-2 {
    margin-top: 20px;
    margin-bottom: 40px;
    border-top: 2px solid #110051;
    padding-top: 10px; }
    #service .box-2:first-child {
      margin-right: 40px; }
    #service .box-2:last-child {
      margin-left: 0; }
  #service .article {
    border-top-color: #110051; }
  #service .ico_circle_sm img {
    margin: 0 auto; }
  /* ---------- business event ---------- */
  #event .big_heading {
    margin-bottom: 80px; }
    #event .big_heading h4 {
      margin-top: 80px; }
  #event .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png");
    margin-bottom: 80px; }
  #event .inner_parts {
    margin-bottom: 80px; }
  #event .big_heading p.eng {
    padding: 0; }
  #event .box1 > .box {
    margin-bottom: 10px; }
  #event .box {
    padding: 0; }
    #event .box .title {
      color: #222; }
  #event .article {
    border-top-color: #110051; }
  #event .ico_circle {
    background: none;
    padding: 10px 0;
    margin-bottom: 60px; }
    #event .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #event .r_border {
    border-right: 1px solid #ccc;
    margin-right: 0;
    padding-right: 25px; }
  #event .box-2 p {
    margin-bottom: 0; }
  /* ---------- business training ---------- */
  #training .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png");
    margin-bottom: 80px; }
  #training .inner_parts {
    margin-bottom: 80px; }
  #training .big_heading {
    margin-bottom: 80px; }
    #training .big_heading h4 {
      margin-top: 80px; }
    #training .big_heading p.eng {
      padding: 0; }
  #training .ico_circle {
    background: none;
    padding: 10px 0;
    margin-bottom: 60px; }
    #training .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #training .cont {
    margin: 50px 0; }
  #training .box {
    padding: 0; }
    #training .box .title {
      color: #222; }
  #training .article {
    border-top-color: #110051;
    padding-top: 20px; }
  #training .r_border {
    border-right: 1px solid #ccc;
    margin-right: 0;
    padding-right: 25px; }
  #training .step {
    width: 599px;
    margin: 0 auto 30px;
    clear: both; }
    #training .step .step_L {
      display: inline-block; }
      #training .step .step_L p.step_L_txt {
        display: inline-block;
        font-size: 13px;
        color: #110051;
        padding-right: 10px; }
      #training .step .step_L .step_L_number {
        display: inline-block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #110051;
        color: #fff;
        padding: 0 5px;
        font-size: 1.57em;
        text-align: center;
        line-height: 60px;
        max-width: 60px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        letter-spacing: 3px; }
        #training .step .step_L .step_L_number img {
          margin: 0 auto;
          padding-top: 4px; }
    #training .step .step_R {
      display: inline-block;
      width: 470px; }
      #training .step .step_R .txt1 {
        text-align: center;
        font-size: 20px;
        color: #2d2d2d;
        margin-bottom: 10px; }
      #training .step .step_R .txt2 {
        text-align: center;
        font-size: 13px;
        color: #2d2d2d;
        margin-bottom: 15px; }
        #training .step .step_R .txt2 .underline {
          text-decoration: underline; }
    #training .step .line {
      border-bottom: 1px solid #110051;
      display: block;
      margin-left: auto; }
  #training .program_steps {
    margin-top: 80px; }
    #training .program_steps .title {
      color: #222; }
  /* ---------- business consulting ---------- */
  #consulting .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png");
    margin-bottom: 80px; }
  #consulting .inner_parts {
    margin-bottom: 40px; }
  #consulting .big_heading {
    margin-bottom: 60px; }
    #consulting .big_heading.last {
      margin-bottom: 30px; }
    #consulting .big_heading p.eng {
      padding: 0; }
  #consulting h4.mintit {
    text-align: center;
    font-size: 26px;
    padding-top: 30px;
    position: relative; }
    #consulting h4.mintit:after {
      position: absolute;
      bottom: -20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: url("../images/corp/business/business-title_border.png");
      display: block; }
  #consulting .ico_circle {
    background: none;
    padding: 10px 0;
    margin-bottom: 60px; }
    #consulting .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #consulting .box1 {
    margin-top: 50px; }
  #consulting .box-2 {
    margin-top: 20px;
    margin-bottom: 40px;
    border-top: 2px solid #110051;
    padding-top: 10px; }
  #consulting .box {
    padding: 0; }
    #consulting .box .title {
      color: #222; }
  #consulting span.smalltxt {
    font-size: 12px; }
  #consulting .ico_circle_sm img {
    margin: 0 auto; }
  #consulting ol.steps {
    margin-left: 0;
    margin-bottom: 20px; }
    #consulting ol.steps li {
      padding: 15px 0;
      list-style: none;
      padding-left: 1.5em;
      vertical-align: middle;
      font-size: 1.42em; }
      #consulting ol.steps li span {
        display: list-item;
        list-style-type: disc; }
  #consulting .step {
    width: 615px;
    margin: 0 auto 30px;
    clear: both; }
    #consulting .step .step_L {
      display: inline-block; }
      #consulting .step .step_L p.step_L_txt {
        display: inline-block;
        font-size: 13px;
        color: #110051;
        padding-right: 10px; }
      #consulting .step .step_L .step_L_number {
        display: inline-block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #110051;
        color: #fff;
        padding: 0 5px;
        font-size: 1.57em;
        text-align: center;
        line-height: 60px;
        max-width: 60px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        letter-spacing: 3px; }
        #consulting .step .step_L .step_L_number img {
          margin: 0 auto;
          padding-top: 4px; }
    #consulting .step .step_R {
      display: inline-block;
      width: 470px; }
      #consulting .step .step_R p.txt1 {
        text-align: center;
        font-size: 20px;
        color: #2d2d2d;
        margin-bottom: 10px; }
      #consulting .step .step_R p.txt2 {
        text-align: center;
        font-size: 13px;
        color: #2d2d2d;
        margin-bottom: 15px; }
        #consulting .step .step_R p.txt2 span.underline {
          text-decoration: underline; }
    #consulting .step .line {
      border-bottom: 1px solid #110051; }
  #consulting .wrap .imgbox {
    display: inline-block;
    margin-right: 30px;
    letter-spacing: normal; }
  #consulting .wrap .txtbox {
    display: inline-block;
    vertical-align: top;
    width: 285px;
    letter-spacing: normal; }
  #consulting .backgray {
    background: #f4f3f7;
    padding: 20px 30px;
    margin: 20px auto 0;
    width: 615px; }
    #consulting .backgray ul li {
      padding-left: 1em;
      text-indent: -1em; }
  #consulting .consulting_hr .box1 .box {
    margin-bottom: 10px; }
  #consulting .consulting_hr .box1 .article {
    border-top-color: #110051; }
  /* ---------- business fbg ---------- */
  #fbg .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png");
    margin-bottom: 80px; }
  #fbg .inner_parts {
    margin-bottom: 80px; }
  #fbg .big_heading {
    margin-bottom: 80px; }
    #fbg .big_heading h4 {
      margin-top: 80px; }
    #fbg .big_heading p.eng {
      padding: 0; }
  #fbg .ico_circle {
    background: none;
    padding: 10px 0;
    margin-bottom: 60px; }
    #fbg .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #fbg .r_border {
    border-right: 1px solid #ccc;
    margin-right: 0;
    padding-right: 25px; }
  #fbg ol.steps1 {
    margin-left: 0;
    margin-top: -20px; }
    #fbg ol.steps1 li {
      padding: 15px 0 15px 2em;
      list-style: none; }
      #fbg ol.steps1 li span {
        display: list-item;
        list-style-type: disc; }
    #fbg ol.steps1 li.border-t-none {
      border-top: none; }
    #fbg ol.steps1 li.border-b-none {
      border-bottom: none; }
  #fbg ol.steps li.border-t-none {
    border-top: none; }
  #fbg ol.steps li.border-b-none {
    border-bottom: none; }
  #fbg .box {
    padding: 0; }
    #fbg .box .title {
      color: #222; }
  #fbg .article {
    border-top-color: #110051;
    padding-top: 20px; }
  #fbg .box-2 p {
    margin-bottom: 0; }
  #fbg .date {
    margin: 10px 0 0; }
  #fbg .appointment .steps {
    margin-left: 0;
    margin-top: -20px; }
    #fbg .appointment .steps li {
      padding: 15px 0 15px 2em;
      list-style-position: inside;
      text-indent: -1.1em; }
  /*
  pc add
--------------------------------------------- */
  /* ---------- privacy ---------- */
  #privacy .main_heading .overlay {
    background-image: url(../images/corp/company/company-bg.png);
    margin-bottom: 80px; }
  #privacy .inner_parts {
    padding-bottom: 100px; }
  #privacy .cont {
    margin: 50px 0; }
    #privacy .cont p {
      font-size: 14px; }
  #privacy .big_heading {
    margin-bottom: 80px; }
    #privacy .big_heading p {
      padding-top: 30px; }
      #privacy .big_heading p.eng {
        padding: 0; }
  #privacy a.btn-l {
    display: block;
    margin: 0 auto; }
  #privacy .ordered_list {
    list-style-type: decimal;
    margin-left: 20px; }
    #privacy .ordered_list li {
      padding-left: 5px; }
  #privacy .unordered_list li {
    margin-left: 1em;
    text-indent: -1em;
    padding-left: 0; }
    #privacy .unordered_list li:before {
      content: "・"; } }

@media (max-width: 767px) {
  /*
  sp layout
--------------------------------------------- */
  body {
    background: #ddd;
    line-height: 1.8;
    font-size: 14px;
    height: 100%;
    font-family: Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    position: relative;
    font-kerning: normal;
    box-sizing: border-box; }
  .wrapper {
    margin: 0 auto;
    background: #fff; }
  html {
    height: 100%; }
  img {
    max-width: 100%;
    height: auto; }
  .pc_only {
    display: none !important; }
  .inner {
    width: 94%;
    margin: 0 auto;
    position: relative; }
  .inner_mid {
    width: 100%;
    margin: 0 auto;
    position: relative; }
  .inner_min {
    padding: 0 2%;
    margin: 0 auto;
    position: relative; }
  .inner_sm {
    width: 94%;
    margin: 0 auto;
    position: relative; }
  .container {
    *zoom: 1;
    padding: 0 2%;
    margin: 0 auto;
    position: relative; }
    .container:after {
      content: ".";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
  /*
  sp heading
--------------------------------------------- */
  .heading {
    text-align: center; }
  a {
    text-decoration: none; }
  .next-btn {
    text-align: center; }
  a.next {
    text-decoration: none; }
    a.next:after {
      content: url("../images/corp/common/ico-arrow-right.png");
      display: inline-block;
      padding-left: 10px; }
  /*
  sp header
--------------------------------------------- */
  .header .holder {
    min-height: 40px;
    line-height: 40px; }
    .header .holder .inner {
      width: 100%; }
    .header .holder .logo_holder {
      vertical-align: middle;
      width: 100%;
      text-align: center;
      margin: 0 auto; }
      .header .holder .logo_holder img {
        width: 46px;
        display: inline-block;
        vertical-align: middle; }
      .header .holder .logo_holder .logo_txt {
        color: #110051;
        display: inline-block;
        font-size: 0.71em;
        letter-spacing: .2em; }
    .header .holder .g_nav {
      vertical-align: middle;
      width: 100%; }
      .header .holder .g_nav .nav {
        line-height: 30px;
        *zoom: 1; }
        .header .holder .g_nav .nav:after {
          content: ".";
          display: block;
          clear: both;
          height: 0;
          visibility: hidden; }
      .header .holder .g_nav li {
        letter-spacing: normal;
        position: relative;
        width: 33.33%;
        border-bottom: 1px #ddd solid;
        border-right: 1px #ddd solid;
        float: left; }
        .header .holder .g_nav li a {
          color: #110051;
          letter-spacing: .05em;
          font-size: 0.92em;
          padding: 0 2%;
          text-align: center;
          display: block;
          text-decoration: none; }
        .header .holder .g_nav li:first-child {
          width: 50%;
          line-height: 35px;
          font-weight: bold;
          border-top: 5px #9d56e4 solid;
          border-right: none; }
          .header .holder .g_nav li:first-child a {
            border-right: 1px #ddd solid; }
          .header .holder .g_nav li:first-child.active {
            background: #9d56e4; }
            .header .holder .g_nav li:first-child.active a {
              color: #fff; }
        .header .holder .g_nav li:nth-child(2) {
          width: 50%;
          line-height: 35px;
          font-weight: bold;
          border-top: 5px #110051 solid;
          border-right: none; }
          .header .holder .g_nav li:nth-child(2).active {
            background: #110051; }
            .header .holder .g_nav li:nth-child(2).active a {
              color: #fff; }
        .header .holder .g_nav li:last-child {
          border-right: none; }
  .header .sub_box {
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    position: fixed;
    width: 100%;
    top: 30px;
    height: 280px;
    color: #000;
    background: #fff;
    transition: all 0.5s ease-in-out;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch; }
    .header .sub_box.in {
      top: 0;
      visibility: visible;
      opacity: 1; }
    .header .sub_box .wrap {
      *zoom: 1; }
      .header .sub_box .wrap:after {
        content: ".";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
    .header .sub_box p {
      padding: 10px;
      color: #fff;
      border-bottom: 1px solid #fff; }
      .header .sub_box p span {
        display: inline-block;
        vertical-align: middle; }
      .header .sub_box p a.close {
        display: inline-block;
        float: right;
        clear: both;
        border: 0px;
        margin: 5px; }
  .header #sp_li_1 {
    background-image: url("../images/corp/common/sp-miraimo-bg.png");
    background-size: cover;
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 10px; }
    .header #sp_li_1 ul li {
      text-align: center; }
      .header #sp_li_1 ul li img {
        max-width: 50%;
        display: inline-block; }
    .header #sp_li_1 ul li:first-child {
      border-bottom: 1px dashed #9d56e4;
      padding: 25px 0px;
      margin-bottom: 60px; }
    .header #sp_li_1 p {
      background: #9d56e4; }
    .header #sp_li_1 a {
      font-size: 14px;
      color: #9d56e4;
      text-align: center;
      text-decoration: none;
      transition: none;
      -webkit-text-size-adjust: 100%; }
  .header #sp_li_2 {
    background: #110051;
    height: 100vh;
    overflow-y: scroll; }
    .header #sp_li_2 ul {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
      -ms-transform: translateY(-50%) translateX(-50%); } }
    @media screen and (max-width: 767px) and (min-width: 568px) {
      .header #sp_li_2 ul {
        margin: 40px 0 0;
        padding-bottom: 20px; } }

@media (max-width: 767px) {
    .header #sp_li_2 a {
      display: block;
      font-size: 14px;
      color: #fff;
      text-align: center;
      text-decoration: none; }
  /*
  sp footer
--------------------------------------------- */
  .footer {
    border-top: 1.5px solid #110051;
    margin: 0 auto; }
    .footer .holder .logo_holder {
      width: 74px;
      padding: 50px 0 14px;
      vertical-align: middle; }
      .footer .holder .logo_holder img {
        vertical-align: middle; }
    .footer .holder .footer_nav {
      letter-spacing: -1em;
      vertical-align: middle;
      padding: 20px 0 0; }
      .footer .holder .footer_nav li {
        letter-spacing: normal;
        width: 50%;
        display: inline-block;
        position: relative;
        vertical-align: middle; }
        .footer .holder .footer_nav li a {
          text-decoration: none;
          color: #110051;
          font-size: 0.71em;
          display: block;
          color: #8a8a8a; }
          .footer .holder .footer_nav li a:hover {
            color: #110051; }
          .footer .holder .footer_nav li a img {
            height: 16px; }
    .footer .copyright {
      font-family: "PT Sans", sans-serif;
      font-size: 0.71em;
      padding-bottom: 20px; }
    .footer_sns ul {
      text-align: center; }
      .footer_sns ul li {
        display: inline-block;
        padding: 0;
        margin: 20px 4px; }
        .footer_sns ul li a {
          width: 38px;
          height: 38px;
          display: block;
          border-radius: 20px;
          border: 1px #2d2d2d solid;
          text-align: center;
          margin: 0 auto; }
          .footer_sns ul li a img {
            width: auto;
            height: 36px;
            text-align: center;
            margin: 0 auto;
            padding: 10px 0; }
  /*
  sp common area
--------------------------------------------- */
  .area_tit {
    text-align: center;
    font-size: 2.28em;
    font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    letter-spacing: .075em;
    line-height: 1.6;
    padding: 70px 0 60px; }
    .area_tit span {
      display: block;
      font-size: 16px;
      font-family: "PT Sans", sans-serif;
      letter-spacing: .1em; }
  /* ---------- area blog ---------- */
  .area_blog {
    padding-bottom: 10px; }
    .area_blog .area_tit span {
      color: #32cca6; }
    .area_blog_cont {
      letter-spacing: -1em;
      margin: 0 -10px; }
      .area_blog_cont_box {
        letter-spacing: normal;
        text-align: left;
        display: inline-block;
        margin: 0 2% 20px;
        width: 46%;
        max-width: 292px; }
        .area_blog_cont_box .img {
          padding-bottom: 6px; }
          .area_blog_cont_box .img img {
            width: 100%; }
        .area_blog_cont_box .date {
          font-size: 0.71em;
          font-weight: bold;
          line-height: 14px; }
        .area_blog_cont_box .tag {
          display: inline-block;
          font-size: 0.71em;
          text-align: center;
          padding: 0 8px;
          color: #32cca6;
          line-height: 18px;
          border: 1px #32cca6 solid;
          border-radius: 9px; }
        .area_blog_cont_box .tit {
          font-size: 0.85em;
          padding: 8px 0;
          line-height: 1.5; }
        .area_blog_cont_box a {
          text-decoration: none; }
          .area_blog_cont_box a:hover {
            color: #32cca6; }
  #home .area_blog_cont {
    margin: 0; }
    #home .area_blog_cont_box {
      width: 92%;
      max-width: inherit;
      margin: 0 auto 20px;
      display: block; }
      #home .area_blog_cont_box .date {
        display: inline-block;
        padding-right: 10px; }
  /*
  sp page
--------------------------------------------- */
  .pages {
    background: url(../images/corp/common/head_bg.png) no-repeat center top;
    background-size: contain; }
    .pages .area_key {
      padding: 20px 0 100px; }
      .pages .area_key .area_tit {
        padding: 70px 0 40px; }
  /* ---------- buttons ---------- */
  .btn {
    background: #fff;
    display: inline-block;
    padding: 0 30px;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    border-radius: 25px;
    line-height: 44px; }
  .btn-purple {
    border: 1px solid #9d56e4;
    color: #9d56e4; }
    .btn-purple:hover {
      background: #9d56e4;
      color: #fff; }
  .btn-orange {
    border: 1px solid #ff9537;
    color: #ff9537; }
    .btn-orange:hover {
      background: #ff9537;
      color: #fff; }
  .btn-lightGreen {
    border: 1px solid #32cca6;
    color: #32cca6; }
    .btn-lightGreen:hover {
      background: #32cca6;
      color: #fff; }
  /*
  sp index header
--------------------------------------------- */
  .header {
    position: relative; }
  /*
  sp hero
--------------------------------------------- */
  .hero {
    background: url(../images/corp/common/hero_bg_sp.png) no-repeat center bottom;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 58.4375%; }
    .hero_logo {
      width: 160px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -80px;
      text-align: center; }
      .hero_logo img {
        text-align: center;
        margin: 0 auto; }
  #home {
    /* ---------- visual text ---------- */
    /* ---------- info ---------- */
    /* ---------- miraimo ---------- */
    /* ---------- miraimo-newgrads ---------- */ }
    #home .s1 h2 {
      padding-top: 50px;
      text-align: center;
      font-size: 1.35em;
      color: #110051;
      font-family: "a-otf-ud-shin-go-pr6n", sans-serif;
      letter-spacing: .08em;
      font-feature-settings: "palt"; }
    #home .s1 p {
      font-family: "a-otf-ud-shin-go-pr6n", sans-serif;
      letter-spacing: .1em;
      font-size: 0.92em;
      line-height: 2em;
      text-align: center;
      margin: 25px auto 50px; }
    #home .s2 {
      padding: 50px 0 100px;
      min-height: 444px;
      text-align: center; }
      #home .s2 h3 {
        text-align: center;
        font-size: 1.35em;
        position: relative;
        display: inline-block;
        padding-bottom: 5px;
        font-family: "a-otf-ud-shin-go-pr6n", sans-serif;
        letter-spacing: .2em; }
        #home .s2 h3:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          content: "";
          display: block;
          height: 3px;
          width: 70px;
          background: #110051; }
      #home .s2 .info_holder {
        margin: 50px 0; }
      #home .s2 ul {
        text-align: left; }
        #home .s2 ul li {
          margin-bottom: 20px; }
          #home .s2 ul li a {
            display: block;
            text-decoration: none; }
            #home .s2 ul li a:hover {
              color: #110051; }
              #home .s2 ul li a:hover .title {
                text-decoration: underline; }
          #home .s2 ul li .date {
            display: block;
            font-size: 0.85em;
            color: #8a8a8a; }
          #home .s2 ul li .title {
            vertical-align: top; }
      #home .s2 .next:hover {
        color: #9d56e4; }
      #home .s2 .btn-l {
        width: 250px; }
    #home .s3 {
      background: url("../images/corp/common/mirai-bg_sp.png") no-repeat top;
      background-size: contain; }
      #home .s3 .inner_sm {
        text-align: center;
        padding: 100px 0; }
        #home .s3 .inner_sm .logo {
          display: inline-block;
          margin-bottom: 50px; }
        #home .s3 .inner_sm .text h3 {
          font-size: 1.35em;
          font-weight: bold;
          margin-bottom: 20px; }
        #home .s3 .inner_sm .text p {
          margin-bottom: 25px; }
        #home .s3 .inner_sm .text a {
          color: #ff9537;
          text-decoration: none;
          width: 250px; }
          #home .s3 .inner_sm .text a:hover {
            color: #fff; }
      #home .s3 .inner_mid {
        width: 100%; }
        #home .s3 .inner_mid h3 {
          text-align: center;
          font-size: 1.35em;
          position: relative;
          display: inline-block;
          padding-bottom: 5px;
          margin-bottom: 45px;
          font-family: "a-otf-ud-shin-go-pr6n", sans-serif;
          letter-spacing: .2em; }
          #home .s3 .inner_mid h3:after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            content: "";
            display: block;
            height: 3px;
            width: 160px;
            background: #32cca6; }
        #home .s3 .inner_mid .next-btn {
          margin: 20px auto; }
          #home .s3 .inner_mid .next-btn .btn {
            color: #32cca6;
            text-decoration: none; }
            #home .s3 .inner_mid .next-btn .btn:hover {
              color: #FFF; }
        #home .s3 .inner_mid.area_blog {
          padding-bottom: 100px; }
    #home .miraimo-newgrads {
      background: url(../images/corp/common/miraimo-newgrads_sp.png) no-repeat center bottom;
      background-size: cover;
      position: relative;
      padding-top: 187.5%; }
      #home .miraimo-newgrads-image img {
        width: 100%; }
      #home .miraimo-newgrads-wrap {
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center; }
      #home .miraimo-newgrads-title {
        margin: 0 auto 20px;
        width: 296px; }
        #home .miraimo-newgrads-title img {
          width: 100%; }
      #home .miraimo-newgrads-desc {
        color: #FFF;
        line-height: 1.857;
        margin-bottom: 30px; }
      #home .miraimo-newgrads .newgrads_button {
        font-weight: bold;
        text-align: center;
        display: block;
        background: #fff;
        line-height: 50px;
        width: 92%;
        border-radius: 35px;
        margin: 0 auto;
        background: #58c56e;
        background: -moz-linear-gradient(left, #58c56e 0%, #b4d451 100%);
        background: -webkit-linear-gradient(left, #58c56e 0%, #b4d451 100%);
        background: linear-gradient(to right, #58c56e 0%, #b4d451 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#58c56e', endColorstr='#b4d451', GradientType=1);
        color: #FFF !important;
        text-decoration: none !important; }
        #home .miraimo-newgrads .newgrads_button:hover {
          opacity: 0.75; }
  /* loading cont */
  body .wrapper {
    height: auto;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 2s ease-in-out;
    -moz-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out; }
  body .loading_cont {
    height: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    display: none; }
    body .loading_cont .hero {
      height: 0;
      opacity: 0;
      visibility: hidden; }
    body .loading_cont .hero_logo {
      bottom: 0px; }
  body.loading {
    overflow: hidden; }
  body.loading .wrapper {
    height: 0;
    opacity: 0;
    visibility: hidden; }
  body.loading .loading_cont {
    overflow: hidden;
    width: 100%;
    position: fixed;
    height: 100%;
    background: #fff;
    opacity: 1;
    visibility: visible;
    display: block; }
    body.loading .loading_cont .hero {
      opacity: 1;
      visibility: visible;
      background: #fff;
      padding-top: calc(58.4375% + 112px); }
  @-webkit-keyframes herologo {
    0% {
      opacity: 0;
      bottom: -300px; }
    100% {
      opacity: 1;
      bottom: 0px; } }
  @-moz-keyframes herologo {
    0% {
      opacity: 0;
      bottom: -300px; }
    100% {
      opacity: 1;
      bottom: 0px; } }
  @keyframes herologo {
    0% {
      opacity: 0;
      bottom: -300px; }
    100% {
      opacity: 1;
      bottom: 0px; } }
    body.loading .loading_cont .hero_logo {
      -webkit-animation-name: herologo;
      -webkit-animation-duration: 2.5s;
      -moz-animation-name: herologo;
      -moz-animation-duration: 2.5s;
      animation-name: herologo;
      animation-duration: 2.5s; }
  /*
  sp information
--------------------------------------------- */
  /* ---------- information archive ---------- */
  #information_archive {
    /* ---------- sidebar ---------- */ }
    #information_archive .main_heading .overlay {
      background-image: url("../images/corp/information/information-bg.png"); }
    #information_archive .content_holder {
      width: 94%;
      margin: 0 auto 80px; }
    #information_archive .big_heading p {
      padding-top: 30px; }
      #information_archive .big_heading p.eng {
        padding: 0; }
    #information_archive .container {
      margin: 50px auto; }
      #information_archive .container .article_cont {
        width: 100%;
        padding-bottom: 50px; }
        #information_archive .container .article_cont .article_box {
          margin-bottom: 20px; }
          #information_archive .container .article_cont .article_box a {
            text-decoration: none; }
          #information_archive .container .article_cont .article_box .article_date {
            font-size: 12px;
            color: #666666;
            padding-top: 2px; }
          #information_archive .container .article_cont .article_box .article_tit {
            color: #000000;
            font-size: 14px;
            width: 100%; }
      #information_archive .container .article_sidebar {
        width: 100%; }
        #information_archive .container .article_sidebar .archive_tit {
          font-size: 1.14em;
          font-weight: bold;
          text-align: center;
          padding: 10px 0;
          border-top: 1px;
          border-bottom: 1px;
          border-color: #110051;
          border-style: solid; }
    #information_archive .page-nation {
      letter-spacing: normal;
      margin: 50px 0; }
      #information_archive .page-nation ul li {
        padding: 0 3px;
        display: inline-block; }
        #information_archive .page-nation ul li a {
          display: block;
          text-align: center;
          width: 45px;
          line-height: 45px;
          border-radius: 4px;
          border: 1px #b7b2ca solid;
          background: #fff;
          color: #b7b2ca;
          text-decoration: none; }
        #information_archive .page-nation ul li.current a {
          color: #fff;
          background: #b7b2ca; }
    #information_archive .archive .archive_list li {
      position: relative;
      margin-bottom: 6px; }
      #information_archive .archive .archive_list li a {
        display: block;
        line-height: 45px;
        text-decoration: none;
        color: #2d2d2d;
        padding: 0 20px;
        border-bottom: 1px dashed #110051; }
        #information_archive .archive .archive_list li a:after {
          float: right;
          content: url(../images/corp/common/ico-arrow-right-blue.png);
          vertical-align: middle;
          clear: both; }
        #information_archive .archive .archive_list li a i {
          color: #2d205f;
          position: absolute;
          right: 20px;
          top: 50%;
          margin-top: -7px; }
  /* ---------- information single ---------- */
  #information_single .main_heading .overlay,
  .single-post .main_heading .overlay {
    background-image: url("../images/corp/information/information-bg.png"); }
  #information_single .content_holder,
  .single-post .content_holder {
    width: 94%;
    margin: 0 auto 80px; }
  #information_single .big_heading,
  .single-post .big_heading {
    width: 94%;
    margin: 0 auto;
    padding-top: 30px; }
    #information_single .big_heading p.article_date,
    .single-post .big_heading p.article_date {
      text-align: left; }
  #information_single .container,
  .single-post .container {
    margin: 45px auto 110px;
    width: 100%; }
    #information_single .container .single_cont p,
    .single-post .container .single_cont p {
      margin: 30px auto; }
    #information_single .container .single_cont .mid_tit,
    .single-post .container .single_cont .mid_tit {
      *zoom: 1;
      font-size: 22px;
      margin: 50px auto 0; }
      #information_single .container .single_cont .mid_tit:after,
      .single-post .container .single_cont .mid_tit:after {
        content: ".";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
      #information_single .container .single_cont .mid_tit .mid_tit_img,
      .single-post .container .single_cont .mid_tit .mid_tit_img {
        float: left;
        padding-top: 15px;
        margin-right: 10px; }
      #information_single .container .single_cont .mid_tit .mid_tit_txt,
      .single-post .container .single_cont .mid_tit .mid_tit_txt {
        margin: 0;
        line-height: 1.5; }
  /*
  sp parts
--------------------------------------------- */
  .main_heading {
    position: relative; }
    .main_heading .overlay {
      background: url("../images/corp/common/main-heading-bg.png") no-repeat center bottom;
      background-size: auto 100%;
      min-height: 140px;
      position: relative;
      margin-bottom: 40px; }
      .main_heading .overlay .inner_mid {
        position: static; }
      .main_heading .overlay h1 {
        text-align: center;
        color: #fff;
        font-size: 1.42em;
        font-family: "a-otf-ud-shin-go-pr6n", sans-serif;
        letter-spacing: .2em;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateY(-50%) translateX(-50%);
        -ms-transform: translateY(-50%) translateX(-50%);
        transform: translateY(-50%) translateX(-50%);
        width: 100%; }
      .main_heading .overlay .breadcrumbs {
        display: none; }
  .big_heading {
    text-align: center; }
    .big_heading p.eng {
      font-size: 0.71em;
      letter-spacing: 0.2em;
      color: #110051;
      margin-bottom: 0; }
    .big_heading h3 {
      text-align: center;
      font-size: 1.71em;
      letter-spacing: .1em;
      position: relative;
      display: inline-block;
      font-family: "a-otf-ud-shin-go-pr6n", sans-serif; }
    .big_heading h4 {
      font-size: 1.28em;
      margin: 23px 0; }
    .big_heading p {
      font-size: 0.85em;
      margin-bottom: 23px; }
  /* icon circle */
  .ico_circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #bfbfbf;
    color: #fff;
    padding: 10px;
    font-size: 1.14em;
    text-align: center;
    line-height: 1.5;
    margin: 37px auto; }
    .ico_circle img {
      width: 16px; }
  .ico_circle_sm {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    padding: 7px;
    font-size: 1.28em;
    text-align: center;
    line-height: 1.5;
    max-width: 60px;
    display: inline-block;
    vertical-align: middle;
    color: #110051; }
    .ico_circle_sm .fa {
      font-size: 1.42em; }
  .inner_parts {
    max-width: 94%;
    margin: 0 auto; }
  /* margin and padding 0 */
  .pbtm-0 {
    padding-bottom: 0 !important; }
  .mbtm-0 {
    margin-bottom: 0 !important; }
  /* flex */
  .inline {
    display: block; }
  /* typhography */
  .article p {
    margin-bottom: 25px; }
  /* lists */
  ol.steps li {
    border-top: 1px dashed #bfbfbf;
    padding: 20px 0;
    list-style-type: decimal;
    list-style-position: inside; }
    ol.steps li:last-child {
      border-bottom: 1px dashed #bfbfbf; }
  ol.steps-simple {
    margin-left: 20px; }
    ol.steps-simple li {
      counter-increment: customlistcounter; }
      ol.steps-simple li:before {
        content: counter(customlistcounter) ")";
        float: left;
        width: 1.2em; }
      ol.steps-simple li:first-child {
        counter-reset: customlistcounter; }
  ul.disc {
    margin-left: 20px;
    list-style: disc; }
  ol.num li {
    counter-increment: customlistcounter; }
    ol.num li:before {
      padding-right: 10px;
      content: counter(customlistcounter) "";
      float: left;
      width: 1.2em;
      color: #778fce; }
    ol.num li:first-child {
      counter-reset: customlistcounter; }
  ul.square li:before {
    padding-right: 10px;
    float: left;
    width: 1.2em;
    content: url("../images/corp/common/ico-square.png");
    display: inline-block;
    vertical-align: middle; }
  /* table */
  .table {
    width: 100%;
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd; }
    .table tr {
      border-bottom: 1px solid #dddddd; }
    .table td {
      padding: 14px 4%; }
      .table td:first-child {
        width: 30%;
        background: #f6f6f6;
        text-align: center;
        border-right: 1px solid #dddddd; }
    .table.table-with-heading tr:first-child {
      background: #e1eef6; }
      .table.table-with-heading tr:first-child td {
        padding: 17px; }
        .table.table-with-heading tr:first-child td:first-child {
          background: #e1eef6; }
  /* anchor links */
  a.ac {
    text-decoration: underline;
    display: inline-block;
    margin-right: 38px; }
  a.blnk:after {
    width: 20px;
    height: 20px;
    content: "";
    margin-left: 5px;
    background: url("../images/corp/common/ico-blank.png") no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle; }
  a.pdf:after {
    width: 20px;
    height: 20px;
    content: "";
    margin-left: 5px;
    background: url("../images/corp/common/ico-pdf.png") no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle; }
  /* btns */
  a.btn-l {
    font-size: 1.14em;
    text-align: center;
    margin: 0 auto 10px;
    text-decoration: none !important;
    display: block;
    width: 100%;
    height: 44px;
    line-height: 44px;
    background: #fff;
    border: 1px solid #110051;
    color: #110051;
    border-radius: 22px; }
    a.btn-l.rounded {
      border-radius: 22px; }
  a.btn-s {
    text-align: center;
    text-decoration: none !important;
    color: #fff;
    display: inline-block;
    width: 80%;
    height: 45px;
    line-height: 45px;
    margin: 0 auto 10px;
    background: #778fce;
    -webkit-box-shadow: 2px 2px 2px 0 #7983a0;
    -moz-box-shadow: 2px 2px 2px 0 #7983a0;
    box-shadow: 2px 2px 2px 0 #7983a0; }
    a.btn-s:hover {
      background: #110051; }
  a.btn-navi {
    font-size: 0.92em;
    text-align: center;
    text-decoration: none;
    color: #FFF;
    display: inline-block;
    width: 80%;
    height: 45px;
    line-height: 45px;
    border: 1px solid #110051;
    background: #110051;
    vertical-align: middle;
    margin: 0 auto 10px;
    position: relative; }
    a.btn-navi:before, a.btn-navi:after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      content: "";
      vertical-align: middle; }
    a.btn-navi:before {
      right: 8px;
      width: 10px;
      height: 1px;
      background: #fff; }
    a.btn-navi:after {
      right: 8px;
      width: 6px;
      height: 6px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    a.btn-navi:hover {
      background: #FFF;
      color: #110051; }
      a.btn-navi:hover:before {
        background: #110051; }
      a.btn-navi:hover:after {
        border-top-color: #110051;
        border-right-color: #110051; }
  .cont {
    margin: 0 auto 25px;
    font-size: 0.92em;
    /* typhography */
    /* box size */
    /* title */
    /* box with images */ }
    .cont .border-drk {
      border: 2px solid #b7b2ca; }
    .cont .border-light {
      border: 2px solid #f4f3f7; }
    .cont .bg-light {
      background: #f4f3f7; }
    .cont .rounded {
      border-radius: 5px; }
    .cont .txt {
      border-top: 2px solid #b7b2ca;
      padding-top: 25px; }
    .cont .title {
      width: calc(100% - 60px);
      font-size: 1.21em;
      color: #110051;
      display: inline-block;
      vertical-align: middle;
      line-height: 1.5; }
    .cont p.center {
      text-align: center; }
    .cont .box {
      padding: 14px 4%; }
    .cont .box-1 {
      width: 100%; }
    .cont .box-2 {
      width: 100%;
      margin-bottom: 20px; }
    .cont .box-3 {
      width: 100%; }
      .cont .box-3 img {
        width: 100%;
        margin: 0 auto 20px; }
      .cont .box-3 p {
        width: 100%; }
    .cont h4 {
      color: #110051;
      border-bottom: 1px solid #bfbfbf;
      font-weight: bold;
      padding-bottom: 10px;
      margin-bottom: 20px; }
    .cont .box-with-img-right {
      width: 100%; }
      .cont .box-with-img-right img {
        margin-bottom: 20px;
        width: 100%; }
    .cont .box-with-img-left {
      width: 100%; }
      .cont .box-with-img-left img {
        margin-bottom: 20px;
        width: 100%; }
  .content_holder p {
    text-align: left; }
  .clearfix {
    clear: both; }
  /*
  sp company
--------------------------------------------- */
  /* ---------- company access ---------- */
  #access .main_heading .overlay {
    background-image: url("../images/corp/company/company-bg.png"); }
  #access .content_holder {
    width: 94%;
    margin: 50px auto 80px; }
  #access .big_heading p {
    padding-top: 30px; }
    #access .big_heading p.eng {
      padding: 0; }
  #access .ico_circle {
    background: #ddd;
    padding: 12px 5px; }
    #access .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #access .inner_parts {
    margin: 0 auto;
    width: 100%;
    margin-bottom: 80px;
    max-width: 100%; }
    #access .inner_parts .ico_circle_sm {
      padding: 10px 3px; }
      #access .inner_parts .ico_circle_sm img {
        width: 14px;
        margin: 0 auto;
        text-align: center; }
    #access .inner_parts .box1 {
      margin-bottom: 30px; }
      #access .inner_parts .box1 > .box {
        margin-bottom: 10px; }
    #access .inner_parts .box {
      padding: 0; }
      #access .inner_parts .box .map {
        border: 1px #ddd solid;
        padding: 6px 6px 0; }
        #access .inner_parts .box .map iframe {
          width: 100%;
          height: 160px; }
      #access .inner_parts .box .btn_wrap {
        width: 100%;
        padding: 15px 0;
        text-align: right;
        border-bottom: 1px #8a8a8a dashed; }
        #access .inner_parts .box .btn_wrap a {
          margin: 0 0 0 15px; }
        #access .inner_parts .box .btn_wrap .plus {
          position: relative;
          font-size: 0.92em; }
          #access .inner_parts .box .btn_wrap .plus:before {
            content: "";
            width: 12px;
            height: 12px;
            display: inline-block;
            vertical-align: middle;
            padding-right: 6px;
            background: url(../images/corp/company/ico-plus.png) no-repeat left center; }
        #access .inner_parts .box .btn_wrap .btn-navi {
          width: 152px; }
      #access .inner_parts .box .detail {
        width: 100%;
        margin-top: 20px; }
        #access .inner_parts .box .detail th,
        #access .inner_parts .box .detail td {
          vertical-align: top;
          display: block; }
        #access .inner_parts .box .detail th {
          width: 120px; }
        #access .inner_parts .box .detail td {
          padding-bottom: 10px; }
  /* ---------- company profile ---------- */
  #profile .main_heading .overlay {
    background-image: url("../images/corp/company/company-bg.png"); }
  #profile .content_holder {
    margin-top: 50px;
    margin-bottom: 80px; }
  #profile .big_heading p {
    padding-top: 30px; }
    #profile .big_heading p.eng {
      padding: 0; }
  #profile .ico_circle {
    background: #ddd;
    padding: 12px 5px; }
    #profile .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #profile .inner_parts {
    margin: 0 auto;
    width: 100%;
    border-top: 2px solid #110051;
    margin-bottom: 80px; }
    #profile .inner_parts table {
      width: 100%; }
      #profile .inner_parts table th,
      #profile .inner_parts table td {
        width: 100%;
        display: block;
        vertical-align: top;
        position: relative; }
      #profile .inner_parts table tr:first-child th,
      #profile .inner_parts table tr:first-child td {
        border-top: none; }
      #profile .inner_parts table th {
        padding-top: 10px;
        width: 166px;
        font-weight: bold; }
      #profile .inner_parts table td {
        padding-bottom: 10px;
        border-bottom: 1px dashed #bfbfbf; }
      #profile .inner_parts table .btn_wrap {
        margin: 10px auto 0 0;
        text-align: right; }
        #profile .inner_parts table .btn_wrap .btn-l {
          width: 190px;
          font-size: 0.92em;
          margin-right: 0;
          padding: 0;
          position: relative; }
          #profile .inner_parts table .btn_wrap .btn-l:after {
            content: "";
            background: url(../images/corp/common/ico-arrow-right-btn.png);
            background-position: right center;
            background-repeat: no-repeat;
            background-size: contain;
            padding: 0;
            width: 7px;
            height: 13px;
            position: absolute;
            right: 10px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
  /* ---------- company message ---------- */
  #message .main_heading .overlay {
    background-image: url("../images/corp/company/company-bg.png"); }
  #message .content_holder {
    position: relative;
    margin: 0 auto;
    width: 94%;
    margin-top: 50px;
    margin-bottom: 80px; }
    #message .content_holder .img_holder {
      margin: 0 auto; }
      #message .content_holder .img_holder img {
        margin: 0 auto;
        width: 100%; }
    #message .content_holder .text_holder {
      margin: 0 auto; }
      #message .content_holder .text_holder h4 {
        margin: 30px 0 20px;
        font-size: 1.28em; }
      #message .content_holder .text_holder ol {
        margin: 0 0 30px; }
      #message .content_holder .text_holder p {
        margin-bottom: 30px; }
    #message .content_holder .signature {
      width: 100%;
      margin-bottom: 30px;
      float: right; }
      #message .content_holder .signature p {
        margin: 0; }
      #message .content_holder .signature span {
        display: inline-block;
        vertical-align: middle; }
      #message .content_holder .signature img {
        display: inline-block;
        vertical-align: middle;
        float: right;
        margin: 4px 20px 0 0;
        clear: both; }
  /* ---------- company index ---------- */
  #company .main_heading .overlay {
    background-image: url("../images/corp/company/company-bg.png"); }
  #company .content_holder {
    margin-bottom: 80px; }
    #company .content_holder .inner_parts {
      max-width: 100%; }
  #company .s1 {
    width: 94%;
    margin: 0 auto; }
  #company .big_heading p {
    padding-top: 30px; }
    #company .big_heading p.eng {
      padding: 0; }
  #company .ico_circle {
    background: #110051;
    padding: 12px 5px; }
    #company .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #company .box {
    width: 100%;
    position: relative;
    padding: 0;
    margin-bottom: 30px; }
    #company .box img {
      width: 100%; }
    #company .box a {
      border: 1px solid #ddd;
      width: 100%;
      color: #110051;
      height: 64px;
      line-height: 64px;
      background: rgba(255, 255, 255, 0.8);
      text-align: center;
      text-decoration: none;
      font-weight: bold;
      font-size: 1.14em;
      display: table;
      position: relative; }
      #company .box a:hover {
        color: #778fce; }
    #company .box .box_image {
      display: table-cell;
      width: 50%;
      vertical-align: middle; }
    #company .box .box_text {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
      text-align: center; }
  /*
  sp contact
--------------------------------------------- */
  /* ---------- contact index ---------- */
  #inquiry .main_heading .overlay {
    background-image: url("../images/corp/contact/contact-bg.png"); }
  #inquiry .content_holder {
    margin-bottom: 80px; }
    #inquiry .content_holder .txt_link {
      margin-top: 20px;
      text-align: center; }
  #inquiry .big_heading p {
    width: 94%;
    margin: 0 auto;
    padding-top: 30px; }
    #inquiry .big_heading p.eng {
      padding: 0; }
  #inquiry .ico_circle {
    background: #110051;
    padding: 15px 10px; }
    #inquiry .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #inquiry .inner_parts {
    margin-top: 50px; }
  #inquiry .form_cont label span {
    background: #f85550;
    color: #fff;
    border-radius: 4px;
    display: inline-block;
    padding: 5px 6px 3px;
    font-size: 0.71em;
    line-height: 1;
    margin-left: 10px; }
  #inquiry .form_cont table {
    width: 100%; }
    #inquiry .form_cont table th,
    #inquiry .form_cont table td {
      width: 100%;
      display: block; }
    #inquiry .form_cont table th {
      padding: 10px 0 5px;
      vertical-align: top; }
    #inquiry .form_cont table td {
      padding: 0 0 10px; }
      #inquiry .form_cont table td input[type="text"],
      #inquiry .form_cont table td input[type="email"],
      #inquiry .form_cont table td textarea {
        width: 100%;
        border-radius: 4px;
        border: 1px #ddd solid;
        padding: 8px;
        font-family: Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif; }
      #inquiry .form_cont table td textarea {
        height: 260px; }
      #inquiry .form_cont table td .formrun-system-show {
        color: #f85550;
        font-size: 0.85em; }
  #inquiry .form_cont input[type="button"],
  #inquiry .form_cont input[type="submit"],
  #inquiry .form_cont button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none; }
  #inquiry .form_cont .submit_btn {
    text-align: center;
    margin: 20px auto 0; }
  #inquiry .form_cont button[type="submit"] {
    font-size: 1.14em;
    text-align: center;
    margin: 0 auto 10px;
    text-decoration: none !important;
    display: block;
    width: 100%;
    height: 44px;
    line-height: 44px;
    background: #fff;
    border: 1px solid #110051;
    color: #110051;
    border-radius: 22px; }
  /* ---------- contact thankyou ---------- */
  #thankyou .main_heading .overlay {
    background-image: url("../images/corp/contact/contact-bg.png"); }
  #thankyou .s1 {
    width: 94%;
    margin: 0 auto; }
  #thankyou .content_holder {
    margin-bottom: 100px; }
  #thankyou .big_heading h3 {
    margin-bottom: 50px; }
  #thankyou .big_heading p {
    padding-top: 30px; }
    #thankyou .big_heading p.eng {
      padding: 0; }
  #thankyou .big_heading h3 {
    font-size: 1.42em; }
  #thankyou .form_thankyou_cont {
    margin: 0 auto; }
    #thankyou .form_thankyou_cont p {
      margin: 0 auto 30px;
      text-align: center; }
    #thankyou .form_thankyou_cont .btn_wrap {
      margin: 60px 0; }
  /*
  sp business
--------------------------------------------- */
  /* ---------- business index ---------- */
  #business .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png"); }
  #business .content_holder {
    margin-bottom: 80px; }
  #business .inner_parts {
    max-width: 100%;
    padding-bottom: 50px; }
  #business .s1 {
    width: 94%;
    margin: 0 auto; }
  #business .big_heading p {
    padding-top: 20px;
    margin-bottom: 50px; }
    #business .big_heading p.eng {
      padding: 0;
      margin-bottom: 0; }
  #business .ico_circle {
    background: #110051;
    padding: 12px 5px; }
    #business .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto; }
  #business .box {
    padding: 0; }
    #business .box .title {
      color: #222; }
  #business .box-2 {
    border-top: 2px solid #110051;
    padding-top: 10px;
    margin-bottom: 40px; }
  #business .box_bottom img {
    width: 100%;
    margin: 10px auto; }
  #business .box_bottom .btn-l {
    width: 250px;
    margin: 0 auto;
    position: relative; }
  /* ---------- business recruitment ---------- */
  #recruitment .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png"); }
  #recruitment .content_holder {
    margin-bottom: 80px; }
  #recruitment .inner_parts {
    max-width: 100%;
    padding-bottom: 50px; }
  #recruitment .s1 {
    width: 94%;
    margin: 0 auto; }
  #recruitment .big_heading {
    margin-bottom: 50px; }
    #recruitment .big_heading p.eng {
      padding: 0; }
  #recruitment .ico_circle {
    background: none;
    padding: 10px 0;
    margin-bottom: 60px;
    width: 20%; }
    #recruitment .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto;
      width: 100%; }
  #recruitment .step {
    width: 100%;
    margin: 0 auto 20px;
    clear: both; }
    #recruitment .step .step_L {
      width: 130px;
      margin: 0 auto; }
      #recruitment .step .step_L p.step_L_txt {
        display: inline-block;
        font-size: 13px;
        color: #110051;
        padding-right: 10px; }
      #recruitment .step .step_L .step_L_number {
        display: inline-block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #110051;
        color: #fff;
        padding: 0 5px;
        font-size: 1.57em;
        text-align: center;
        line-height: 60px;
        max-width: 60px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        letter-spacing: 3px; }
        #recruitment .step .step_L .step_L_number img {
          margin: 0 auto;
          padding-top: 4px; }
    #recruitment .step .step_R {
      width: 100%; }
      #recruitment .step .step_R p.txt1 {
        text-align: center;
        font-size: 20px;
        color: #2d2d2d;
        margin-bottom: 10px; }
      #recruitment .step .step_R p.txt2 {
        text-align: center;
        font-size: 13px;
        color: #2d2d2d;
        margin-bottom: 15px; }
        #recruitment .step .step_R p.txt2 span.underline {
          text-decoration: underline; }
    #recruitment .step .line {
      border-bottom: 1px solid #110051; }
  #recruitment .box {
    padding: 0; }
    #recruitment .box .title {
      color: #222; }
  #recruitment .box1 .article {
    border-top-color: #110051; }
  #recruitment .box-2 {
    border-top: 2px solid #110051;
    padding-top: 10px;
    margin-bottom: 40px; }
    #recruitment .box-2 > .box:first-child {
      margin-bottom: 5px; }
  #recruitment .cont:last-child {
    margin-top: 50px; }
  /* ---------- business service ---------- */
  #service .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png"); }
  #service .content_holder {
    margin-bottom: 80px; }
  #service .inner_parts {
    max-width: 100%;
    padding-bottom: 50px; }
  #service .s1 {
    width: 94%;
    margin: 0 auto; }
  #service .big_heading {
    margin-bottom: 50px; }
    #service .big_heading p.eng {
      padding: 0; }
  #service .box {
    padding: 0; }
    #service .box .title {
      color: #222; }
  #service .box1 {
    margin-top: 50px; }
    #service .box1 .box-1 {
      padding: 20px; }
      #service .box1 .box-1 p {
        margin-bottom: 0; }
  #service .box-2 {
    margin-top: 20px;
    margin-bottom: 40px;
    border-top: 2px solid #110051;
    padding-top: 10px; }
    #service .box-2:first-child {
      margin-right: 40px; }
    #service .box-2:last-child {
      margin-left: 0; }
  #service .article {
    border-top-color: #110051;
    padding-top: 20px; }
  #service .ico_circle {
    background: none;
    padding: 10px 0;
    margin-bottom: 60px;
    width: 20%; }
    #service .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto;
      width: 100%; }
  /* ---------- business event ---------- */
  #event .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png"); }
  #event .content_holder {
    margin-bottom: 80px; }
  #event .inner_parts {
    max-width: 100%;
    padding-bottom: 50px; }
  #event .s1 {
    width: 94%;
    margin: 0 auto; }
  #event .big_heading {
    margin-bottom: 50px; }
    #event .big_heading p.eng {
      padding: 0; }
  #event .ico_circle {
    background: none;
    padding: 10px 0;
    margin-bottom: 60px;
    width: 20%; }
    #event .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto;
      width: 100%; }
  #event .cont:last-child .box-2 {
    margin-bottom: 0; }
  #event .cont:last-child .article p {
    margin-bottom: 0; }
  #event .box {
    padding: 0; }
    #event .box .title {
      color: #222; }
  #event .article {
    border-top-color: #110051;
    padding-top: 20px; }
  /* ---------- business training ---------- */
  #training .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png"); }
  #training .content_holder {
    margin-bottom: 80px; }
  #training .inner_parts {
    max-width: 100%;
    padding-bottom: 50px; }
  #training .s1 {
    width: 94%;
    margin: 0 auto; }
  #training .big_heading {
    margin-bottom: 50px; }
    #training .big_heading p.eng {
      padding: 0; }
  #training .box {
    padding: 0; }
    #training .box .title {
      color: #222; }
  #training .article {
    border-top-color: #110051;
    padding-top: 20px; }
  #training .ico_circle {
    background: none;
    padding: 10px 0;
    margin-bottom: 60px;
    width: 20%; }
    #training .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto;
      width: 100%; }
  #training ol.steps {
    margin-left: 0;
    margin-bottom: 20px; }
    #training ol.steps li {
      padding: 25px 0;
      list-style: none;
      vertical-align: middle;
      font-size: 14px; }
      #training ol.steps li span {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: -10px;
        width: 24px;
        vertical-align: top; }
  #training .step {
    width: 100%;
    margin: 0 auto 30px;
    clear: both; }
    #training .step .step_L {
      width: 130px;
      margin: 0 auto; }
      #training .step .step_L p.step_L_txt {
        display: inline-block;
        font-size: 13px;
        color: #110051;
        padding-right: 10px; }
      #training .step .step_L .step_L_number {
        display: inline-block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #110051;
        color: #fff;
        padding: 0 5px;
        font-size: 1.57em;
        text-align: center;
        line-height: 60px;
        max-width: 60px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        letter-spacing: 3px; }
        #training .step .step_L .step_L_number img {
          margin: 0 auto;
          padding-top: 4px; }
    #training .step .step_R {
      width: 100%; }
      #training .step .step_R .txt1 {
        text-align: center;
        font-size: 20px;
        color: #2d2d2d;
        margin-bottom: 10px; }
      #training .step .step_R .txt2 {
        text-align: center;
        font-size: 13px;
        color: #2d2d2d;
        margin-bottom: 15px; }
        #training .step .step_R .txt2 span.underline {
          text-decoration: underline; }
    #training .step .line {
      border-bottom: 1px solid #110051; }
  #training .program_steps {
    margin-top: 50px; }
  /* ---------- business consulting ---------- */
  #consulting .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png"); }
  #consulting .content_holder {
    margin-bottom: 80px; }
  #consulting .inner_parts {
    max-width: 100%; }
    #consulting .inner_parts:nth-child(4) .box1 {
      margin-top: 25px; }
    #consulting .inner_parts:last-child {
      padding-bottom: 50px; }
      #consulting .inner_parts:last-child .box1 {
        margin-top: 25px; }
      #consulting .inner_parts:last-child .imgbox {
        margin: 0 auto 15px;
        width: 300px; }
  #consulting h4.mintit {
    position: relative; }
    #consulting h4.mintit:after {
      position: absolute;
      bottom: -20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: url(../images/corp/business/business-title_border.png);
      display: block; }
  #consulting .s1 {
    width: 94%;
    margin: 0 auto; }
  #consulting .big_heading {
    margin-bottom: 50px; }
    #consulting .big_heading:nth-child(3) {
      margin-bottom: 25px; }
    #consulting .big_heading:nth-child(6) {
      margin-bottom: 25px; }
    #consulting .big_heading p.eng {
      padding: 0; }
  #consulting .box1 {
    margin-top: 50px; }
  #consulting .box-2 {
    margin-top: 20px;
    margin-bottom: 40px;
    border-top: 2px solid #110051;
    padding-top: 10px; }
    #consulting .box-2 > .box:first-child {
      margin-bottom: 5px; }
  #consulting .box {
    padding: 0; }
    #consulting .box .title {
      color: #222; }
  #consulting .ico_circle {
    background: none;
    padding: 10px 0;
    margin-bottom: 60px;
    width: 20%; }
    #consulting .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto;
      width: 100%; }
  #consulting ol.steps {
    margin-left: 0;
    margin-bottom: 10px; }
    #consulting ol.steps li {
      padding: 12px 0;
      list-style: none;
      vertical-align: middle;
      font-size: 14px;
      padding-left: 1.5em;
      display: block; }
      #consulting ol.steps li span {
        display: list-item;
        list-style-type: disc; }
  #consulting .step {
    width: 100%;
    margin: 0 auto 30px;
    clear: both; }
    #consulting .step .step_L {
      width: 130px;
      margin: 0 auto; }
      #consulting .step .step_L p.step_L_txt {
        display: inline-block;
        font-size: 13px;
        color: #110051;
        padding-right: 10px; }
      #consulting .step .step_L .step_L_number {
        display: inline-block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #110051;
        color: #fff;
        padding: 0 5px;
        font-size: 1.57em;
        text-align: center;
        line-height: 60px;
        max-width: 60px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        letter-spacing: 3px; }
        #consulting .step .step_L .step_L_number img {
          margin: 0 auto;
          padding-top: 4px; }
    #consulting .step .step_R {
      width: 100%; }
      #consulting .step .step_R p.txt1 {
        text-align: center;
        font-size: 20px;
        color: #2d2d2d;
        margin-bottom: 10px; }
      #consulting .step .step_R p.txt2 {
        text-align: center;
        font-size: 13px;
        color: #2d2d2d;
        margin-bottom: 15px; }
        #consulting .step .step_R p.txt2 span.underline {
          text-decoration: underline; }
    #consulting .step .line {
      border-bottom: 1px solid #110051; }
  #consulting .backgray {
    background: #f4f3f7;
    padding: 20px 20px 20px 10px;
    margin-top: 30px; }
    #consulting .backgray ul li {
      padding-left: 1em;
      text-indent: -1em; }
  #consulting .consulting_hr .box1 .box {
    margin-bottom: 10px; }
  #consulting .consulting_hr .box1 .article {
    border-top-color: #110051;
    padding-top: 20px; }
  /* ---------- business fbg ---------- */
  #fbg .main_heading .overlay {
    background-image: url("../images/corp/business/business-bg.png"); }
  #fbg .content_holder {
    margin-bottom: 80px; }
  #fbg .inner_parts {
    max-width: 100%;
    padding-bottom: 50px; }
  #fbg .s1 {
    width: 94%;
    margin: 0 auto; }
  #fbg .big_heading {
    margin-bottom: 50px; }
    #fbg .big_heading p.eng {
      padding: 0; }
  #fbg .ico_circle {
    background: none;
    padding: 10px 0;
    margin-bottom: 60px;
    width: 50%; }
    #fbg .ico_circle img {
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto;
      width: 100%; }
  #fbg ol.steps li {
    padding: 10px 0 10px 2em;
    list-style: none;
    display: block; }
    #fbg ol.steps li span {
      display: list-item;
      list-style-type: decimal; }
  #fbg ol.steps li.border-t-none {
    border-top: none; }
  #fbg ol.steps li.border-b-none {
    border-bottom: none; }
  #fbg ol.steps1 {
    margin-left: 0; }
    #fbg ol.steps1 li {
      padding: 15px 0 15px 2em;
      list-style: none; }
      #fbg ol.steps1 li span {
        display: list-item;
        list-style-type: disc; }
    #fbg ol.steps1 li.border-t-none {
      border-top: none; }
    #fbg ol.steps1 li.border-b-none {
      border-bottom: none; }
  #fbg .box {
    padding: 0; }
    #fbg .box .title {
      color: #222; }
  #fbg .date {
    margin: 10px 0 0; }
  #fbg .article {
    border-top-color: #110051;
    padding-top: 20px; }
  #fbg .cont:first-child .article {
    padding-top: 0; }
  #fbg .cont:last-child .box-2 {
    margin-bottom: 0; }
  #fbg .cont:last-child .article p {
    margin-bottom: 0; }
  /*
  sp add
--------------------------------------------- */
  /* ---------- privacy ---------- */
  #privacy .main_heading .overlay {
    background-image: url("../images/corp/company/company-bg.png"); }
  #privacy .inner_parts {
    padding-bottom: 50px; }
  #privacy .cont {
    margin: 50px 0; }
  #privacy .big_heading p {
    padding-top: 30px;
    width: 94%;
    margin: 0 auto; }
    #privacy .big_heading p.eng {
      padding-top: 0; }
  #privacy a.btn-l {
    display: block;
    margin: 0 auto;
    line-height: 47px;
    padding-right: 10px;
    position: relative; }
    #privacy a.btn-l:after {
      content: "";
      background: url(../images/corp/common/ico-arrow-right-btn.png);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: contain;
      padding: 0;
      width: 7px;
      height: 12px;
      position: absolute;
      right: 10px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
  #privacy .ordered_list {
    list-style-type: decimal;
    margin-left: 20px; }
    #privacy .ordered_list li {
      padding-left: 5px; }
  #privacy .unordered_list li {
    margin-left: 1em;
    text-indent: -1em;
    padding-left: 0; }
    #privacy .unordered_list li:before {
      content: "・"; } }
