/*
  icon
--------------------------------------------- */
@font-face {
  font-family: 'mori-font';
  src:  url('../fonts/mori-font.eot?3ttmy6');
  src:  url('../fonts/mori-font.eot?3ttmy6#iefix') format('embedded-opentype'),
    url('../fonts/mori-font.ttf?3ttmy6') format('truetype'),
    url('../fonts/mori-font.woff?3ttmy6') format('woff'),
    url('../fonts/mori-font.svg?3ttmy6#mori-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="i-"], [class*=" i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'mori-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-corp-01:before {
  content: "\e900";
}
.i-corp-02:before {
  content: "\e901";
}
.i-corp-03:before {
  content: "\e902";
}
.i-corp-04:before {
  content: "\e903";
}
.i-corp-05:before {
  content: "\e904";
}
.i-corp-06:before {
  content: "\e905";
}
.i-corp-07:before {
  content: "\e906";
}
.i-corp-08:before {
  content: "\e907";
}
.i-corp-09:before {
  content: "\e908";
}
.i-corp-10:before {
  content: "\e909";
}
.i-corp-11:before {
  content: "\e90a";
}
.i-corp-12:before {
  content: "\e90b";
}
.i-corp-13:before {
  content: "\e90c";
}
.i-corp-14:before {
  content: "\e90d";
}
.i-corp-15:before {
  content: "\e90e";
}
.i-corp-16:before {
  content: "\e90f";
}
.i-corp-17:before {
  content: "\e910";
}
.i-corp-18:before {
  content: "\e911";
}
.i-corp-19:before {
  content: "\e912";
}
.i-corp-20:before {
  content: "\e913";
}
.i-corp-21:before {
  content: "\e914";
}
.i-corp-22:before {
  content: "\e915";
}
.i-corp-23:before {
  content: "\e916";
}
.i-corp-24:before {
  content: "\e917";
}
.i-corp-25:before {
  content: "\e918";
}
.i-corp-26:before {
  content: "\e919";
}
.i-corp-27:before {
  content: "\e91a";
}
.i-corp-28:before {
  content: "\e91b";
}
.i-corp-29:before {
  content: "\e91c";
}
.i-corp-30:before {
  content: "\e91d";
}
.i-corp-31:before {
  content: "\e91e";
}
.i-corp-32:before {
  content: "\e91f";
}
.i-corp-33:before {
  content: "\e920";
}
.i-corp-34:before {
  content: "\e921";
}
.i-corp-35:before {
  content: "\e922";
}
.i-corp-36:before {
  content: "\e923";
}
.i-corp-37:before {
  content: "\e924";
}
.i-corp-38:before {
  content: "\e925";
}
.i-corp-39:before {
  content: "\e926";
}
.i-corp-40:before {
  content: "\e927";
}
.i-corp-41:before {
  content: "\e928";
}
.i-corp-42:before {
  content: "\e929";
}
