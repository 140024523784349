@charset "UTF-8";
@import url('//fonts.googleapis.com/css?family=PT+Sans');

@import "component/reset";
@import "component/icon";
@import "component/corp/mixin";
@import "component/corp/config";
@import "component/base";

@media (min-width: 768px) {
	@import "parts/corp/pc/pc-common";
	@import "parts/corp/pc/pc-index";
	@import "parts/corp/pc/pc-information";
	@import "parts/corp/pc/pc-parts";
	@import "parts/corp/pc/pc-company";
	@import "parts/corp/pc/pc-contact";
	@import "parts/corp/pc/pc-business";
	@import "parts/corp/pc/pc-add";
}

@media (max-width: 767px) {
	@import "parts/corp/sp/sp-common";
	@import "parts/corp/sp/sp-index";
	@import "parts/corp/sp/sp-information";
	@import "parts/corp/sp/sp-parts";
	@import "parts/corp/sp/sp-company";
	@import "parts/corp/sp/sp-contact";
	@import "parts/corp/sp/sp-business";
	@import "parts/corp/sp/sp-add";
}
